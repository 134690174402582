import { toast, Zoom } from 'react-toastify';
// import _ from 'underscore'

export default function useToast() {
    /**
     * This method used to display toast message
     * @param {Object} data 
     */
    const showToast = (data) => {
        try {
            // if (!_.isEmpty(data.message)) {
                if ((data && data.message !== "")) {     
                    // console.log("showing alert",data.message)           
                toast(data.message, {
                    type: 'info',
                    position: "top-right",
                    // position:'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    ...data
                })
            }
        } catch (err) {
            console.error("Exception occurred in displaying toast message -- ", err)
        }
    }

    return showToast;
}