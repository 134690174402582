import { useState, useEffect, useRef, useContext } from "react"
import AdminLayout from "../layouts/AdminLayout"
import { StudentTable } from "../components/studentroster/StudentTable"
import { EnrollStudentTable } from "../components/studentroster/EnrollStudentTable"
import AddNewStudentModal from "../components/modals/AddNewStudentModal"
// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  checkForStudentInformation,
  addOrUpdateStudent,
  updateStudentPassword,
} from "../redux/features/student/student.slice"
import { EnrolledStudentInformation } from "../redux/features/student/enroll.slice"
// import { ProcessingImage } from "../shared/components/ProcessingImage"
import { Loading } from "../shared/Loading"
import useToast from "../shared/components/Toast"

// added by premkumar
import ChangePasswordModal from "../components/modals/ChangePasswordModal"
import { resetUpdateStudentPasswordDetails, resetStudentDetails, resetAddOrUpdateStudentDetails } from "../redux/features/student/student.slice"
import { changeActivePage } from "../redux/features/user/user.slice"
import AppContext from "../store/AppContext"

function StudentRosterContainer() {
  const studentList = useSelector((state: any) => state.student)
  const enrollList = useSelector((state: any) => state.Enrolledstudent)
  const [showEnrollList, setShowEnrollList] = useState(false)
  const [studentSisName, setStudentSisName] = useState("")
  const [refreshStaff, setRefreshStaff] = useState(false)
  // console.log('student::',studentList)
  // console.log('enrollmentstudent::',enrollList)
  // console.log('showEnrollList::',showEnrollList)
  const dispatch = useDispatch()
  const showToast = useToast()
  const studentStatus = useSelector((state: any) => state.student.status)
  const studentProfile = useSelector((state: any) => state.student.studentData)
  const enrollmentStudentStatus = useSelector(
    (state: any) => state.Enrolledstudent.status
  )
  //console.log('enrollmentstudent status 26::',enrollmentStudentStatus)

  const [show, setShow] = useState(false)
  const [editStudent, setEditStudent] = useState("")
  const studentModal = useRef<any>(null)

  // added by premkumar
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const studentPasswordModal = useRef<any>(null)

  const studentDetails = useSelector((state: any) => state.student.studentDetails)
  const addOrUpdateStudentDetails = useSelector((state: any) => state.student.addOrUpdateStudentDetails)
  const updateStudentPasswordDetails = useSelector((state: any) => state.student.updateStudentPasswordDetails)

  const appContext = useContext(AppContext);
  const adminInfo= appContext?.userDetails

  const addJobRole = () => {
    //console.log('in the container!!!');
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
    setEditStudent("")
    studentModal.current.resetState()
  }
  const handleUpdate = (studentData: any) => {
    let created = studentData.created
    let updated = studentData.updated
    let IsManual = studentData.IsManual
    ;["created", "updated", "IsManual"].forEach((e) => delete studentData[e])
    dispatch(
      addOrUpdateStudent({
        student: {
          studentDetails: studentData,
          IsManual: IsManual,
          created: created,
          updated: updated,
        },
      })
    )
  }

  const enrolledStudent = (userId: any) => {
    console.log("StudentRosterContainer, id is ::: ", userId)
    dispatch(EnrolledStudentInformation({ userId, districtId:adminInfo.districtId }))
  }

  useEffect(() => {
    dispatch(checkForStudentInformation({districtId:adminInfo.districtId}))
    if (refreshStaff) setRefreshStaff(false)
    //console.log('student  status in studentRoasterContainer container  ')
  }, [dispatch, refreshStaff])

  useEffect(() => {
    if(addOrUpdateStudentDetails.status === "succeeded" && studentDetails.isStudentExists === false) {
      dispatch(checkForStudentInformation({districtId:adminInfo.districtId}))
      if (refreshStaff) setRefreshStaff(false)
      setShow(false)
      setEditStudent("")
      if (studentList.studentList.statusCode === 200) {
        setRefreshStaff(true)
      }
      studentModal.current.resetState()
      showToast({message: addOrUpdateStudentDetails.message})
      dispatch(resetStudentDetails())
      dispatch(resetAddOrUpdateStudentDetails())
    }
  }, [addOrUpdateStudentDetails])

  /* added by premkumar
   */
  const setEditStudentPassword = (editStudent: any) => {
    setShowPasswordModal(true)
    setEditStudent(editStudent)
  }

  /* added by premkumar
   */
  const handlePasswordModalClose = () => {
    setShowPasswordModal(false)
    setEditStudent("")
  }

  /* added by premkumar
   */
  const handlePasswordUpdate = (e: any, editStudent: any) => {
    e.preventDefault()

    const student = {
      studentDetails: {
        _id: editStudent._id,
        email: editStudent.email,
        password: editStudent.password,
      },
    }
    dispatch(updateStudentPassword({ student }))
  }


  useEffect(() => {
    if(updateStudentPasswordDetails.status === 'succeeded') {
      setShowPasswordModal(false)
      setEditStudent("")
      if (studentList.studentList.statusCode === 200) {
        setRefreshStaff(true)
      }
      studentPasswordModal.current.resetState()
      dispatch(resetUpdateStudentPasswordDetails())
    }
  }, [updateStudentPasswordDetails])

  useEffect(() => {
    dispatch(changeActivePage("student"))
  },[])

  return (
    <>
      <AddNewStudentModal
        modalState={show}
        handleClose={handleClose}
        handleUpdate={handleUpdate}
        editStudent={editStudent}
        ref={studentModal}
      />
      <ChangePasswordModal
        modalState={showPasswordModal}
        handleClose={handlePasswordModalClose}
        handleUpdate={handlePasswordUpdate}
        userInfo={editStudent}
        ref={studentPasswordModal}
      />
      <AdminLayout
        showButton='true'
        handler={addJobRole}
        name='Add New Student'
      >
        <div className='dashfirstrowheight'>
          {studentList.status === "loading" ? (
            // <ProcessingImage isLoading={true} />
            <div>
            <Loading />
          </div>
          ) : (
            <StudentTable
              studentList={studentList}
              enrolledStudent={enrolledStudent}
              setStudentSisName={setStudentSisName}
              setShow={setShow}
              setEditStudent={setEditStudent}
              enrollList={enrollList}
              studentSisName={studentSisName}
              setEditStudentPassword={setEditStudentPassword}
              handler={addJobRole}
            />
          )}
        </div>

        {/* <div className="container mrgntop" id ="studTable" style={{display:'none'}}>

          {
            enrollList.status === "loading" ? 
            <ProcessingImage isLoading={true} /> :
            <EnrollStudentTable enrollList={enrollList} studentSisName={studentSisName}/>
          }
        </div> */}
      </AdminLayout>
    </>
  )
}

export default StudentRosterContainer
