import React from 'react';
import Button from 'react-bootstrap/Button';

export const SchoolMapping = (props: any) => {
    const scoList = props.schoolList.schoolList.result
    // console.log('react', scoList)
    const handleUpdate = (e: any, schoolMappingData: any) => {
        // console.log("handleUpdate" ,schoolMappingData);
      props.handleUpdate(e, schoolMappingData);
    
    }
    return(
        <div className="horizontalScrollBar">
            <table className="ng-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>BLOOM SCHOOL NAME</th>
                        <th>DISTRICT</th>
                        <th>CITY</th>
                        <th>STATE</th>
                        <th>DISTRICT SCHOOL ID</th>
                        <th>DISTRICT SCHOOL LMS ID</th>
                        <th>SCHOOL TYPE</th>
                        <th>STATUS</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        scoList && scoList.map((schoolItem: any, i: number)=>{
                            return(
                            <tr>
                                <td>{i=i+1}</td>
                                <td>{schoolItem.schoolName}</td>
                                <td>{schoolItem.districtName}</td>
                                <td>{schoolItem.city}</td>
                                <td>{schoolItem.state}</td>
                                <td>{schoolItem.districtSchoolId}</td>
                                <td>{schoolItem.districtSchoolLMSId}</td>
                                <td>{schoolItem.schoolType}</td>
                                <td>{schoolItem.status}</td>
                        <td>
                            <div>

                        {(schoolItem && schoolItem.status==="active")?
        
                            <Button className="generic-btn" variant="success" onClick={e=> handleUpdate(e,schoolItem)}>Activate</Button>
                            : 
                            <Button className="generic-btn" variant="success" onClick={e=> handleUpdate(e,schoolItem)}>Deactivate</Button>
                        }
                            </div></td>
                    </tr>
                            )
                            })
                            }           
                </tbody>
            </table>            
        </div>
   )
}