import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {  StudentService } from '../../../services'

const initialState = {
  enrolledStudentList: [],
  status: 'idle',
  error: null
}

export const EnrolledStudentInformation: any = createAsyncThunk('student/EnrolledStudentInformation', async (data) => {
  const studentService = new StudentService();
  const response = await studentService.enrolledStudentFetch(data);
  return response
})

export const enrollSlice = createSlice({
  name: 'Enrolledstudent',
  initialState,
  reducers: {},
  extraReducers: {
    [EnrolledStudentInformation.pending]: (state, action) => {
      console.log('action payload in extraReducers!!!', action.payload)
      state.status = 'loading'
    },
    [EnrolledStudentInformation.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      console.log('action payload in extraReducers!!!', action.payload)
      state.enrolledStudentList = action.payload
    },
    [EnrolledStudentInformation.rejected]: (state, action) => {
      console.log('action payload in extraReducers!!!', action.payload)
      state.status = 'failed'
      state.error = action.error.message
    }
  }
})
  
export default enrollSlice.reducer