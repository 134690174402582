import { useState, useContext, useEffect } from 'react';
import AdminLayout from '../layouts/AdminLayout';
import {  useDispatch, useSelector } from 'react-redux';
import AppContext from '../store/AppContext';
import IntegrationHome from '../components/integration/Home';
import { Box, Modal } from '@mui/material';
import OneRosterAPI from '../components/integration/OneRsoterAPI';
import SelectSchools from '../components/integration/SelectSchools';
import SelectCourses from '../components/integration/SelectCourses';
import LoginOptions from '../components/integration/LoginOptions';
import SSOText from '../components/integration/SSOText';
import { changeActivePage } from '../redux/features/user/user.slice';
import Logo from "../assets/images/Aauti_School_Logo.svg";
import CSVUpload from '../components/integration/CSVUpload';
import ChooseUserId from '../components/integration/ChooseUserId';
import {  SelectdTeacher } from '../components/integration/SelectTeachers';
import { SelectdStudent } from '../components/integration/SelectStudent';
import { CreateIds } from '../components/integration/CreateIds';
import UserPassword from '../components/integration/UserPassword';
import PasswordPolicy from '../components/integration/PasswordPolicy';
import IsSynchronozation from '../components/integration/IsSynchronozation';
import SyncSchedule from '../components/integration/SyncSchedule';
import Support from '../components/integration/Support';
import { useNavigate } from 'react-router-dom';
import { fetchOneRosterData } from '../redux/features/integration/integration.slice';


function IntegrationConsoleContainer() {
    const dispatch = useDispatch()
    const appContext = useContext(AppContext);
    const { districtId }: any = appContext?.userDetails && appContext.userDetails.districtId ? appContext.userDetails : {};
    const intergaration = useSelector((state: any) => state.user.showIntegrationConsole);
    useEffect(() => {
        dispatch(changeActivePage("integration"))
    },[])
    const Data = useSelector((state: any) => state?.integration?.integratedFlowProps)
    const adminInfo = appContext?.userDetails;
    const navigate = useNavigate();
    const teacherTableData:any = []
    const studentTableData:any = []
    const checkA:any=[]
  
    function toEndUp(){
        navigate(`/${districtId}/dashboard`)
    }
  
  const onOneRosterNavigate = (currentBody: string,val:any) => {
    console.log('data ', currentBody,val)
    const currentIntegrationBody = Object.assign({}, integrationBody);
    if(currentBody === 'home') {
        currentIntegrationBody.currentBody = 'home'
        currentIntegrationBody.body = <IntegrationHome onOneRosterNavigate={onOneRosterNavigate} />;
     } else if(currentBody === 'oneroster') {
        currentIntegrationBody.currentBody = 'oneroster'
        currentIntegrationBody.body = <OneRosterAPI onOneRosterNavigate={onOneRosterNavigate} />;
    } else if(currentBody === 'select-schools') {
        currentIntegrationBody.currentBody = 'select-schools'
        currentIntegrationBody.body = <SelectSchools onOneRosterNavigate={onOneRosterNavigate} />;
    } else if(currentBody === 'select-courses') {
        currentIntegrationBody.currentBody = 'select-courses'
        currentIntegrationBody.body = <SelectCourses onOneRosterNavigate={onOneRosterNavigate}/>;
    } else if(currentBody === 'login-options') {
        currentIntegrationBody.currentBody = 'login-options'
        currentIntegrationBody.body = <LoginOptions onOneRosterNavigate={onOneRosterNavigate} val={val}/>;
    } else if(currentBody === 'sso') {
        currentIntegrationBody.body = <SSOText onOneRosterNavigate={onOneRosterNavigate} />;
        currentIntegrationBody.currentBody = 'sso'
    }else if(currentBody === 'userid') {
        currentIntegrationBody.body = <ChooseUserId onOneRosterNavigate={onOneRosterNavigate} />;
        currentIntegrationBody.currentBody = 'userid'
    }else if(currentBody === 'userpassword') {
        currentIntegrationBody.body = <UserPassword onOneRosterNavigate={onOneRosterNavigate} />;
        currentIntegrationBody.currentBody = 'userpassword'
    }else if(currentBody === 'passwordpolicy') {
        currentIntegrationBody.body = <PasswordPolicy onOneRosterNavigate={onOneRosterNavigate} />;
        currentIntegrationBody.currentBody = 'passwordpolicy'
    }else if(currentBody === 'isSycn') {
        currentIntegrationBody.body = <IsSynchronozation onOneRosterNavigate={onOneRosterNavigate} />;
        currentIntegrationBody.currentBody = 'isSycn'
    }else if(currentBody === 'syncschedule') {
        currentIntegrationBody.body = <SyncSchedule onOneRosterNavigate={onOneRosterNavigate} />;
        currentIntegrationBody.currentBody = 'syncschedule'
    }else if(currentBody === 'completeFlow') {
        toEndUp()
    }   
    setIntegrationBody(currentIntegrationBody);
  }
  const onCSVNavigate=(currentBody: string)=>{
    
    const currentIntegrationBody = Object.assign({}, integrationBody);
    if(currentBody === 'createIds') {
        currentIntegrationBody.currentBody = 'createIds'
        currentIntegrationBody.body = <CreateIds tableData={studentTableData} onCSVNavigate={onCSVNavigate} />;
     } 
     else if(currentBody === 'teacher') {
        currentIntegrationBody.body = <SelectdTeacher onCSVNavigate={onCSVNavigate} tableData={teacherTableData}  />;
        currentIntegrationBody.currentBody = 'teacher'
    }
    else if(currentBody === 'student') {
        currentIntegrationBody.body = <SelectdStudent onCSVNavigate={onCSVNavigate} tableData={studentTableData}  />;
        currentIntegrationBody.currentBody = 'student'
    }
    else if(currentBody === 'studentCSV') {
        currentIntegrationBody.body = <CSVUpload onCSVNavigate={onCSVNavigate} name={"Student"} tableData={studentTableData}/>;
        currentIntegrationBody.currentBody = 'studentCSV'
    }
    else if(currentBody === 'aauticsv') {
        console.log(currentBody,"12345")
        currentIntegrationBody.body = <CSVUpload onCSVNavigate={onCSVNavigate} name={"Teacher"} tableData={teacherTableData}/>;
        currentIntegrationBody.currentBody = 'aauticsv'
    }else if(currentBody === 'userpassword') {
        currentIntegrationBody.body = <UserPassword onCSVNavigate={onCSVNavigate} a={"CSV"} />;
        currentIntegrationBody.currentBody = 'userpassword'
    }else if(currentBody === 'passwordpolicy') {
        currentIntegrationBody.body = <PasswordPolicy onCSVNavigate={onCSVNavigate} a={"CSV"} checkA={checkA}/>;
        currentIntegrationBody.currentBody = 'passwordpolicy'
    }else if(currentBody === 'isSycn') {
        currentIntegrationBody.body = <IsSynchronozation onCSVNavigate={onCSVNavigate}  a={"CSV"}/>;
        currentIntegrationBody.currentBody = 'isSycn'
    }else if(currentBody === 'support') {
        currentIntegrationBody.body = <Support onCSVNavigate={onCSVNavigate}  a={"CSV"}/>;
        currentIntegrationBody.currentBody = 'isSycn'
    }
    setIntegrationBody(currentIntegrationBody);

  }

  const [integrationBody, setIntegrationBody] = useState({body: <IntegrationHome onOneRosterNavigate={onOneRosterNavigate} onCSVNavigate={onCSVNavigate} />, currentBody: 'home'})


  return (
      <>
        <AdminLayout page='integration'>
        <Modal
            open={true}
            >
            <Box sx={{height:'93vh', width: '95vw', backgroundColor: "#fff",marginLeft:'2.5vw',marginTop:'4vh', outline:'0', borderRadius:'10px'}}>
            <Box sx={{ height: "12vh", display: "flex", justifyContent: "right", alignItems: "center", marginRight: "3vw" }}>
            <img src={Logo} height="45px" />
            </Box>
            {intergaration?
            <Box sx={{display: "flex", justifyContent:'center'}}>
                {integrationBody.body}
            </Box>
            :
            <Box sx={{display: "flex", justifyContent:'center'}}>
                <Support toEndUp={toEndUp}/>
            </Box>
            }
            </Box>
            </Modal>
            </AdminLayout>
      </>
    );
}

export default IntegrationConsoleContainer;

            