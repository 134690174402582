import React, { useContext } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate } from "react-router-dom";
import "./navMenu.css";
import AppContext from '../../store/AppContext';
import { useSelector, useDispatch } from 'react-redux';
import { changeActivePage } from '../../redux/features/user/user.slice';
import {
    HomeRounded,
    FolderRounded,
    MenuBookRounded,
  } from "@mui/icons-material";

export const NavMenu = ({page}: any) => {
    
    const navigate = useNavigate();
    const appContext = useContext(AppContext);

    const activePage = useSelector((state: any) => state.user.activePage);
    const dispatch = useDispatch();
    const { districtId, role }: any = appContext?.userDetails && appContext.userDetails.districtId ? appContext.userDetails : {};

    const changeActiveMenu = (menu: any) => {
        console.log("from change active menu method")
        navigate("/integration")
    }

    const activeMenu = activePage

    return(
        // To bring the left nav bar height down it is been hard-codded here in style
        <div className="navBar" >
            <ListGroup variant="flush">
                <ListGroup.Item className={"home" === activeMenu? "active" : "navMenu" }>
                    {"home" === activeMenu ? 
                        <HomeRounded fontSize="inherit" sx={{ color: "#0abced",height:16,width:16}} />
                        :
                        <HomeRounded fontSize="inherit" sx={{ color: "#fff",height:16,width:16}}/>}
                    <Link to={`/${districtId}/dashboard`}>Home</Link>
                </ListGroup.Item>

                <ListGroup.Item className={"admin" === activeMenu ? "active" : "navMenu" }>
                    {"admin" === activeMenu ? 
                        <FolderRounded fontSize="inherit" sx={{ color: "#0abced"}} />
                        :
                        <FolderRounded fontSize="inherit" sx={{ color: "#fff"}}/>}
                    <Link to={`/${districtId}/admin`}>Admin</Link>
                </ListGroup.Item>

                <ListGroup.Item className={"reports" === activeMenu ? "active" : "navMenu" }>
                    {"reports" === activeMenu ? 
                        <FolderRounded fontSize="inherit" sx={{ color: "#0abced"}} />
                        :
                        <FolderRounded fontSize="inherit" sx={{ color: "#fff"}}/>}
                    <Link to={`/${districtId}/reports`}>Reports</Link>
                </ListGroup.Item>

                <ListGroup.Item className={"integration" === activeMenu? "active" : "navMenu" }>
                    {"integration" === activeMenu ? 
                        <FolderRounded fontSize="inherit" sx={{ color: "#0abced"}} />
                        :
                        <FolderRounded fontSize="inherit" sx={{ color: "#fff"}}/>}
                    <Link to={`/${districtId}/integration`}>Integration Console</Link>
                </ListGroup.Item>

                <ListGroup.Item className={"staff" === activeMenu? "active" : "navMenu" }>
                    {"staff" === activeMenu ? 
                        <MenuBookRounded fontSize="inherit" sx={{ color: "#0abced"}} />
                        :
                        <MenuBookRounded fontSize="inherit" sx={{ color: "#fff"}}/>}
                    <Link to={`/${districtId}/staff`}>Staff Roster</Link>
                </ListGroup.Item>

                {role && (role === 'contentAdmin' || role === 'superAdmin') && <ListGroup.Item className={"articles" === activeMenu ? "active" : "navMenu" }>
                    {"articles" === activeMenu ? 
                        <FolderRounded fontSize="inherit" sx={{ color: "#0abced"}} />
                        :
                        <FolderRounded fontSize="inherit" sx={{ color: "#fff"}}/>}
                    <Link to={`/${districtId}/articles`}>Articles</Link>
                </ListGroup.Item>}

                <ListGroup.Item className={"student" === activeMenu? "active" : "navMenu" }>
                    {"student" === activeMenu ? 
                        <FolderRounded fontSize="inherit" sx={{ color: "#0abced"}} />
                        :
                        <FolderRounded fontSize="inherit" sx={{ color: "#fff"}}/>}
                    <Link to={`/${districtId}/student`}>Student Roster</Link>
                </ListGroup.Item>

                <ListGroup.Item className={"policies" === activeMenu? "active" : "navMenu" }>
                    {"policies" === activeMenu ? 
                        <FolderRounded fontSize="inherit" sx={{ color: "#0abced"}} />
                        :
                        <FolderRounded fontSize="inherit" sx={{ color: "#fff"}}/>}
                    <Link to={`/${districtId}/policies/security-policies`}>Security Policies</Link>
                </ListGroup.Item>

                <ListGroup.Item >
                    {"" === activeMenu ? 
                        <FolderRounded fontSize="inherit" sx={{ color: "#0abced"}} />
                        :
                        <FolderRounded fontSize="inherit" sx={{ color: "#fff"}}/>}
                <Dropdown className='d-flex flex-row'>
                    <Dropdown.Toggle id="dropdown-basic" className={"settings" === activeMenu ? "font-weight-bold" : ''} >
                        District Settings
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item className="navMenu p-2" >
                            <Link to={`/${districtId}/settings/school-mapping`} onClick={() => {dispatch(changeActivePage("settings"))}}>School Mapping</Link>
                        </Dropdown.Item>
                        <Dropdown.Item className="navMenu p-2">
                            <Link to={`/${districtId}/settings/jobrole-mapping`} onClick={() => {dispatch(changeActivePage("settings"))}}>Job Role Mapping</Link>
                        </Dropdown.Item>
                        <Dropdown.Item className="navMenu p-2">
                            <Link to={`/${districtId}/settings/district-parameters`} onClick={() => {dispatch(changeActivePage("settings"))}}>District Parameters</Link>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>      
                </ListGroup.Item>
            </ListGroup>
        </div>
    )
}