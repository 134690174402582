import * as React from 'react';
import { useState } from 'react';
import { Box, Button} from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";
import {integratedFlowChanges} from "../../redux/features/integration/integration.slice"
import { useDispatch,useSelector } from 'react-redux';
import { toast} from 'react-toastify';

export default function UserPassword({onOneRosterNavigate,name,a,onCSVNavigate}: any) {
  const dispatch = useDispatch()

  const Data = useSelector((state: any) => state?.integration?.integratedFlowProps)

  const [checkA, SetCheckA] = useState(false);
  const [checkB, SetCheckB] = useState(false);
  const [checkC, SetCheckC] = useState(false);

  const [disableBox1, setDisableBox1] = useState(false);
  const [disableBox2, setDisableBox2] = useState(false);
  const [disableBox3, setDisableBox3] = useState(false);

  const [staffPass, setStaffPass] = useState('');
  const [studPass, setStudPass] = useState('');
  const [policyCompCheck, setPolicyCompCheck] = useState('');

  function handleClickA() {
    SetCheckA(!checkA)
    SetCheckB(false)
    SetCheckC(false)
  }
  function handleClickB() {
    SetCheckA(false)
    SetCheckB(!checkB)
  }
  function handleClickC() {
    SetCheckA(false)
    SetCheckC(!checkC)
  }

  function buttonContinue(){
    if(studPass.length>0||staffPass.length>0){
      if(staffPass.length>0){
    dispatch(integratedFlowChanges({'staffPassword':staffPass}))
      }
      if(studPass.length>0){
    dispatch(integratedFlowChanges({'studentPassword':studPass}))
      }
    dispatch(integratedFlowChanges({'policyCompCheck':policyCompCheck}))
    if(checkA){
    dispatch(integratedFlowChanges({'forceStaffToChangePassword':checkA}))
    dispatch(integratedFlowChanges({'forceStudentToChangePassword':checkA}))
    }else{
      dispatch(integratedFlowChanges({'forceStaffToChangePassword':checkB}))
      dispatch(integratedFlowChanges({'forceStudentToChangePassword':checkC}))
    }
    if(a==="CSV"){
      onCSVNavigate('passwordpolicy')
    }else{
      onOneRosterNavigate('passwordpolicy')
    }
  }else{
    toast("Select password for student or staff",{type: toast.TYPE.ERROR})
  }
  }

  function staffNstudent(e:any) {
    let a = e.value
    if(a.length>0){
      setDisableBox2(true)
      setDisableBox3(true)
      setStaffPass(a)
      setStudPass(a)
      setPolicyCompCheck("Staff & Student")
    }else{
      setDisableBox2(false)
      setDisableBox3(false)
      setStaffPass('')
      setStudPass('')
      setPolicyCompCheck("")
    }
  }
  function staffPassFun(e:any) {
    let a = e.value
    if(a.length>0){
      setDisableBox1(true)
      setDisableBox3(true)
      setStaffPass(a)
      setPolicyCompCheck("Staff")
    }else{
      // if(studPass.length==0){
      setDisableBox1(false)
      setDisableBox3(false)
      setPolicyCompCheck("")
      // }
      setStaffPass('')
    }
  }
  function studentPassFun(e:any) {
    let a = e.value
    if(a.length>0){
      setDisableBox1(true)
      setDisableBox2(true)
      setStudPass(a)
      setPolicyCompCheck("Student")
    }else{
      setDisableBox1(false)
      setDisableBox2(false)
      setPolicyCompCheck("")
      // }
      setStudPass('')
    }
  }

  return (
    <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '540px', width: '1044px', borderRadius: '20px' }}>
      <Box sx={{ marginLeft: 5 }}
        onClick={a === "CSV" ? () => onCSVNavigate('createIds') : () => onOneRosterNavigate('userid')}>
        <img src={backArrow} height="40px" id="oneRoasterBackButtonFromUserPassword"/>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '300px' }}>
          <h1 style={{ color: '#1e1e1e', fontWeight: 600, marginBottom: '4vh', textAlign: 'center', fontSize: '20px' }}>User Password</h1>
          <Box >
            <label style={{ paddingBottom: '3px', color: '#333333', fontSize: '12px', fontWeight: 500 }} id="temporaryPasswordLabel">Set a temporary Password for Staff & Student</label>
            <input className="MuiInput-input" id="temporaryPasswordInput" disabled={disableBox1} placeholder='enter password' style={{ backgroundColor: '#ffff', border: 'solid 1px #e9e9e9', width: '100%', height: '30px', padding: '5px', fontSize: '11px', fontWeight: 500 }} onChange={(e)=>staffNstudent(e.target)}/>
            <Box>
              <input
              id="clickChangePasswordOnFirstLoginInput"
                type="checkbox"
                checked={checkA}
                onChange={handleClickA}
                style={{ height: '10px' }}
                disabled={disableBox1}
              />
              <label style={{ paddingLeft: '5px', fontSize: '11px', fontWeight: 500 }} id="clickChangePasswordOnFirstLoginLabel">Force user to change password on first login</label>
            </Box>
          </Box>
          <p style={{ textAlign: 'center', fontSize: 'bolder', margin: '20px 0px',fontWeight:"bold",color:"black" }}>or</p>
          <Box>
            <label style={{ paddingBottom: '3px', color: '#333333', fontSize: '12px', fontWeight: 500 }} id="tempPasswordForStaffLabel">Set a temporary Password for Staff</label>
            <input className="MuiInput-input" id="tempPasswordForStaffInput" disabled={disableBox2} placeholder='enter password' style={{ backgroundColor: '#ffff', border: 'solid 1px #e9e9e9', width: '100%', height: '30px', padding: '5px', fontSize: '11px', fontWeight: 500 }} onChange={(e)=>staffPassFun(e.target)}/>
            <Box>
              <input
              id="clickChangepasswordForStaffInput"
                type="checkbox"
                checked={checkB}
                onChange={handleClickB}
                style={{ height: '10px' }}
                disabled={disableBox2}
              />
              <label style={{ paddingLeft: '5px', fontSize: '11px', fontWeight: 500 }} id="clickChangepasswordForStaffLabel">Force user to change password on first login</label>
            </Box>
          </Box>
          <Box sx={{ paddingTop: '25px' }}>
            <label style={{ paddingBottom: '3px', color: '#333333', fontSize: '12px', fontWeight: 500 }} id="tempPasswordForStudentLabel">Set a temporary Password for Student</label>
            <input className="MuiInput-input" id="tempPasswordForStudentInput" disabled={disableBox3} placeholder='enter password' style={{ backgroundColor: '#ffff', border: 'solid 1px #e9e9e9', width: '100%', height: '30px', padding: '5px', fontSize: '11px', fontWeight: 500 }} onChange={(e)=>studentPassFun(e.target)}/>
            <Box >
              <input
              id='clickChangePasswordForStudentInput'
                type="checkbox"
                checked={checkC}
                onChange={handleClickC}
                style={{ height: '10px' }}
                disabled={disableBox3}
              />
              <label style={{ paddingLeft: '5px', fontSize: '11px', fontWeight: 500 }} id="clickChangePasswordForStudentLabel">Force user to change password on first login</label>
            </Box>
          </Box>
        </Box>
      </Box>
      <div style={{ textAlign: 'center', marginTop: '8vh' }}>
        <Button onClick={buttonContinue} style={{ padding: '8px 110px', textTransform: 'none' }} variant="contained" id="nextButtonFromUserPasswordScreen">Next</Button>
      </div>
    </Box>
  );
}