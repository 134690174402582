import { BaseService } from './base.service';

export class ChangePasswordService extends BaseService {

    public async changePasswordSendEmailLink(data: any) {
        const url = "/async-svc/user/password/link"
        return await this.postData(url, data);
    }
    
    public async validateLink(data: any) {
        const url = "/async-svc/user/password/link/validation"
        return await this.postData(url, data);
    }
    
    public async changePassword(data: any) {
        const url = "/async-svc/user/password/"
        return await this.updateInfo(url, data);
    }
}
