import React from "react";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import useToast from "../../shared/components/Toast";
import "./index.css";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const showToast = useToast();

  const togglePasswordVisibility = () => {
    setShowPassword((value) => !value);
  };

  const onChangeConfirmPassword = (event: any) =>
    setConfirmPassword(event.target.value);
  const onChangePassword = (event: any) => setPassword(event.target.value);

  useEffect(() => {
    if (passwordMatchError === true) {
      showToast({
        message: "New password and confirm password are didn't match",
        type: "error",
      });
    }
  }, [passwordMatchError]);

  const renderPassword = () => {
    // const passwordRe = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const passwordRe =
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
    if (!passwordRe.test(password)) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const validatePasswords = () => {
    if (!password || !confirmPassword) {
      setPasswordError(true);
    } else {
      if (password !== confirmPassword) {
        console.log("------");
        setPasswordMatchError(true);
      } else {
        setPasswordMatchError(false);
      }
    }
  };

  return (
    <div className="reset-password-container d-flex flex-row">
      <div className="reset-card">
        <div className="loginRightScreen col-12">
          <div className="login-container login-pad">
            <div className="logo-main">
              <img
                src={process.env.PUBLIC_URL + "/logo-main.png"}
                alt="bloom-logo"
              />
            </div>
            <div className="mb-4 text-center title-md">
              Please reset your password
            </div>
            <div className="form-group cont mt-4">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>New Password</Form.Label>
                  <Form.Text
                    className={passwordError ? "err-msg text" : "text"}
                  >
                    (minimum of 8 characters contains Uppercase characters
                    (A-Z), Lowercase characters (a-z), Digits (0-9) and Special
                    characters(!@#$%^&*))
                  </Form.Text>
                  <div className="password-input-container">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      onBlur={renderPassword}
                      onChange={onChangePassword}
                      value={password}
                    />

                    {showPassword ? (
                      <AiFillEyeInvisible
                        className="eye-icon"
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <AiFillEye
                        className="eye-icon"
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={onChangeConfirmPassword}
                  />
                </Form.Group>
                {passwordMatchError && (
                  <Form.Text className="err-msg">
                    *Passwords are didn't match
                  </Form.Text>
                )}
                <Button
                  className="mb-1 btn-color btn-txt"
                  variant="info"
                  onClick={validatePasswords}
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
