
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";
import { useSelector,useDispatch } from 'react-redux';
import { integratedFlowChanges } from '../../redux/features/integration/integration.slice';


export default function PasswordPolicy({onOneRosterNavigate,onCSVNavigate,a,rosterMethod,checkA}: any) {
  const dispatch = useDispatch()
  const [checkSelection, SetCheckSelection] = useState(false);
  const [checkStudent, SetCheckStudent] = useState(false);
  const [checkStaff, SetCheckStaff] = useState(false);
  const [checkPassword, SetCheckPassword] = useState(false);
  const [staffSelection, setStaffSelection] = useState<any>({duration: '30'})
  const [studentSelection, setStudentSelection] = useState<any>({duration: '30'})
  const passPrevCheck = useSelector((state: any) => state?.integration?.integratedFlowProps)
  const [staffIpValue, setStaffIpValue] = useState(5);
  const [studentIpValue, setStudentIpValue] = useState(5);

  const Enrollment = styled(Paper)(({ theme }) => ({
    width: '100px',
    height: '30px',
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
    marginRight: '3%',
    cursor: 'pointer'
  }));

  function buttonContinueStaff(){
    dispatch(integratedFlowChanges({
      staffPasswordPolicy:{
        length:staffIpValue,
        specialCharAllowed: checkSelection,
        upperAndLowerAllowed: checkStudent,
        duration: staffSelection.duration
      }
  }))
    if(a==="CSV"){
      onCSVNavigate('isSycn')
    }else{
      onOneRosterNavigate('isSycn')
    }
  }

  function buttonContinueStudent(){
    dispatch(integratedFlowChanges({
      studentPasswordPolicy:{
        length:studentIpValue,
        specialCharAllowed: checkStaff,
        upperAndLowerAllowed: checkPassword,
        duration: studentSelection.duration
    }
  }))
    if(a==="CSV"){
      onCSVNavigate('isSycn')
    }else{
      onOneRosterNavigate('isSycn')
    }
  }
  function buttonContinueStaffNstudent(){
    dispatch(integratedFlowChanges({
      staffPasswordPolicy:{
        length:staffIpValue,
        specialCharAllowed: checkSelection,
        upperAndLowerAllowed: checkStudent,
        duration: staffSelection.duration
      }
  }))
  dispatch(integratedFlowChanges({
    studentPasswordPolicy:{
      length:studentIpValue,
      specialCharAllowed: checkStaff,
      upperAndLowerAllowed: checkPassword,
      duration: studentSelection.duration
  }
}))
  if(a==="CSV"){
    onCSVNavigate('isSycn')
  }else{
    onOneRosterNavigate('isSycn')
  }
 

  }
  function handleClickC() {
    SetCheckSelection(!checkSelection) 
  }
  function handleClickB() {
    SetCheckStudent(!checkStudent)
  }
  function handleClickD() {
    SetCheckStaff(!checkStaff) 
  }
  function handleClickE() {
    SetCheckPassword(!checkPassword)
  }

  function staffIpChange(e:any){
    setStaffIpValue(e.value)
  }
  function studentIpChange(e:any){
    setStudentIpValue(e.value)
  }
  const getStudent=()=>{
    return(
      <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '600px', width: '1044px', borderRadius: '20px' ,display:'flex',flexDirection:"row"}}>
        <Box style={{ margin: '30px', paddingBottom: '10px' }} 
      onClick={ a==="CSV"? () => onCSVNavigate('userpassword'):() => onOneRosterNavigate('userpassword')}>
      <img src={backArrow} height="40px" id="backButtonFromPasswordPolicyForStudent"/>
    </Box>
      <Box sx={{display:'flex',flexDirection:'row',justifyContent:"center",alignContent:'center',marginLeft:20,width:'500px'}}>
      
      <Box sx={{marginTop:5,}}>
    <h1 style={{ color: '#1e1e1e', fontWeight: 500, marginBottom: '4vh', textAlign: 'center', fontSize: '20px' }}>Password Policy for Student</h1>
    <Box sx={{ display: 'flex', justifyContent: 'center', }}>
      <Box sx={{ width: '300px' }}>
        <Box>
        <label style={{ paddingBottom: '3px', color: '#333333' }} id="oneRoasterLengthPasswordLabelForStudent">Length Password</label>
        <input type='number' className="MuiInput-input" value={studentIpValue} min={5} style={{ backgroundColor: '#ffff', border: 'solid 1px #e9e9e9', width: '100%', height: '30px' }} onChange={(e)=>studentIpChange(e.target)} id="oneRoasterLengthPasswordInputForStudent"/>
        <Box sx={{marginTop:'13px'}}>
            <input
            id="oneRoasterSpecialCharactersCheckboxForStudent"
              type="checkbox"
              checked={checkStaff}
              onChange={handleClickD}
              style={{height:'11px'}}
            />
            <label style={{ paddingLeft: '5px' }} id="oneRoasterSpecialCharactersLabelForStudent">Special Characters</label>
          </Box>
          <Box sx={{marginTop:'13px'}}>
            <input
            id="oneRoasterUpperandLowerCaseCheckboxForStudent"
              type="checkbox"
              checked={checkPassword}
              onChange={handleClickE}
              style={{height:'11px'}}
            />
            <label style={{ paddingLeft: '5px' }} id="oneRoasterUpperandLowerCaseLabelForStudent">Upper and Lower Case</label>
          </Box>
          </Box>
        <label style={{ paddingBottom: '10px', color: '#333333', marginTop:'20px' }} id='oneroasterPasswordDurationLabelForStudent'>Password Duration</label>
        <Stack direction="row" spacing={1}>
            <Enrollment onClick={(e) => setStudentSelection({duration: '30'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:studentSelection.duration == '30' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: studentSelection.duration == '30' ? '#ffffff':'#1e1e1e'}} square={false} id="30daysStudentSelectionDuration">30 Days</Enrollment>
            <Enrollment onClick={(e) => setStudentSelection({duration: '60'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:studentSelection.duration == '60' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: studentSelection.duration == '60' ? '#ffffff':'#1e1e1e'}} square={false} id="60daysStudentSelectionDuration">60 Days</Enrollment>
            <Enrollment onClick={(e) => setStudentSelection({duration: '90'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:studentSelection.duration == '90' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: studentSelection.duration == '90' ? '#ffffff':'#1e1e1e'}} square={false} id="90daysStudentSelectionDuration">90 Days</Enrollment>
            <Enrollment onClick={(e) => setStudentSelection({duration: '120'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:studentSelection.duration == '120' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: studentSelection.duration == '120' ? '#ffffff':'#1e1e1e'}} square={false} id="120daysStudentSelectionDuration">120 Days</Enrollment>
            <Enrollment onClick={(e) => setStudentSelection({duration: '180'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:studentSelection.duration == '180' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: studentSelection.duration == '180' ? '#ffffff':'#1e1e1e'}} square={false} id="180daysStudentSelectionDuration">180 Days</Enrollment>
            
        </Stack>
          
      </Box>
    </Box>
    <div style={{ textAlign: 'center', marginTop: '15vh',marginLeft:25,justifyContent:"center" }}>
     <Button onClick= {buttonContinueStudent} style={{ padding: '8px 110px', textTransform: 'none' }} variant="contained" id="saveButtonForStudentPasswordPolicy">Save</Button>
  
   </div>
    </Box>
   
    </Box>
   
</Box>
    )
    
  }
  const getStaff=()=>{
    return(
      <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '520px', width: '1044px', borderRadius: '20px' ,display:'flex',flexDirection:"row"}}>
         <Box style={{ margin: '30px', paddingBottom: '10px' }} 
      onClick={ a==="CSV"? () => onCSVNavigate('userpassword'):() => onOneRosterNavigate('userpassword')}>
      <img src={backArrow} height="40px" id='backButtonFromPasswordPolicyForStaff'/>
    </Box>
      <Box sx={{display:'flex',flexDirection:'row',justifyContent:"space-between",marginLeft:30,width:'500px'}}>
     
      <Box sx={{marginTop:5}}>

    <h1 style={{ color: '#1e1e1e', fontWeight: 500, marginBottom: '4vh', textAlign: 'center', fontSize: '20px' }}>Password Policy for Staff</h1>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '300px' }}>
        <Box>
        <label style={{ paddingBottom: '3px', color: '#333333' }} id="oneRoasterLengthPasswordLabelForStaff">Length Password</label>
        <input type='number' className="MuiInput-input" min={5} value= {staffIpValue} style={{ backgroundColor: '#ffff', border: 'solid 1px #e9e9e9', width: '100%', height: '30px' }} onChange={(e)=>staffIpChange(e.target)} id="oneRoasterLengthPasswordInputForStaff"/>
        <Box sx={{marginTop:'13px'}}>
            <input
            id="SpecialCharactersCheckboxForStaff"
              type="checkbox"
              checked={checkSelection}
              onChange={handleClickC}
              style={{height:'11px'}}
            />
            <label style={{ paddingLeft: '5px' }} id="oneRoasterSpecialCharactersLabelForStaff">Special Characters</label>
          </Box>
          <Box sx={{marginTop:'13px'}}>
            <input
            id="oneRoasterUpperandLowerCaseCheckboxForStaff"
              type="checkbox"
              checked={checkStudent}
              onChange={handleClickB}
              style={{height:'11px'}}
            />
            <label style={{ paddingLeft: '5px' }} id="oneRoasterUpperandLowerCaseLabelForStaff">Upper and Lower Case</label>
          </Box>
          </Box>
        <label style={{ paddingBottom: '10px', color: '#333333', marginTop:'20px' }} id='oneroasterPasswordDurationLabelForStaff'>Password Duration</label>
        <Stack direction="row" spacing={1}>
            <Enrollment onClick={(e) => setStaffSelection({duration: '30'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:staffSelection.duration == '30' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: staffSelection.duration == '30' ? '#ffffff':'#1e1e1e'}} square={false} id="30daysStaffSelectionDuration">30 Days</Enrollment>
            <Enrollment onClick={(e) => setStaffSelection({duration: '60'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:staffSelection.duration == '60' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: staffSelection.duration == '60' ? '#ffffff':'#1e1e1e'}} square={false} id="60daysStaffSelectionDuration">60 Days</Enrollment>
            <Enrollment onClick={(e) => setStaffSelection({duration: '90'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:staffSelection.duration == '90' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: staffSelection.duration == '90' ? '#ffffff':'#1e1e1e'}} square={false} id="90daysStaffSelectionDuration">90 Days</Enrollment>
            <Enrollment onClick={(e) => setStaffSelection({duration: '120'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:staffSelection.duration == '120' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: staffSelection.duration == '120' ? '#ffffff':'#1e1e1e'}} square={false} id="120daysStaffSelectionDuration">120 Days</Enrollment>
            <Enrollment onClick={(e) => setStaffSelection({duration: '180'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:staffSelection.duration == '180' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: staffSelection.duration == '180' ? '#ffffff':'#1e1e1e'}} square={false} id="180daysStaffSelectionDuration">180 Days</Enrollment>
            
        </Stack>
          
      </Box>
    </Box>
   <div style={{ textAlign: 'center', marginTop: '15vh',marginLeft:20,justifyContent:"center" }}>
     <Button onClick= {buttonContinueStaff} style={{ padding: '8px 110px', textTransform: 'none' }} variant="contained" id="saveButtonForStaffPasswordPolicy">Save</Button>
   </div>
   </Box>
    </Box>
   
    </Box>
    )
  }
 

  return (
    <>
  {passPrevCheck.policyCompCheck==="Staff"&&(
    
      getStaff()
    )}

{passPrevCheck.policyCompCheck==="Student"&&(
      getStudent()
    )}
    {passPrevCheck.policyCompCheck==="Staff & Student"&&(

    <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '70vh', width: '1044px', borderRadius: '20px' }}>
     
    <Box sx={{display:'flex',flexDirection:'row',justifyContent:"space-between"}}>
   
      <Box style={{ margin: '30px', paddingBottom: '10px' }} 
      onClick={ a==="CSV"? () => onCSVNavigate('userpassword'):() => onOneRosterNavigate('userpassword')}>
      <img src={backArrow} height="40px" id='backButtonFromPasswordPolicyForStaffStudent'/>
    </Box>
    
   <Box sx={{marginTop:5}}>

    <h1 style={{ color: '#1e1e1e', fontWeight: 500, marginBottom: '4vh', textAlign: 'center', fontSize: '20px' }}>Password Policy for Staff</h1>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '300px' }}>
        <Box>
        <label style={{ paddingBottom: '3px', color: '#333333' }} id='oneRoasterLengthPasswordLabelForStudentStaff'>Length Password</label>
        <input type='number' className="MuiInput-input" min={5} value={staffIpValue} style={{ backgroundColor: '#ffff', border: 'solid 1px #e9e9e9', width: '100%', height: '30px' }} id="oneRoasterLengthPasswordInputForStaffStudent"/>
        <Box sx={{marginTop:'13px'}}>
            <input
             id="specialCharactersCheckboxForStaffStudentInput"
              type="checkbox"
              checked={checkSelection}
              onChange={handleClickC}
              style={{height:'11px'}}
            />
            <label style={{ paddingLeft: '5px' }} id="specialCharactersCheckboxForStaffStudentLabel">Special Characters</label>
          </Box>
          <Box sx={{marginTop:'13px'}}>
            <input
            id="upperandLowerCaseCheckboxForStaffStudentInput"
              type="checkbox"
              checked={checkStudent}
              onChange={handleClickB}
              style={{height:'11px'}}
            />
            <label style={{ paddingLeft: '5px' }} id="upperandLowerCaseCheckboxForStaffStudentLabel">Upper and Lower Case</label>
          </Box>
          </Box>
        <label style={{ paddingBottom: '10px', color: '#333333', marginTop:'20px' }} id="passwordDurationLabelForStaffStudent">Password Duration</label>
        <Stack direction="row" spacing={1}>
            <Enrollment onClick={(e) => setStaffSelection({duration: '30'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:staffSelection.duration == '30' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: staffSelection.duration == '30' ? '#ffffff':'#1e1e1e'}} square={false} id="30daysStaffStudnetSelectionDuration">30 Days</Enrollment>
            <Enrollment onClick={(e) => setStaffSelection({duration: '60'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:staffSelection.duration == '60' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: staffSelection.duration == '60' ? '#ffffff':'#1e1e1e'}} square={false} id="60daysStaffStudentSelectionDuration">60 Days</Enrollment>
            <Enrollment onClick={(e) => setStaffSelection({duration: '90'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:staffSelection.duration == '90' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: staffSelection.duration == '90' ? '#ffffff':'#1e1e1e'}} square={false} id="90daysStaffStudentSelectionDuration">90 Days</Enrollment>
            <Enrollment onClick={(e) => setStaffSelection({duration: '120'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:staffSelection.duration == '120' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: staffSelection.duration == '120' ? '#ffffff':'#1e1e1e'}} square={false} id="120daysStaffStudentSelectionDuration">120 Days</Enrollment>
            <Enrollment onClick={(e) => setStaffSelection({duration: '180'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:staffSelection.duration == '180' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: staffSelection.duration == '180' ? '#ffffff':'#1e1e1e'}} square={false} id="180daysStaffStudentSelectionDuration">180 Days</Enrollment>
            
        </Stack>
          
      </Box>
    </Box>
   
    </Box> 
    

    <div style={{border:"0.5px solid #d7d7d7",height:"300px",marginTop:30}}></div>

     <Box sx={{marginTop:5,marginRight:10,}}>
      <h1 style={{ color: '#1e1e1e', fontWeight: 500, marginBottom: '4vh', textAlign: 'center', fontSize: '20px' }}>Password Policy for Student</h1>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '300px' }}>
              <Box>
              <label style={{ paddingBottom: '3px', color: '#333333' }} id='oneRoasterLengthPasswordLabelForStudentStaff2'>Length Password</label>
              <input type='number' className="MuiInput-input" min={5} value={studentIpValue} style={{ backgroundColor: '#ffff', border: 'solid 1px #e9e9e9', width: '100%', height: '30px' }} id="oneRoasterLengthPasswordInputForStaffStudent2"/>
              <Box sx={{marginTop:'13px'}}>
                  <input
                   id="specialCharactersCheckboxForStaffStudentInput2"
                    type="checkbox"
                    checked={checkStaff}
                    onChange={handleClickD}
                    style={{height:'11px'}}
                  />
                  <label style={{ paddingLeft: '5px' }}  id="specialCharactersCheckboxForStaffStudentLabel2">Special Characters</label>
                </Box>
                <Box sx={{marginTop:'13px'}}>
                  <input
                  id="upperandLowerCaseCheckboxForStaffStudentInput2"
                    type="checkbox"
                    checked={checkPassword}
                    onChange={handleClickE}
                    style={{height:'11px'}}
                  />
                  <label style={{ paddingLeft: '5px' }} id="upperandLowerCaseCheckboxForStaffStudentLabel2">Upper and Lower Caserrr</label>
                </Box>
                </Box>
              <label style={{ paddingBottom: '10px', color: '#333333', marginTop:'20px' }} id="passwordDurationLabelForStaffStudent2">Password Duration</label>
              <Stack direction="row" spacing={1}>
                  <Enrollment onClick={(e) => setStudentSelection({duration: '30'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:studentSelection.duration == '30' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: studentSelection.duration == '30' ? '#ffffff':'#1e1e1e'}} square={false} id="30daysStaffStudnetSelectionDuration2">30 Days</Enrollment>
                  <Enrollment onClick={(e) => setStudentSelection({duration: '60'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:studentSelection.duration == '60' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: studentSelection.duration == '60' ? '#ffffff':'#1e1e1e'}} square={false} id="60daysStaffStudentSelectionDuration2">60 Days</Enrollment>
                  <Enrollment onClick={(e) => setStudentSelection({duration: '90'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:studentSelection.duration == '90' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: studentSelection.duration == '90' ? '#ffffff':'#1e1e1e'}} square={false} id="90daysStaffStudentSelectionDuration2">90 Days</Enrollment>
                  <Enrollment onClick={(e) => setStudentSelection({duration: '120'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:studentSelection.duration == '120' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: studentSelection.duration == '120' ? '#ffffff':'#1e1e1e'}} square={false} id="120daysStaffStudentSelectionDuration2">120 Days</Enrollment>
                  <Enrollment onClick={(e) => setStudentSelection({duration: '180'})} style={{display:'flex',flexDirection:'column',fontSize:'10px',justifyContent:'center',textAlign:'center',backgroundColor:studentSelection.duration == '180' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: studentSelection.duration == '180' ? '#ffffff':'#1e1e1e'}} square={false} id="180daysStaffStudentSelectionDuration2">180 Days</Enrollment>
                  
              </Stack>
                
            </Box>
          </Box>
          
      </Box> 
    </Box>
    <div style={{ textAlign: 'center', marginTop: '15vh',marginLeft:40,justifyContent:"center" }}>
      <Button onClick= {buttonContinueStaffNstudent} style={{ padding: '8px 110px', textTransform: 'none' }} variant="contained" id='saveButtonForPasswordPolicyStaffStudent'>Save</Button>:
    </div>
  </Box>)}
  </>
  );
}
