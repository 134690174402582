import AdminLayout from '../layouts/AdminLayout';
import { Home } from '../components/home/Home';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeActivePage, changeShowIntegrationStatus } from '../redux/features/user/user.slice';
import { checkForIntegrationInformation } from '../redux/features/integration/integration.slice';
import AppContext from '../store/AppContext';

function HomeContainer() {
  const dispatch = useDispatch()
  const appContext = useContext(AppContext);
  const adminInfo= appContext?.userDetails;

  useEffect(() => {
    dispatch(changeActivePage("home"))
  },[])
  const integrationList = useSelector((state: any) => state.integration)
 
  useEffect(()=>{
    if(integrationList.status==="succeeded"&&integrationList.integrationList.length===0){
      dispatch(changeShowIntegrationStatus(true))
    }else{
      dispatch(changeShowIntegrationStatus(false))
    }
  },[integrationList.status])
  
  useEffect(() => {
    dispatch(checkForIntegrationInformation(adminInfo?._id))
  }, [])

  return (
      <AdminLayout>
        <Home integrationList={integrationList}/>
      </AdminLayout>
    );
}

export default HomeContainer;