import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../features/user/user.slice'
import studentSlice from '../features/student/student.slice'
import jobRoleSlice from '../features/jobRole/jobRole.slice'
import enrollSlice from '../features/student/enroll.slice'
import enrollStaffSlice from '../features/staff/enroll-staff.slice'
import integrationSlice from '../features/integration/integration.slice'
import schoolSlice from '../features/school/school.slice'
import securityReducer from '../features/securityPolicies/security.slice'
import districtReducer from '../features/districtParameters/district.slice'

import staffReducer from '../features/staff/staff.slice'

export default configureStore({
  reducer: {
    user: userReducer,
    student: studentSlice,
    //security: securityReducer,
    //staff: staffReducer
    Enrolledstudent: enrollSlice,
    school: schoolSlice,
    security: securityReducer,
    staff: staffReducer,
    Enrolledstaff: enrollStaffSlice,
    district: districtReducer,
    integration: integrationSlice,
    jobRole:jobRoleSlice
  }
})