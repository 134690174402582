function LoginLayout({children}: any) {

    return (
      <>
          <div className="container mx-auto login-body">
              {children}
          </div>
      </>
    );
  }
  
  export default LoginLayout;