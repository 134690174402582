import React, { useState } from 'react';
import { CSVReader } from 'react-papaparse';
import _, { each } from 'underscore';
import {generateUsernames} from '../../redux/features/integration/integration.slice'
import {useSelector, useDispatch} from 'react-redux'
import { ProcessingImage } from '../../shared/components/ProcessingImage';
import {RiUploadCloud2Line} from "react-icons/ri"
import useToast from '../../shared/components/Toast'
import { Box,Button} from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";
import { Label } from '@mui/icons-material';

const buttonRef = React.createRef();

function CSVUpload (props){

  const {onCSVNavigate, name} = props
  console.log(props,"123456")
    const [uploaded,setUploaded] = useState(false)
    const [fileName, setFileName] = useState('')
    const [columns, setColumns] = useState([])
    // const [tableData, setTableData] = useState([])
    const [generatedUsernamesStatus, setGeneratedUsernamesStatus] = useState(false)
    const [columnFields, setColumnFields] = useState({})

    const dispatch = useDispatch()
    const showToast = useToast();
    // const generateUsernamesDetails = useSelector(state => state.integration.generateUsernamesDetails)

    // if(generateUsernamesDetails.status === "succeeded" && generatedUsernamesStatus) {
    //   let duplicateEmailCount = 0 
    //   let duplicateEmailRecords = []
    //   let validRecords = []

    //   const generatedData = generateUsernamesDetails.data
    //    generatedData.forEach(eachRow => {
    //     const uploadDetails = tableData.find(eachRecord => eachRecord.id === eachRow.id)
    //     console.log(eachRow, "each row ***************")
    //     const updatedRow =  {
    //       ...uploadDetails,
    //       [columnFields.firstName]: eachRow['firstName'],
    //       [columnFields.lastName]: eachRow['lastName'],
    //       [columnFields.username]: eachRow['username'],
    //       isDuplicate: eachRow['isDuplicate'],
    //       ...((!uploadDetails[columnFields.password]) && {[columnFields.password] : eachRow['username']})
    //     }

    //     if(eachRow?.isDuplicate.isEmailDuplicate || eachRow?.isDuplicate?.isUserIdDuplicate ||eachRow?.isDuplicate?.isTeacherIdDuplicate){
    //       duplicateEmailCount += 1
    //       duplicateEmailRecords.push(updatedRow)
    //     }else{
    //       validRecords.push(updatedRow)
    //     }
        
    //   })

    //   const userDetails = [...duplicateEmailRecords, ...validRecords]

    //   props.uploadData({columns, tableData: userDetails, duplicateEmailCount})
    //   props.uploadFileName(fileName)
    //   setGeneratedUsernamesStatus(false)
    // }

    const handleOpenDialog = (e) => {
      // Note that the ref is set async, so it might be null at some point
      
      if (buttonRef.current) {
        buttonRef.current.open(e);
      }
    };
  
    const handleOnFileLoad = (data,filename) => {
      console.log(data, filename, "----------->>>>>>")
      if(filename.name.toLowerCase().endsWith(".csv")) {
        const columns = data[0].data;
        // const tableData = [];
        for (var i=1; i < data.length; i++) {
          var obj = {}
          for (var j = 0; j < columns.length; j++) {
              columns[j] = columns[j].trim();
                obj[columns[j]] = data[i].data[j];
          }
          const emptyData = data[i].data.filter(item => !_.isEmpty(item))
          if(emptyData && emptyData.length > 0) {
            obj.id = i
            props.tableData.push(obj);
          }
        }
        // console.log(tableData, "tabledata =>=>=>=>=>=>=>=>=>")
        // setFileName(filename)
        // setColumns(columns)
        // setTableData(tableData)
  
        // const role = columns[0]?.includes("student") ? 'student' : 'staff';
        // let firstName, lastName, password, username, teacherId, userDetails, userId
  
        // if(role === 'student') {
        //   firstName = 'student_firstName'
        //   lastName = 'student_lastName'
        //   username = 'student_email'
        //   password = 'student_user_password'
        //   userId = "student_user_id"
        // } else {
        //   firstName = 'staff_firstName'
        //   lastName = 'staff_lastName'
        //   username = 'staff_email'
        //   password = 'staff_password'
        //   teacherId = 'staff_employee_id'
        // }
  
        // if(role === 'student'){
        //     userDetails = tableData.map((eachRow) => ({
        //     id: eachRow.id,
        //     firstName: eachRow[firstName],
        //     lastName: eachRow[lastName],
        //     username: eachRow[username],
        //     userId : eachRow[userId]
        //   }))
        // }else{
        //     userDetails = tableData.map((eachRow) => ({
        //     id: eachRow.id,
        //     firstName: eachRow[firstName],
        //     lastName: eachRow[lastName],
        //     username: eachRow[username],
        //     teacherId: eachRow[teacherId],
        //   }))
        // }
        
        // console.log("llllllllllllllllllllllllll",tableData, userDetails)
        // dispatch(generateUsernames({role, userDetails}))
        // setColumnFields({firstName, lastName, username, password})
        // setGeneratedUsernamesStatus(true)
  
      } else {
        showToast({ message : "You have uploaded invalid file" , type : "error" })
      }
    };

  
    const handleOnError = (err, file, inputElem, reason) => {
      console.log(err);
    };
  
    const handleOnRemoveFile = (data) => {
      console.log(data);
    };
  
    const handleRemoveFile = (e) => {
      // Note that the ref is set async, so it might be null at some point
      if (buttonRef.current) {
        buttonRef.current.removeFile(e);
      }
    };
    
  
      return (
        <>
        
        <Box sx={{backgroundColor:'rgb(215,215,215,0.1)',height:'600px',width:'1044px',borderRadius: '20px'}}>
         <Box  sx={{display:"flex",alignItems:"center",flexDirection:"row",marginTop:5,marginBottom:5}}>
         {name==="Teacher" ?
         <Box  style={{marginLeft:50}}
          onClick={() => props.onCSVNavigate('home')}
          >
        <img src={backArrow} height="40px" />
        </Box>:
        <Box style={{marginLeft:50}}
        onClick={() => props.onCSVNavigate('teacher')}
        >
      <img src={backArrow} height="40px" />
      </Box>}
        <h1 style={{color: '#1e1e1e', fontWeight: 500,  textAlign: 'center', fontSize:'26px',marginLeft:300,marginTop:5,}}>Upload {name} CSV</h1>

         </Box>
          <CSVReader
            ref={buttonRef}
            onFileLoad={handleOnFileLoad}
            onError={handleOnError}
            noClick
            noDrag
            onRemoveFile={handleOnRemoveFile}
            noProgressBar
            accept='.csv'
          >
            {({ file }) => (
              <aside
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <button 
                type="button"
                class="jumbotron"
                onClick={handleOpenDialog}
                style={{
                  marginLeft: '30%',
                  marginRight: 0,
                  width: '40%',
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '2px dashed #8687a7',
                  height: '202px',
                  borderRadius: '10px',
                  padding: '65px',
                  marginBottom: '20px',
                  color: '#3166BA',
                  textAlign: 'center',
                  marginTop: '20px',
                  background: '#fff',
                  fontSize: '18px',
                }}
                  >
                     <div><RiUploadCloud2Line className='mr-1' size={25} /> Browse File</div>
                    
                </button>
                
            <p />
              </aside>
            )}
          </CSVReader>
          {name ==="Teacher"  ? 
          <p style={{ color:'#3166ba',textAlign: 'center', fontSize:'14px'}}><a href='https://uploadpdfs.blob.core.windows.net/admin-csvs/TeacherCSV.csv'> Download teacher CSV</a></p> :
          <p style={{color: '#3166ba', textAlign: 'center', fontSize:'14px'}}><a href='https://uploadpdfs.blob.core.windows.net/admin-csvs/StudentCSV.csv'> Download student CSV</a></p>}
          <div style={{textAlign: 'center', marginTop: '10vh'}}>
            {name ==="Teacher"  ?  
            <Button 
            onClick={() => props.onCSVNavigate('teacher')}
             style={{padding: '8px 80px'}}variant="contained">Next</Button>
            
            :  <Button 
            onClick={() => props.onCSVNavigate('student')}
             style={{padding: '8px 80px'}}variant="contained">Next</Button>}
            
        </div>
        </Box>
        </>
      );

  }

export default CSVUpload
