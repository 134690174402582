import { Header } from '../components/header/Header';
import { NavMenu } from '../components/navigation/NavMenu';
import { useSelector } from 'react-redux';
import AppContext from '../store/AppContext';
import { useContext } from 'react';
import "./adminlayout.css"

function AdminLayout({children}: any) {
  const activeMenu = useSelector((state: any) => state.user.activePage);
  const showMenu = useSelector((state: any) => state.user.showMenu);
  const appContext = useContext(AppContext);
  const adminInfo = appContext?.userDetails;

    return (
      <>
        <div>
          <Header />
            <div className="admin-layout-main-container">
           <div className="admin-layout-header">
           {"integration" !== activeMenu?<><div className="admin-layout-welcome-container">
                  Welcome {adminInfo.firstName}
                  {console.log('adminInfo....', adminInfo)}
                </div>
                <div className="admin-layout-nav-menu-container">
                    <NavMenu />
                </div></>:""}
              </div>
                <div className={"integration" !== activeMenu?"admin-layout-content-container":"admin-layout-content-container-Integration"}>
                    {children}
                </div>
            </div>
          </div>
      </>
    );
  }
  
  export default AdminLayout;
  