import AdminLayout from '../layouts/AdminLayout';
import { JobRoleMapping } from '../components/district-settings/JobRoleMapping';
import { useState, useRef } from 'react';
import BloomModal from '../components/modals/BloomModal';
import {addOrUpdateJobRole, checkForJobRoleInformation, deleteJobRoleInfo} from '../redux/features/jobRole/jobRole.slice'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {ProcessingImage} from '../shared/components/ProcessingImage'
import { changeActivePage } from '../redux/features/user/user.slice';

function JobRoleMappingContainer() {
  const JobRoleList = useSelector((state: any) => state.jobRole)
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [deleteFile, setDeleteFile] = useState(false)
  const [editJobRole, setEditJobRole] = useState<any>('')
  const jobRoleModal = useRef(null)
  const addJobRole = () => {
    console.log('in the container!!!');
    setShow(true);
}

  const handleClose = () => {
      setShow(false);
  }
const handleUpdate = (e: any, jobMapping: any) => {
  e.preventDefault();
  let firstName = jobMapping.firstName;
  let lastName = jobMapping.lastName;
  let employeeId = jobMapping.employeeId;
  let bloomJobRole = jobMapping.bloomJobRole;
  let districtJobRole = jobMapping.districtJobRole;
  let recordStatus = jobMapping.recordStatus;
  ['firstName', 'lastName', 'employeeId','bloomJobRole', 'districtJobRole','recorStatus' ].forEach(e => delete jobMapping[e]);
  dispatch(addOrUpdateJobRole({"jobMapping": { 
    
      "firstName":firstName,
       "lastName":lastName,
        "employeeId":employeeId,
         "bloomJobRole":bloomJobRole,
          "districtJobRole":districtJobRole,
          "recordStatus":recordStatus
  }}))
   setShow(false);
   setEditJobRole('');
   if(JobRoleList.JobRoleList.statusCode === 200){
    setEditJobRole(true)
  } 
}
const deleteDetail = (jobMappingsData: any) => {
  dispatch(deleteJobRoleInfo({"jobMappingsData": {
    "recordStatus": jobMappingsData.recordStatus,
    "firstName": jobMappingsData.firstName,
    "lastName": jobMappingsData.lastName,
    "bloomJobRole": jobMappingsData.bloomJobRole,
    "districtJobRole":jobMappingsData.districtJobRole,
    "employeeId":jobMappingsData.employeeId,
  }}))
  if(JobRoleList.JobRoleList.statusCode === 200){
    setDeleteFile(true)
  } 
  
}  

  useEffect(() => {
    dispatch(checkForJobRoleInformation())
    if(deleteFile)
    setDeleteFile(false)
    if(editJobRole)
    setEditJobRole(false)
  }, [dispatch,deleteFile,editJobRole])

  useEffect(() => {
    dispatch(changeActivePage("settings"))
  },[])

  return (
      <AdminLayout showButton="true" handler={addJobRole} name="Add New Job Role">
        <div className="container mrgntop">
          {
            JobRoleList.status === "loading"?
             <ProcessingImage isLoading={true}/> :
            <JobRoleMapping JobRoleList={JobRoleList} setShow={setShow} setEditJobRole={setEditJobRole} deleteDetail={deleteDetail}/>
          }
        </div>
        <BloomModal modalState={show} handleClose={handleClose} handleUpdate={handleUpdate} editJobRole={editJobRole} ref={jobRoleModal}/>
      </AdminLayout>
    );
}

export default JobRoleMappingContainer;