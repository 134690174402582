import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {  StaffService } from '../../../services'

const initialState = {
  enrolledStaffList: [],
  status: 'idle',
  error: null
}

export const EnrolledStaffInformation: any = createAsyncThunk('enrolledstaff/EnrolledStaffInformation', async (data) => {
  const staffService = new StaffService();
  const response = await staffService.enrolledStaffFetch(data);
  return response
})

export const enrollStaffSlice = createSlice({
    name: 'Enrolledstaff',
    initialState,
    reducers: {},
    extraReducers: {
      [EnrolledStaffInformation.pending]: (state, action) => {
        console.log('action payload in EnrolledStaffInformation extraReducers!!!', action.payload)
        state.status = 'loading'
      },
      [EnrolledStaffInformation.fulfilled]: (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        console.log('action payload in EnrolledStaffInformation success!!!', action.payload)
        state.enrolledStaffList = action.payload
      },
      [EnrolledStaffInformation.rejected]: (state, action) => {
        console.log('action payload in EnrolledStaffInformation rejected!!!', action.payload)
        state.status = 'failed'
        state.error = action.error.message
      }
    }
  })
  
export default enrollStaffSlice.reducer