import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { LoginService, ChangePasswordService } from '../../../services'

interface UsersState {
  userProfile: any;
  status: "idle" | "loading" | "succeeded" | "failed" | "logout";
  signupStatus: any;
  error: string | null;
  changePasswordSendEmailDetails: any;
  linkValidationDetails: any;
  changePasswordDetails: any;
  impersonateUserDetails: any;
  activePage: string;
  showMenu: boolean;
  showIntegrationConsole: boolean;
}

const initialState: UsersState = { 
  userProfile: {
    email: '12345',
    password: 'password',
    status:null
  },
  status: 'idle',
  signupStatus: {
    status: 'idle',
   data:{},
    error: null
  },
  error: null,
  changePasswordSendEmailDetails: {
    status: 'idle',
    message: '',
    error: null
  },
  linkValidationDetails: {
    status: 'idle',
    error: null
  },
  changePasswordDetails: {
    status: 'idle',
    data: '',
    error: null
  },
  impersonateUserDetails:{
    status:'idle',
    data: {},
    error:null
  },
  activePage:"",
  showMenu:false,
  showIntegrationConsole: false
}

export const checkForUserProfile: any = createAsyncThunk('user/checkuser', async (data: any) => {
  const loginService = new LoginService();
  const response = await loginService.userLogin(data);
  return response
})
export const userSignUpProfile: any = createAsyncThunk('user/signUp', async (data: any) => {
  const loginService = new LoginService();
  const response = await loginService.userSignUp(data);
  return response
})

export const sendChangePasswordEmailLink: any = createAsyncThunk('user/changepasswordemail', async(data: any) => {
  const changePasswordService = new ChangePasswordService();
  const response = await changePasswordService.changePasswordSendEmailLink(data)
  return response
})

export const checkForLinkValidation: any = createAsyncThunk('user/linkvalidation', async(data: any) => {
  const changePasswordService = new ChangePasswordService();
  const response = await changePasswordService.validateLink(data)
  return response
})

export const changeUserPassword: any = createAsyncThunk('user/changeuserpassword', async(data: any) => {
  const changePasswordService = new ChangePasswordService();
  const response = await changePasswordService.changePassword(data)
  return response
})

export const saveImpersonateUserDetails: any = createAsyncThunk('user/impersonate', async(data: any) => {
  const loginService = new LoginService();
  const response = await loginService.saveImpersonateDetails(data)
  return response
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      changeStatus: (state) => {
        state.userProfile.status = null
      },
      changeActivePage: (state,action) => {
        state.activePage = action.payload
        state.showMenu = false
      },
      changeShowIntegrationStatus: (state, action) =>{
        state.showIntegrationConsole= action.payload
      },
      changeShowMenuStatus: (state,action) => {
        state.showMenu = action.payload
      },
      changeSignupStatus:(state)=>{
        state.signupStatus.status='idle'
      },
      login: {
        reducer(state, action) {
          console.log('user in thee slicee  ', action.payload)
        },
        prepare(email, password): any {
          return {
            payload: {
              user: {
                email,
                password
              }
            }
          }
        }
      }
    },
    extraReducers: {
      [checkForUserProfile.pending]: (state: any, action: any) => {
        console.log('action payload in extraReducers!!!', action.payload)
        state.status = 'loading'
      },
      [checkForUserProfile.fulfilled]: (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        console.log('action payload in extraReducers!!!', action.payload)
        state.userProfile = action.payload
      },
      [checkForUserProfile.rejected]: (state, action) => {
        console.log('action payload in extraReducers!!!', action.payload)
        state.status = 'failed'
        state.error = action.error.message
      },
      [userSignUpProfile.pending]: (state: any, action: any) => {
        state.signupStatus.status  = 'loading'
      },
      [userSignUpProfile.fulfilled]: (state, action) => {
        console.log( state.signupStatus.status,"12323233")
        state.signupStatus.status = 'succeeded'
        state.signupStatus.data  = action.payload
      },
      [userSignUpProfile.rejected]: (state, action) => {
        state.signupStatus.status  = 'failed'
        state.signupStatus.error  = action.error.message
      },

      [sendChangePasswordEmailLink.pending]: (state) => {
        state.changePasswordSendEmailDetails.status = 'loading'
      },
      [sendChangePasswordEmailLink.fulfilled]: (state, action) => {
        if(action.payload?.result?.message) {
          state.changePasswordSendEmailDetails.status = 'succeeded'
          state.changePasswordSendEmailDetails.message = action.payload.result.message
        } else if (action.payload?.error.message) {
          state.changePasswordSendEmailDetails.status = 'failed'
          state.changePasswordSendEmailDetails.error = action.payload.error.message
        }
      },
      [sendChangePasswordEmailLink.rejected]: (state, action) => {
        state.changePasswordSendEmailDetails.status = 'failed'
        state.changePasswordSendEmailDetails.error = action.error.message
      },
      
      [checkForLinkValidation.pending]: (state) => {
        state.linkValidationDetails.status = 'loading'
      },
      [checkForLinkValidation.fulfilled]: (state, action) => {
        if(action.payload?.error?.message) {
          state.linkValidationDetails.status = 'failed'
          state.linkValidationDetails.error = action.payload.error.message
        } else {
          state.linkValidationDetails.status = 'succeeded'
        }
      },
      [checkForLinkValidation.rejected]: (state, action) => {
        state.linkValidationDetails.status = 'failed'
        state.linkValidationDetails.error = action.error.message
      },

      [changeUserPassword.pending]: (state) => {
        state.changePasswordDetails.status = 'loading'
      },
      [changeUserPassword.fulfilled]: (state, action) => {
        if(action.payload?.result?.message) {
          state.changePasswordDetails.status = 'succeeded'
          state.changePasswordDetails.message = action.payload.result.message
        } else {
          state.changePasswordDetails.status = 'failed'
          state.changePasswordDetails.error = action.payload.error.message
        }
      },
      [changeUserPassword.rejected]: (state, action) => {
        state.changePasswordDetails.status = 'failed'
        state.changePasswordDetails.error = action.error.message
      },
      [saveImpersonateUserDetails.pending]: (state) => {
        state.impersonateUserDetails.status = 'loading'
      },
      [saveImpersonateUserDetails.fulfilled]: (state, action) => {
        if(action.payload?.link) {
          state.impersonateUserDetails.status = 'succeeded'
          state.impersonateUserDetails.data.link = action.payload.link
        } else {
          state.impersonateUserDetails.status = 'failed'
          state.impersonateUserDetails.error = action.payload.error.message
        }
      },
      [saveImpersonateUserDetails.rejected]: (state, action) => {
        state.impersonateUserDetails.status = 'failed'
        state.impersonateUserDetails.error = action.error.message
      }
    }
  })
  
  export const { login, changeStatus, changeActivePage, changeShowMenuStatus,changeSignupStatus, changeShowIntegrationStatus } = userSlice.actions
  
  export default userSlice.reducer