import AdminLayout from "../layouts/AdminLayout"
import { StaffTable } from "../components/staffroster/StaffTable"
import { useState, useEffect, useRef, useContext } from "react"
import AddNewStaffModal from "../components/modals/AddNewStaffModal"
import { useSelector, useDispatch } from "react-redux"
import {
  checkForStaffInformation,
  addOrUpdateStaff,
  updateStaffPassword,
} from "../redux/features/staff/staff.slice"
import { EnrolledStaffInformation } from "../redux/features/staff/enroll-staff.slice"
import { EnrollStaffTable } from "../components/staffroster/EnrollStaffTable"
// import { ProcessingImage } from "../shared/components/ProcessingImage"
import { Loading } from "../shared/Loading"
import { resetUpdateStaffPasswordDetails, resetTeacherDetails, resetAddOrUpdateStaffDetails } from "../redux/features/staff/staff.slice"

// added by premkumar
import ChangePasswordModal from "../components/modals/ChangePasswordModal"
import { changeActivePage } from "../redux/features/user/user.slice"
import useToast from "../shared/components/Toast"
import AppContext from "../store/AppContext"

function StaffRosterContainer() {
  const staffList = useSelector((state: any) => state.staff)
  const enrollList = useSelector((state: any) => state.Enrolledstaff)
  // console.log('user in staffroaster container stafflist staff:::', staffList)
  // console.log('user in staffroaster container enrolllist staff:::', enrollList)
  const dispatch = useDispatch()
  const showToast = useToast()
  const [show, setShow] = useState(false)
  const [staffSisName, setStaffSisName] = useState("")
  const [editStaff, setEditStaff] = useState("")
  const [refreshStaff, setRefreshStaff] = useState(false)
  // added by premkumar
  const [showPasswordModal, setShowPasswordModal] = useState(false)

  const staffModal = useRef<any>(null)
  const staffPasswordModal = useRef<any>(null)

  const teacherDetails = useSelector((state: any) => state.staff.teacherDetails)
  const addOrUpdateStaffDetails = useSelector((state: any) => state.staff.addOrUpdateStaffDetails)
  const updateStaffPasswordDetails = useSelector((state: any) => state.staff.updateStaffPasswordDetails)
  const appContext = useContext(AppContext);
  const adminInfo= appContext?.userDetails;
  const addJobRole = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
    setEditStaff("")
    staffModal.current.resetState()
  }

  const handleUpdate = (staffData: any) => {
    let created = staffData.created
    let updated = staffData.updated
    let IsManual = staffData.IsManual
    ;["created", "updated", "IsManual"].forEach((e) => delete staffData[e])

    dispatch(
      addOrUpdateStaff({
        teacher: {
          staff: staffData,
          IsManual: IsManual,
          created: created,
          updated: updated,
        },
      })
    )

  }

  const enrolledStaff = (id: any) => {
    // console.log('user in login container enrolled staff before dispatch fn:::', id);

    dispatch(EnrolledStaffInformation({ userId: id, districtId:adminInfo.districtId }))
  }
  useEffect(() => {
    dispatch(checkForStaffInformation(
      {districtId:adminInfo.districtId}
    ))
    if (refreshStaff) setRefreshStaff(false)
  }, [dispatch, refreshStaff])

  useEffect(() => {
    if(addOrUpdateStaffDetails.status === "succeeded" && teacherDetails.isTeacherEmailExists === false && teacherDetails.isTeacherEmployeeIdExists === false) {
      dispatch(checkForStaffInformation(
        {districtId:adminInfo.districtId}
      ))
      if (refreshStaff) setRefreshStaff(false)
      setShow(false)
      setEditStaff("")
      if (staffList.staffList.statusCode === 200) {
        setRefreshStaff(true)
      }
      staffModal.current.resetState()
      showToast({message: addOrUpdateStaffDetails.message})
      dispatch(resetTeacherDetails())
      dispatch(resetAddOrUpdateStaffDetails())
    }
  }, [addOrUpdateStaffDetails])

  /* added by premkumar
   */
  const setEditStaffPassword = (staffInfo: any) => {
    setShowPasswordModal(true)
    setEditStaff(staffInfo)
  }

  /* added by premkumar
   */
  const handlePasswordModalClose = () => {
    setShowPasswordModal(false)
    setEditStaff("")
  }

  /* added by premkumar
   */
  const handlePasswordUpdate = (e: any, staffData: any) => {
    e.preventDefault()
    const teacher = {
      staff: {
        _id: staffData._id,
        email: staffData.email,
        password: staffData.password,
      },
    }
    dispatch(updateStaffPassword({ teacher }))
  }


  useEffect(() => {
    if(updateStaffPasswordDetails.status === 'succeeded') {
      setShowPasswordModal(false)
      setEditStaff("")
      if (staffList.staffList.statusCode === 200) {
        setRefreshStaff(true)
      }
      staffPasswordModal.current.resetState()
      dispatch(resetUpdateStaffPasswordDetails())
    }
  }, [updateStaffPasswordDetails])

  useEffect(() => {
    dispatch(changeActivePage("staff"))
  },[])

  return (
    <>
      {console.log("b4 rendering tehedit staff contains.", editStaff)}
      <AddNewStaffModal
        modalState={show}
        handleClose={handleClose}
        handleUpdate={handleUpdate}
        editStaff={editStaff}
        ref={staffModal}
      />
      <ChangePasswordModal
        modalState={showPasswordModal}
        handleClose={handlePasswordModalClose}
        handleUpdate={handlePasswordUpdate}
        userInfo={editStaff}
        teacher
        ref={staffPasswordModal}
      />
      <AdminLayout showButton='true' handler={addJobRole} name='Add New Staff'>
        <div className='dashfirstrowheight'>
          {staffList.status === "loading" ? (
            // <ProcessingImage isLoading={true} />
            <div>
            <Loading />
          </div>
          ) : (
            <StaffTable
              staffList={staffList}
              enrolledStaff={enrolledStaff}
              staffSisName={setStaffSisName}
              setShow={setShow}
              setEditStaff={setEditStaff}
              enrollList={enrollList}
              setEditStaffPassword={setEditStaffPassword}
              handler={addJobRole}
            />
          )}
        </div>
      </AdminLayout>
    </>
  )
}

export default StaffRosterContainer
