import {useEffect,useState} from 'react';
import { Box, Button, Card } from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";
import { integratedFlowChanges } from '../../redux/features/integration/integration.slice';
import { useDispatch } from 'react-redux';

const selectedBox = {backgroundColor: 'rgba(49, 102, 186, 1)', color: 'white'}

export default function LoginOptions(props: any) {
const {onOneRosterNavigate} = props
  const dispatch = useDispatch()
  const [selectedValue, setSelectedValue] = useState('userid');
useEffect(()=>{
  dispatch(integratedFlowChanges({'type':"userId"}))
},[])

  const handleChange = (event: any) => {
    dispatch(integratedFlowChanges({'type':event.target.value}))
    setSelectedValue(event.target.value);
  };

  return (
    <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '520px', width: '1044px', borderRadius: '20px' }}>
      <Box style={{ margin: '30px', paddingBottom: '50px' }} onClick={() => onOneRosterNavigate('select-courses')}>
        <img src={backArrow} height="40px" id="backButtonToSelectCourses"/>
      </Box>
      <h1 style={{ color: '#1e1e1e', fontWeight: 500, marginBottom: '7vh', textAlign: 'center', fontSize: '22px' }}>How to do you want the users to Login?</h1>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Card variant="outlined" sx={{ width: 250, padding: '13px', textAlign: 'center', fontWeight: 500 }}>
          <input type="radio" value="userId" name="login-options" defaultChecked onChange={handleChange} id="userIdAndPasswordRadioButton"/> User ID & Password
        </Card>
      </Box>
      <p style={{ textAlign: 'center', fontSize: 'bolder', margin: '20px 0px',fontWeight: 500,color:"black" }}>or</p>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Card variant="outlined" sx={{ width: 250, padding: '13px', textAlign: 'center', fontWeight: 500 }}>
          <input type="radio" value="sso" name="login-options" onChange={handleChange} id="singleSignOnRadioButton"/> Single Sign on (SSO)
        </Card>
      </Box>
      <div style={{ textAlign: 'center', marginTop: '5%' }}>
        <Button onClick={() => onOneRosterNavigate(selectedValue)} style={{ padding: '8px 110px', textTransform: 'none' }} variant="contained" id="nextButtonForOneRoasterFromUsersToLoginPage">Next</Button>
      </div>
    </Box>
  );
}
