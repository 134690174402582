import auth from './AuthService';
import { BaseService } from './base.service';

export class IntegrationService extends BaseService {

    public async integrationFetch(userId: string) {
        const url = `/async-svc/integration-console/integrations/${userId}`
        return await this.getData(url);
    }
    public async postIntegrationData(data: any) {
        
        const url = "/async-svc/integration"
        return await this.postData(url, data);
    }
    public async updateIntegrationData(data: any) {
        
        const url = "/async-svc/integrationStatusUpdate"
        return await this.postData(url, data)
    }
    
    public async integrationTableFetch(data: any) {
        const districtInfo = auth.getAdminInfo()
        const districtId  = districtInfo.users.districtId
        const url = `/async-svc/integrationStatusList/${districtId}`
        return await this.getData(url);
    }
    
    public async deleteIntegrationData(data: any) {
        
        const url = "/async-svc/integrationStatusDelete"
        return await this.updateInfo(url, data)
    }
    
    public async generateSystemUsernames(data: any) {
        const url = '/async-svc/generate/usernames'
        return await this.postData(url, data)
    }
    
    public async getOneRosterOrganisations(data: any){
        const url = '/async-svc/orgs'
        return await this.postData(url, data)
    }
    
    public async getOneRosterCourses(data: any){
        const url = '/async-svc/courses'
        return await this.postData(url, data)
    }
    
    public async getOneRosterData(data: any){
        const url = '/async-svc/oneroster/data'
        return await this.postData(url, data)
    }
    public async syncOneRosterData(userId:any){
        const url = `/async-svc/integration-console/integrations/${userId}/sync`
        return await this.updateInfo(url,{})
    }
}

