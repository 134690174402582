import { NEWS_API_KEY } from "../config/config"
import { BaseService } from "./base.service"

export class StaffService extends BaseService {
  public async fetchStaffList(data: any) {
    const {districtId} = data
    const url = `/async-svc/teachers/${districtId}`
    return await this.getData(url)
  }
  
  public async postStaffData(data: any) {
    const url = "/async-svc/staffs"
    return await this.postData(url, data)
  }
  
  public async enrolledStaffFetch(data: any) {
    const userId = data.userId
    const districtId = data.districtId
    const url = `/async-svc/students/enrolledStudents/${districtId}/${userId}`
    return await this.getData(url)
  }
  
  public async postUpdatedStaffPassword(data: any) {
    const url = "/async-svc/staff/change-password"
    return await this.postData(url, data)
  }
  
  public async checkTeacherExists(data: any) {
    const url = "/async-svc/user/details"
    return await this.postData(url, data)
  }
  
  public async getTeacherDetails() {
    const url = "/async-svc/teacherids"
    return await this.getData(url)
  }

  public async getEmailsList() {
    const url = `/async-svc/event-signup/EmailsList`
    return await this.getData(url)
  }

public async approveAndSendEmailForEventSignup(data: any){
    const url = `/async-svc/event-signup/approveAndSendEmail`
    return await this.postData(url, data)
  }

  public async rejectEmailForEventSignup(data: any){
    const url = `/async-svc/event-signup/rejectEmail`
    return await this.postData(url, data)
  }

  public async getReports(type: string) {
    const url = `/async-svc/teacher-signup/reports/${type}`
    return await this.getData(url)
  }

  public async getReportIssues() {
    const url = `/async-svc/teachers/reported-content`
    return await this.getData(url)
  }

  public async createAccount(email: string) {
    const url = `/async-svc/teacher-signup/createAccount`
    return await this.postData(url, {email})
  }

  public async getArticlesData(data: any) {
    const url = `/content-svc/content/getNewsArticlesDetails/${data?.month}/${data?.year}`
    return await this.getData(url)
  }

  public async saveArticlesData(data: any) {
    const url = '/content-svc/content/saveNewsArticlesDetails'
    return await this.postData(url, data)
  }

  public async apiFetchArticlesData(keyWord: string) {
    const API_KEY = NEWS_API_KEY
    
    const response = await fetch(`https://newsapi.org/v2/everything?language=en&apiKey=${API_KEY}&q=${keyWord}`, 
    {
        method: 'GET'
    })
    return await response.json();
}
}