import React, { useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


const Enrollment = styled(Paper)(({ theme }) => ({
    width: 150,
    height: 50,
    // padding:20,
    padding: theme.spacing(2),
    ...theme.typography.body2,

    textAlign: 'center',
    justifyContent: 'center',
    marginRight: '3%',
    alignContent: "center",
    cursor: 'pointer',
}));
const selectedBox = { backgroundColor: 'rgba(49, 102, 186, 1)', color: 'white' }

export const CreateIds = (props: any) => {

    const [selection, setSelection] = useState<any>({ rosterMethod: 'SIS ID' })
    const [a, setA] = useState<any>("")
    // console.log(a, "ASASASAS", selection.rosterMethod)
    // console.log(selection, '8888888888888888')
    // console.log("tableData={studentTableData}", props.tableData)
    const sisId = props.tableData.filter((each: any) => each.student_sis_id)?.[0]?.student_sis_id
    const stateId = props.tableData.filter((each: any) => each.student_school_id)?.[0]?.student_school_id
    const emailId = props.tableData.filter((each: any) => each.student_email)?.[0]?.student_email

    console.log("sisid", sisId, stateId, emailId)

    const values = (val: string) => {
        setSelection({ rosterMethod: val })
        if (val === "SIS ID") {
            setA(sisId)
        } else if (val === "State ID") {
            console.log(stateId, "123123")
            setA(stateId)
        } else if (val === "Email ID") {
            setA(emailId)
        }

        console.log(a, "1231234", val)
    }
    return (
        <>
            <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '600px', width: '1044px', borderRadius: '20px', marginLeft: "6%" }}>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <Box sx={{ marginLeft: 5 }}
                        onClick={() => props.onCSVNavigate('student')}
                    >
                        <img src={backArrow} height="40px" />
                    </Box>
                    <h1 style={{ color: '#1e1e1e', fontWeight: 500, textAlign: 'center', fontSize: '22px', marginLeft: 370, marginTop: "10%" }}>User ID</h1>

                </Box>
                <Stack style={{ marginLeft: '20%', marginTop: "5%" }} direction="row" spacing={4}>
                    <Enrollment
                        onClick={(e) => {
                            // setSelection({rosterMethod: 'SIS ID'})
                            values('SIS ID')
                        }}
                        style={selection.rosterMethod == 'SIS ID' ? selectedBox : {}} square={false}>SIS ID</Enrollment>
                    <Enrollment onClick={(e) => {
                        // setSelection({rosterMethod: 'State ID'})
                        values('State ID')
                    }}
                        style={selection.rosterMethod == 'State ID' ? selectedBox : {}} square={false}>State ID</Enrollment>
                    <Enrollment onClick={(e) => {
                        // setSelection({rosterMethod: 'Email ID'})
                        values('Email ID')
                    }}
                        style={selection.rosterMethod == 'Email ID' ? selectedBox : {}} square={false}>Email ID</Enrollment>
                </Stack>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: '20%', marginTop: 2 }}>
                    <form >

                        <Grid >
                            {/* <Item>API Endpoint</Item> */}
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                <label style={{ color: '#333333', fontSize: '12px', fontWeight: 500 }}>Sample {selection.rosterMethod === 'SIS ID' ? "SIS ID" : selection.rosterMethod === 'State ID' ? "State ID" : "Email ID"}</label>
                                <input className="MuiInput-input" id="filled-basic" value={a} readOnly={true} style={{ backgroundColor: 'white', border: 'solid 1px #e9e9e9', width: '210px', height: '35px' }} />

                            </div>
                        </Grid>
                    </form>
                </Box>

                <div style={{ textAlign: 'center', marginTop: '10vh' }}>

                    <Button
                        onClick={() => props.onCSVNavigate('userpassword')}
                        style={{ padding: '8px 80px' }} variant="contained">Next</Button>

                </div>
            </Box>

        </>
    )
}