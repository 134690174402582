import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  StaffService
} from "../../../services"

const initialState = {
  staffList: [],
  status: "idle",
  error: null,
  dataSyncedAt:"",
  updateStaffPasswordDetails: {
    message: "",
    status: "idle",
    error: null
  },
  teacherDetails: {
    status: "idle",
    isTeacherEmailExists: false,
    isTeacherEmployeeIdExists : false,
    error: null
  },
  addOrUpdateStaffDetails: {
    message: "",
    status: "idle",
    error: null
  },
  teacherIds: {
    status: "idle",
    teacherDetails: [],
    error: null
  },
  emailsListDetails: {
    status: "idle",
    data: [],
    error: null
  },
  approveAndSendEmailDetails: {
    status: "idle",
    data: [],
    error: null
  },
  rejectEmailForEventSignupDetails: {
    status: "idle",
    data: [],
    error: null
  },
  getReportsDetails: {
    status: "idle",
    data: [],
    error: null
  },
  getReportIssuesDetails: {
    status: "idle",
    data: [],
    error: null
  },
  createAccountDetails: {
    status: "idle",
    data: [],
    error: null
  },
  articlesData: {
    status: "idle",
    data: [],
    error: null
  },
  fetchArticlesData: {
    status: "idle",
    data: [],
    error: null
  },
  saveArticlesData: {
    status: "idle",
    data: [],
    error: null
  }
}

export const addOrUpdateStaff: any = createAsyncThunk(
  "staff/addOrUpdateStaffInfo",
  async (data) => {
    const staffService = new StaffService()
    const response = await staffService.postStaffData(data)
    return response
  }
)

export const checkForStaffInformation: any = createAsyncThunk(
  "staff/checkForStaffInformation",
  async (data) => {
    const staffService = new StaffService()
    const response = await staffService.fetchStaffList(data)
    console.log(response)
    return response
  }
)

export const updateStaffPassword: any = createAsyncThunk(
  "staff/updateStaffPassword",
  async (data) => {
    const staffService = new StaffService()
    const response = await staffService.postUpdatedStaffPassword(data)
    return response
  }
)

export const checkIfTeacherExists: any = createAsyncThunk(
  "staff/checkIfTeacherExists",
  async (data) => {
    const staffService = new StaffService()
    const response = await staffService.checkTeacherExists(data)
    return response
  }
)

export const getTeacherIds: any = createAsyncThunk(
  "staff/getTeacherIds",
  async () => {
    const staffService = new StaffService()
    const response = await staffService.getTeacherDetails()
    return response
  }
)

export const getEmailsList: any = createAsyncThunk('staff/EmailsList', async() => {
  const staffService = new StaffService()
  const response = await staffService.getEmailsList()
  return response
})

export const approveAndSendEmailForEventSignup: any = createAsyncThunk('staff/approveAndSendEmailForEventSignup', async(data) => {
  const staffService = new StaffService()
  const response = await staffService.approveAndSendEmailForEventSignup(data)
  return response
})

export const rejectEmailForEventSignup: any = createAsyncThunk('staff/rejectEmailForEventSignup', async(data) => {
  const staffService = new StaffService()
  const response = await staffService.rejectEmailForEventSignup(data)
  return response
})

export const getReports: any = createAsyncThunk('staff/getReports', async (type: string) => {
  const staffService = new StaffService()
  const response = await staffService.getReports(type)
  return response
})

// to get issues from DB which is submitted in teacher portal
export const getReportIssues: any = createAsyncThunk('staff/getReportIssues', async () => {
  const staffService = new StaffService()
  const response = await staffService.getReportIssues()
  return response
})

// To Create Account Manually for teacher
export const createAccount: any = createAsyncThunk('staff/createAccount', async (email: string) => {
  const staffService = new StaffService()
  const response = await staffService.createAccount(email)
  return response
})

// To get news articles data
export const getArticlesData: any = createAsyncThunk('staff/getArticlesData', async (data) => {
  const staffService = new StaffService()
  const response = await staffService.getArticlesData(data)
  return response
})

export const saveArticlesData: any = createAsyncThunk('staff/saveArticlesData', async (data) => {
  const staffService = new StaffService()
  const response = await staffService.saveArticlesData(data)
  return response
})

export const apiFetchArticles: any = createAsyncThunk('staff/articles', async (data: string) => {
  const staffService = new StaffService()
  const response = await staffService.apiFetchArticlesData(data);
  return response
})

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    resetUpdateStaffPasswordDetails: (state) => {
      state.updateStaffPasswordDetails = initialState.updateStaffPasswordDetails
    },
    resetTeacherDetails: (state) => {
      state.teacherDetails = initialState.teacherDetails
    },
    resetAddOrUpdateStaffDetails: (state) => {
      state.addOrUpdateStaffDetails = initialState.addOrUpdateStaffDetails
    },
    clearDetailsForApproveAndReject: (state) => {
      state.approveAndSendEmailDetails = initialState.approveAndSendEmailDetails
      state.rejectEmailForEventSignupDetails = initialState.rejectEmailForEventSignupDetails
    },
    resetCreateAccountDetails: (state) => {
      state.createAccountDetails = initialState.createAccountDetails
    },
    resetSaveArticlesData: (state) => {
      state.saveArticlesData = initialState.saveArticlesData
    }
  },
  extraReducers: {
    [checkForStaffInformation.pending]: (state, action) => {
      console.log("action payload in extraReducers!!!", action.payload)
      state.status = "loading"
    },
    [checkForStaffInformation.fulfilled]: (state, action) => {
      state.status = "succeeded"
      // Add any fetched posts to the array
      console.log(
        "staffslice-action payload in extraReducers!!!",
        action.payload
      )
      state.staffList = action.payload
      state.dataSyncedAt = action.payload.result?.dataSyncedAt
    },
    [checkForStaffInformation.rejected]: (state, action) => {
      console.log("action payload in extraReducers!!!", action.payload)
      state.status = "failed"
      state.error = action.error.message
    },
    [addOrUpdateStaff.pending]: (state) => {
      state.addOrUpdateStaffDetails.status = "loading"
    },
    [addOrUpdateStaff.fulfilled]: (state, action) => {
      if(!action.payload?.error?.message) {
        state.addOrUpdateStaffDetails.status = 'succeeded'
        state.addOrUpdateStaffDetails.message = action.payload.result.message
      } else {
        state.addOrUpdateStaffDetails.status = 'failed'
        state.addOrUpdateStaffDetails.error = action.payload.error.message
      }
    },
    [addOrUpdateStaff.rejected]: (state, action) => {
      state.addOrUpdateStaffDetails.status = "failed"
      state.addOrUpdateStaffDetails.error = action.error.message
    },
    [updateStaffPassword.fulfilled]: (state, action) => {
      if(action?.payload?.statusCode === 200) {
        state.updateStaffPasswordDetails.status = "succeeded"
        state.updateStaffPasswordDetails.message = action.payload.result
      } else {
        state.updateStaffPasswordDetails.status = "failed"
        state.updateStaffPasswordDetails.error = action.payload.error.message
      }
    },
    [updateStaffPassword.rejected]: (state, action) => {
      state.updateStaffPasswordDetails.status = "failed"
      state.updateStaffPasswordDetails.error = action.error.message
    },
    [checkIfTeacherExists.fulfilled]: (state, action) => {
      // if(action.payload.result === null) {
      //   state.teacherDetails.status = 'succeeded'
      //   state.teacherDetails.isTeacherExists =  false
      // } else {
      //   state.teacherDetails.status = 'failed'
      //   state.teacherDetails.isTeacherExists =  true
      // }
      state.teacherDetails.status = 'succeeded'
      state.teacherDetails.isTeacherEmailExists = action.payload.result.isEmailExists
      state.teacherDetails.isTeacherEmployeeIdExists = action.payload.result.isEmployeeIdExists
    },
    [checkIfTeacherExists.rejected]: (state, action) => {
      state.teacherDetails.error = action.error.message
    },
    [getTeacherIds.pending]: (state) => {
      state.teacherIds.status = "loading"
    },
    [getTeacherIds.fulfilled]: (state,action) => {
      state.teacherIds.status = "succeeded"
      state.teacherIds.teacherDetails = action.payload.result.teachersDetails
      // console.log(action.payload,"<<<<<<")
    },
    [getTeacherIds.rejected]: (state,action) => {
      state.teacherIds.status = "failed"
      state.teacherDetails.error = action.error.message
    },
    
    [getEmailsList.pending]: (state) => {
      state.emailsListDetails.status = "loading"
    },
    [getEmailsList.fulfilled]: (state,action) => {
      state.emailsListDetails.status = "succeeded"
      state.emailsListDetails.data = action.payload.result
    },
    [getEmailsList.rejected]: (state,action) => {
      state.emailsListDetails.status = "failed"
      state.emailsListDetails.error = action.error.message
    },

    [approveAndSendEmailForEventSignup.pending]: (state) => {
      state.approveAndSendEmailDetails.status = "loading"
    },
    [approveAndSendEmailForEventSignup.fulfilled]: (state,action) => {
      state.approveAndSendEmailDetails.status = "succeeded"
      state.approveAndSendEmailDetails.data = action.payload.result
    },
    [approveAndSendEmailForEventSignup.rejected]: (state,action) => {
      state.approveAndSendEmailDetails.status = "failed"
      state.approveAndSendEmailDetails.error = action.error.message
    },

    [rejectEmailForEventSignup.pending]: (state) => {
      state.rejectEmailForEventSignupDetails.status = "loading"
    },
    [rejectEmailForEventSignup.fulfilled]: (state,action) => {
      state.rejectEmailForEventSignupDetails.status = "succeeded"
      state.rejectEmailForEventSignupDetails.data = action.payload.result
    },
    [rejectEmailForEventSignup.rejected]: (state,action) => {
      state.rejectEmailForEventSignupDetails.status = "failed"
      state.rejectEmailForEventSignupDetails.error = action.error.message
    },

    [getReports.pending]: (state) => {
      state.getReportsDetails.status = "loading"
    },
    [getReports.fulfilled]: (state,action) => {
      state.getReportsDetails.status = "succeeded"
      state.getReportsDetails.data = action.payload.result
    },
    [getReports.rejected]: (state,action) => {
      state.getReportsDetails.status = "failed"
      state.getReportsDetails.error = action.error.message
    },

    [getReportIssues.pending]: (state) => {
      state.getReportIssuesDetails.status = "loading"
    },
    [getReportIssues.fulfilled]: (state,action) => {
      state.getReportIssuesDetails.status = "succeeded"
      console.log(action.payload.result,"LOOOOOOOOOOOOOOOOOOOOOOOOOooo")
      state.getReportIssuesDetails.data = action.payload.result
    },
    [getReportIssues.rejected]: (state,action) => {
      state.getReportIssuesDetails.status = "failed"
      state.getReportIssuesDetails.error = action.error.message
    },

    [createAccount.pending]: (state) => {
      state.createAccountDetails.status = "loading"
    },
    [createAccount.fulfilled]: (state,action) => {
      if(action.payload.result){
        state.createAccountDetails.status = "succeeded"
        state.createAccountDetails.data = action.payload.result
      }else{
        state.createAccountDetails.status = "failed"
      }
    },
    [createAccount.rejected]: (state,action) => {
      state.createAccountDetails.status = "failed"
      state.createAccountDetails.error = action.error.message
    },

    [getArticlesData.pending]: (state) => {
      state.articlesData.status = "loading"
    },
    [getArticlesData.fulfilled]: (state,action) => {
      state.articlesData.status = "succeeded"
      state.articlesData.data = action.payload.result
    },
    [getArticlesData.rejected]: (state,action) => {
      state.articlesData.status = "failed"
      state.articlesData.error = action.error.message
    },
    
    [apiFetchArticles.pending]: (state, action) => {
      state.fetchArticlesData.status = 'loading'
    },
    [apiFetchArticles.fulfilled]: (state, action) => {
      state.fetchArticlesData.status = 'succeeded'
      if (action.payload.articles) {
          state.fetchArticlesData.data = action?.payload?.articles?.map((eachArticle:any, index: number) => {
              return {...eachArticle, id : (index + 1).toString()}
          })
      }
    },
    [apiFetchArticles.rejected]: (state, action) => {
      state.fetchArticlesData.status = 'failed'
      state.fetchArticlesData.error = action.error.message
    },

    [saveArticlesData.pending]: (state, action) => {
      state.saveArticlesData.status = 'loading'
    },
    [saveArticlesData.fulfilled]: (state,action) => {
      state.saveArticlesData.status = "succeeded"
      state.saveArticlesData.data = action.payload.result
    },
    [saveArticlesData.rejected]: (state,action) => {
      state.saveArticlesData.status = "failed"
      state.saveArticlesData.error = action.error.message
    },

  },
})

export const { resetSaveArticlesData, resetUpdateStaffPasswordDetails, resetTeacherDetails, resetAddOrUpdateStaffDetails, clearDetailsForApproveAndReject, resetCreateAccountDetails } = staffSlice.actions

export default staffSlice.reducer
