import React from 'react';
import Spinner from 'react-bootstrap/Spinner'

export const ProcessingImage = ({isLoading}) => {

    return (
        <> 
            {console.log("from the processing image page")}
            <div style={{marginLeft: '40%'}} >
                {isLoading ? <Spinner
                    as="span"
                    animation="border"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    variant="success"
                    /> : '' }
            </div>
        </>
    )
}