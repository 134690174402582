/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Backdrop,
  Fade,
  Box,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import { getReportIssues } from "../../redux/features/staff/staff.slice";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function ReportedContent() {
  const [enlargedImg, setEnlargedImg] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); // State to keep track of currently selected image index
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportIssues());
  }, []);

  const getReportIssuesDetails = useSelector(
    (state: any) => state.staff.getReportIssuesDetails.data
  );

  // console.log(getReportIssuesDetails,"LLLLLLLLLLLLLLLLLl")

  

  let reported_Arr = [
    {
      subject: "My Aauti:Work to be Graded,Class Schedule",
      content: "hhh",
      firstName: "Nambi",
      lastName: "rao",
      email: "nambirao@gmail.com",
      schoolId: "DFLTXPIJMBRv7",
      imageDataURL: [
        "https://aautiaiimages.blob.core.windows.net/aautischool/lionssb64ad48c-ad10-49d6-ae4e-57fc4e03d987.png",
        "https://aautiaiimages.blob.core.windows.net/aautischool/lionssb64ad48c-ad10-49d6-ae4e-57fc4e03d987.png",
        "https://aautiaiimages.blob.core.windows.net/aautischool/lionsss9f358eff-8da0-4e4a-9ed7-ce32d8791a2a.png",
      ],
    },
    {
      subject: "My Aauti:Work to be Graded,Class Schedule",
      content: "hhh",
      firstName: "Nambi",
      lastName: "rao",
      email: "nambirao@gmail.com",
      schoolId: "DFLTXPIJMBRv7",
      imageDataURL: [
        "https://aautiaiimages.blob.core.windows.net/aautischool/lionssb64ad48c-ad10-49d6-ae4e-57fc4e03d987.png",
        "https://aautiaiimages.blob.core.windows.net/aautischool/lionssb64ad48c-ad10-49d6-ae4e-57fc4e03d987.png",
      ],
    },
  ];

  const openModal = (imgUrl: string) => {
    setEnlargedImg(imgUrl);
  };

  const closeModal = () => {
    setEnlargedImg("");
    setSelectedImage("");
  };

  // const handleImageClick = (imgUrl: string,idx:any) => {
  //   openModal(imgUrl);
  //   setSelectedImage(imgUrl);
  //   setSelectedImageIndex(idx);
  // };

  const handleImageClick = (imgUrl: string) => {
    openModal(imgUrl);
    setSelectedImage(imgUrl);
  };

  const handlePrevImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1); // Move to the previous image
      setSelectedImage(
        reported_Arr[selectedImageIndex - 1].imageDataURL[
          selectedImageIndex - 1
        ]
      ); // Set the selected image to the previous one
    }
  };

  const handleNextImage = () => {
    if (
      selectedImageIndex <
      reported_Arr[selectedImageIndex].imageDataURL.length - 1
    ) {
      setSelectedImageIndex(selectedImageIndex + 1); // Move to the next image
      setSelectedImage(
        reported_Arr[selectedImageIndex + 1].imageDataURL[
          selectedImageIndex + 1
        ]
      ); // Set the selected image to the next one
    }
  };

  // This file is to view report issues from teacher_portal
  return (
    <TableContainer sx={{ border: "1px solid #e6e6e6", marginTop: "10px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 200 }}>Subject</TableCell>
            <TableCell style={{ minWidth: 150 }}>Content</TableCell>
            <TableCell style={{ minWidth: 100 }}>First Name</TableCell>
            <TableCell style={{ minWidth: 100 }}>Last Name</TableCell>
            <TableCell style={{ minWidth: 100 }}>Email</TableCell>
            <TableCell style={{ minWidth: 100 }}>School Id</TableCell>
            <TableCell style={{ minWidth: 100 }}>Attachments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reported_Arr.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7}>
                <h1
                  style={{
                    color: "#000000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  No Records Found
                </h1>
              </TableCell>
            </TableRow>
          ) : (
            getReportIssuesDetails.map((each: any) => (
              <TableRow
                key={each._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ minWidth: 200 }}>{each?.subject}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>{each?.content}</TableCell>
                <TableCell sx={{ minWidth: 100 }}>{each?.firstName}</TableCell>
                <TableCell sx={{ minWidth: 100 }}>{each?.lastName}</TableCell>
                <TableCell sx={{ minWidth: 100 }}>{each?.email}</TableCell>
                <TableCell sx={{ minWidth: 100 }}>{each?.schoolId}</TableCell>
                {/* <TableCell style={{ minWidth: 100 }}>
                  {each.imageDataURL?.map((image: any, idx: any) => (
                    <img
                      key={idx}
                      src={image}
                      alt="no-image"
                      height="40px"
                      width="40px"
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      onClick={() => handleImageClick(image,idx)}
                    />
                  ))}
                </TableCell> */}
                <TableCell style={{ minWidth: 100 }}>
                  {each.imageDataURL
                    ?.slice(0, 2)
                    .map((image: any, idx: any) => (
                      <img
                        key={idx}
                        src={image}
                        alt="no-image"
                        height="40px"
                        width="40px"
                        style={{ cursor: "pointer", marginRight: "5px" }}
                        onClick={() => handleImageClick(image)}
                      />
                    ))}
                  {each.imageDataURL?.length > 2 && (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => openModal(each.imageDataURL[2])}
                    >
                      View More
                    </span>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      {/* <Modal
        open={!!enlargedImg}
        onClose={closeModal}
        aria-labelledby="enlarged-image-modal"
        aria-describedby="enlarged-image"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!!enlargedImg}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              boxShadow: 24,
              padding: 3,
              borderRadius: 4,
            }}
          >
            <img
              src={enlargedImg}
              alt="enlarged"
              style={{ width: "auto", maxHeight: "80vh" }}
            />
          </Box>
        </Fade>
      </Modal> */}
      {enlargedImg && (
        <Modal
          open={!!enlargedImg}
          onClose={closeModal}
          aria-labelledby="enlarged-image-modal"
          aria-describedby="enlarged-image"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={!!enlargedImg}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                boxShadow: 24,
                padding: 3,
                borderRadius: 4,
              }}
            >
              <img
                src={enlargedImg}
                alt="enlarged"
                style={{
                  width: "auto",
                  maxHeight: "80vh",
                  position: "relative",
                }}
              />
              <IconButton
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color:"#00669c"
                }}
                onClick={handlePrevImage}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color:"#00669c",
                  fontSize:"32px"
                }}
                onClick={handleNextImage}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          </Fade>
        </Modal>
      )}
    </TableContainer>
  );
}
