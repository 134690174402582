import AdminLayout from '../layouts/AdminLayout';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeActivePage } from '../redux/features/user/user.slice';
import AppContext from '../store/AppContext';
import Articles from '../components/articles/Articles';

function ArticlesContainer() {
  const dispatch = useDispatch()
  const appContext = useContext(AppContext);
  const adminInfo= appContext?.userDetails;

  useEffect(() => {
    dispatch(changeActivePage("articles"))
  },[])


  return (
      <AdminLayout>
        <Articles />
      </AdminLayout>
    );
}

export default ArticlesContainer;