import * as React from 'react';
import { Box, Typography } from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";


interface Isupport{
  onOneRosterNavigate?:any;
  a?:any;
  onCSVNavigate?:any;
  toEndUp?:any;
}
export default function Support({ onOneRosterNavigate,a,onCSVNavigate, toEndUp }: Isupport) {

  return (
    <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '70vh', width: '1044px', borderRadius: '20px'}}>
      <Box style={{ margin: '30px', paddingBottom: '10px' }} 
      onClick={toEndUp}>
      <img src={backArrow} height="40px" />
    </Box>
    <Box sx={{ display:'flex',flexDirection:'column',justifyContent:'center', textAlign:'center', marginTop:'150px' }}>
      <Typography variant="body2" sx={{ fontWeight: 500, fontSize: '20px', color: '#1e1e1e' }}>
      Reach out to customer care support and we will help you out
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 500, fontSize: '25px', color: ' #3166ba' }}>
        support@aautischool.com
      </Typography>
      </Box>
    </Box>
  );
}
