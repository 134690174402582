
import React, { useState, useEffect } from 'react';
import { saveItem, getItem } from './storage';

interface IAppContext {
    userDetails: any,
    setUserDetails: Function
}

const AppContext = React.createContext<IAppContext | null>(null);

export const AppConsumer = AppContext.Consumer;

export const AppProvider = ({ children }: any): any => {
    
    const [userDetails, setUserDetails] = useState(getItem("userDetails"));

    useEffect(() => {
        saveItem("userDetails", userDetails);
    }, [userDetails])

    return <AppContext.Provider
                value={{
                    userDetails,
                    setUserDetails
                }}>
                {children}
            </AppContext.Provider>
}

export default AppContext;