export const environment = () => {
    
    const hostname = window && window.location && window.location.hostname;
    if(hostname.includes('admin-dev') || hostname.includes("devadaminaauti")) {
        return "dev"
    } else if(hostname.includes('admin-qa') || hostname.includes("qaadaminaauti")) {
        return "qa"
    }else if(hostname.includes('admin') || hostname.includes("adaminaauti") ){
        return "prod"
    } else {
       return "prod";
    }
};


export const addVersionToUrl = (url:any) => {
    const keywords = ["core-svc", "auto-gen","async-svc", "gen-svc","content-svc"];
    const urlParts = url.split('/');
  
    
    for (const keyword of keywords) {
      if (url.includes(keyword)) {
        const index = urlParts.indexOf(keyword);
        if (index !== -1 && urlParts[index + 1] !== 'v1') {
          urlParts.splice(index + 1, 0, 'v1');
          return urlParts.join('/');
        }
      }
    }
    return url; 
  };

export const getEnvironmentUrl = (url?: string) => {

    // if(url && url.includes('async-svc')){
    //     return "http://localhost:80"
    // }
    
    const env = environment();
    if (url && url.includes("content-svc")) {
        return `https://aauti-content-services-${env}.aauti-backend.com`;
    } else if (url && url.includes("gen-svc")) {
        return `https://aauti-gen-services-${env}.aauti-backend.com`;
    } else if (url && url.includes("async-svc")) {
        return `https://aauti-admin-services-${env}.aauti-backend.com`;
    } else if (url && url.includes("core-svc")) {
        return `https://aauti-core-services-${env}.aauti-backend.com`;
    } else if (url && url.includes("auto-gen") && !url.includes("content-svc")) {
        return `https://aauti-content-services-${env}.aauti-backend.com`;
    } else {
        return `https://${env}.aauti-backend.com`;
    }
}

export const NEWS_API_KEY = "b6504e0bc52d40c9b857e20a3c119b00"