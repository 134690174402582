import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

export const JobRoleMapping = (props: any) => {
     const JobList = props.JobRoleList.JobRoleList.result
     const editJobRole = (e: any, JobRoleEdit: any) => {
        props.setEditJobRole(JobRoleEdit);
        props.setShow(true); 
    }
    const deleteDetail = (filedetail: any) => {
        props.deleteDetail(filedetail);
      }     

    return(
        <>
            <div className="mrgnbtm row">
                <div className="col-md-2">
                    <Link to={''}>Basic</Link>
                </div>
                <div className="col-md-2">
                    <Link to={''}>Advanced</Link>
                </div>
            </div>
            <div className='horizontalScrollBar'>
                <table className="ng-table sort-enabled upload-data-table" >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>BLOOM JOB ROLE</th>
                            <th>DISTRICT JOB ROLE DESCRIPTION</th>
                            <th>DISTRICT JOB ROLE</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    JobList && JobList.map((jobItem: any, i: number)=>{
                        return(
                        <tr>
                            <td>{i=i+1}</td>
                            <td>{jobItem.bloomJobRole}</td>
                            <td></td>
                            <td>{jobItem.districtJobRole}</td>
                            <td>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Button className="generic-btn" variant="warning" onClick={e=> editJobRole(e,jobItem)}>Edit</Button>
                                    </div>
                                    <div className="col-md-6">
                                        <Button className="generic-btn" variant="danger" onClick={e=> deleteDetail(jobItem)}>Delete</Button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        )
                    })
                } 
                    </tbody>
                </table>
            </div>
        </>
    )
}