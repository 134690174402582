import { useEffect, useState, useRef } from 'react';
import ProcessingButton from '../../shared/components/ProcessingButton';
import { Form} from 'react-bootstrap'
import useToast from '../../shared/components/Toast'
import { changeSignupStatus, sendChangePasswordEmailLink, userSignUpProfile } from '../../redux/features/user/user.slice'
import { useSelector, useDispatch } from 'react-redux'
import { environment } from "../../config/config";
import Logo from '../../assets/images/auti_School.svg'
import { Button } from '@mui/material';
import { toast} from 'react-toastify';
import { v4 as uuidv4 } from "uuid";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const validEmailMsg = "Enter Valid Email"


function Login({onLogin, loginStatus}: any) {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [districtId, setDistrictId] = useState('')
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [inputFieldsStatus,setInputFieldsStatus] = useState(false)
  const [sendEmailStatus, setSendEmailStatus] = useState(true)
  const [showSignup, setShowSignup] = useState(false)

  const [firstName,setFirstName]=useState('')
  const [lastName,setLastname]=useState('')
  const [signupEmail,setSignupEmail]=useState('')
  const [signPassword,setSignPassword]=useState('')
  const [type,setType]=useState('')
  const [SignupDistrictId,setSignupDistrictId]=useState('')
  const [districtName,setDistrictName]=useState('')
  const [districtSchools,setDistrictSchools]=useState('')
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch()
  const showToast = useToast();

  const passwordInputFieldRef: any = useRef("passwordInputFieldRef")
  const districtIdInputFieldRef: any = useRef("districtIdInputFieldRef")
  const loginButtonRef: any = useRef("loginButtonRef")



  const onUserChanged = (e: any) => setEmail(e.target.value)
  const onPasswordChanged = (e: any) => setPassword(e.target.value)
  const onDistrictIdChanged = (e: any) => setDistrictId(e.target.value)
  const onFirstName = (e: any) => setFirstName(e.target.value)
  const onSignPassword = (e: any) => setSignPassword(e.target.value)
  const onSignupDistrictId = (e: any) => setSignupDistrictId(e.target.value)
  const onLastname = (e: any) => setLastname(e.target.value)
  const onType = (e: any) => setType(e.target.value)
  const onSignupEmail = (e: any) => setSignupEmail(e.target.value)
  const onDistrictName = (e: any) => setDistrictName(e.target.value)
  const onDistrictSchools = (e: any) => setDistrictSchools(e.target.value)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(()=>{
    setSignupDistrictId(uuidv4())
  },[])

  const onLoginFunc: any = () => {
    if(!inputFieldsStatus) { 
      if(!email || !password || !districtId) {
        setInputFieldsStatus(true)
      } else {
        onLogin(email.trim(), password, districtId.trim());
      }
    }
  }
 
  const signupSatus = useSelector((state: any) => state?.user?.signupStatus)

  const onClickShowForgotPassword = () => {
    setEmail('')
    setShowForgotPassword(true)
  }

  const onClickSignup = () => {
    setShowSignup(true)
  }

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const onClickLogin = () => {
    setShowForgotPassword(false)
    setShowSignup(false)
    setShowSuccessMessage(false)
    setEmail('')
  }

  const changePasswordSendEmailDetails = useSelector((state: any) => state.user.changePasswordSendEmailDetails)

  useEffect(() => {
    if(changePasswordSendEmailDetails.status === "succeeded") {
      setShowSuccessMessage(true)
      setSendEmailStatus(true)
    } else if (changePasswordSendEmailDetails.status === "failed") {
      showToast({message: changePasswordSendEmailDetails.error, type : "error"})
      const intervalId = setInterval(() => {
        setSendEmailStatus(true)
        clearInterval(intervalId)
      }, 3200)
    }
  }, [changePasswordSendEmailDetails])

  useEffect(() => {
    const message = !showForgotPassword ? validEmailMsg  : "Please enter all fields"
    if(inputFieldsStatus) {
      showToast({message, type : "error" })
      const loginIntervalId = setInterval(() => {
        setInputFieldsStatus(false)
        clearInterval(loginIntervalId)
      }, 3200) 
    }
  }, [inputFieldsStatus])

  const onClickSendEmail = () => {
    const env = environment()
    if(!inputFieldsStatus) {
      if(!re.test(email)) {
        setInputFieldsStatus(true)
      } else if(sendEmailStatus) {
        setSendEmailStatus(false)
        dispatch(sendChangePasswordEmailLink({email,environment:env}))
      }
    }
  }

  const onSubmitForm = (event: any) => {
    event.preventDefault()
    onClickSendEmail()
  }

  const focusRefOnEnter = (event: any, ref: any) => {
    if(event.key === "Enter" && ref){
      ref.current.focus()
    }
  }
  const buttonSignUp= () =>{
    if(firstName!==""&& lastName!=="" && signupEmail!==""&&signPassword!==""&&type!==""&& SignupDistrictId!=="" && districtName!==""){
      dispatch(userSignUpProfile(
        {
          "firstName": firstName,
          "lastName": lastName,
          "email": signupEmail,
          "password": signPassword,
          "type": type,
          "districtId": SignupDistrictId,
          "districtName": districtName,
          "districtSchools": []

        }

        ),
        
        )
        // setShowSignup(false)
    }else{
      toast("Fill all fields",{type: toast.TYPE.ERROR})
    }
      }

      useEffect(()=>{
        if(signupSatus.status==="succeeded"){
          toast("SignUp successfully",{type: toast.TYPE.SUCCESS})
          onLogin(signupEmail.trim(), signPassword, SignupDistrictId.trim());
        dispatch(changeSignupStatus())

      }

      },[signupSatus])
      
      const renderSignSection =()=>(
        <Form id="eventSignupConatiner">
           <div style={{padding:5}}>
    
                <div className="form-group">
                    <label className="control-label" htmlFor="name" id="adminSignUpfirstNameLabel">FirstName</label>
                    <input autoComplete='off' type="text" className="form-control"   id="schoolId" name="adminSignUpFirstNameInput"  required onChange={onFirstName} />
                  
                </div>
                <div className="form-group">
                    <label className="control-label" htmlFor="service" id="adminSignUplastNameLabel">LastName</label>
                    <input autoComplete='off' type="text" className="form-control" id="lmsUserId"   name="adminSignUplastNameInput" required onChange={onLastname}/>
                  
                </div>
                <div className="form-group">
                    <label className="control-label" htmlFor="endpoint" id="adminSignUpemailLabel">Email</label>
                    <input autoComplete='off' type="text" className="form-control" id="adminSignUpEmailInput"   name="Email" required onChange={onSignupEmail}/>
                    
                </div>
                    <div className="form-group">
                        <label className="control-label" htmlFor="endpoint" id="adminSignUpPasswordLabel">Password</label>
                        <input autoComplete='new-password' type="password" className="form-control" id="adminSignUpPasswordInput"   name="Password" required onChange={onSignPassword}/>
                    
                    </div>
    
                <div className="form-group">
                    <label className="control-label" htmlFor="service" id="adminSignUptypeLabel">Type</label>
                    <input autoComplete='off' type="text" className="form-control" id="userId"  name="adminSignUpTypeInput" required onChange={onType}/>
                  
                </div>
    
    
                <div className="form-group">
                    <label className="control-label" htmlFor="key" id="adminSignUpDistrictIdLabel">DistrictId</label>
                    <input autoComplete='off' type="text" className="form-control" id="sisId"   name="adminSignUpDistrictIdInput" required value={SignupDistrictId} onChange={onSignupDistrictId}/>
    
                </div>
                <div className="form-group">
                    <label className="control-label" htmlFor="secret" id='adminSignUpDistrictNameLabel'>DistrictName</label>
                    <input autoComplete='off' type="text" className="form-control" id="adminSignUpDistrictNameInput"   name="districtName" required onChange={onDistrictName}/>
    
                </div>
    
    
                    {/* <div className="form-group">
                        <label className="control-label" htmlFor="endpoint">DistrictSchools</label>
                        <input autoComplete='new-password' type="password" className="form-control" id="NewPassword"   name="districtSchools" required onChange={onDistrictSchools}/>
                      
                    </div>  */}
                    <div style={{textAlign: 'center', marginTop: '3vh'}}>
                <Button 
                id="adminSignUpContinueButton"
                onClick={buttonSignUp}
                style={{padding: '8px 80px'}} variant="contained">Continue</Button>
            </div>    
            <p className="d-flex align-items-center mt-3 justify-content-center mr-2">Back To
        <button type="button" className="btn btn-outline text-primary p-0 " style={{marginLeft:10}} onClick={onClickLogin} id="backToAdminLoginFromSignup">Login</button>
      </p>
    
    </div>
        </Form>
    
    
      )
  const renderLoginSection = () => (
    <Form id="adminLogin">
      <div style={{color:"#101626",fontSize:"18px",marginTop:20}}> Welcome back</div>
     
          <div  style={{ fontSize: 11 }}>
            Enter your credentials to access your account.
          </div>

      <div className="form-group cont mt-4 mb-3">
      <label className="control-label mb-2" htmlFor="endpoint" style={{ fontSize: '14px' }} id="adminLoginEmailLabel">Email</label>
        <Form.Control
          type="text" 
          className="form-control"
          name="email" 
          placeholder="Enter your email"
          onChange={onUserChanged}
          value={email}
          onKeyDown={event => focusRefOnEnter(event, passwordInputFieldRef)}
         id="adminLoginEmailInput"
          />
        <span className="icon fa fa-check"></span>
      </div> 
      <div className="form-group cont mb-3">
      <label className="control-label mb-2" htmlFor="password" style={{ fontSize: '14px' }} id='adminLoginPasswordLabel'>Password</label>
      <div className="input-group" >
        <Form.Control 
          type={showPassword ? 'text' : 'password'}
          className="form-control"
          name="password" 
          placeholder="Enter your password"
          onChange={onPasswordChanged}
          value={password}
          ref = {passwordInputFieldRef}
          onKeyDown={event => focusRefOnEnter(event, districtIdInputFieldRef)}
          style={{
            borderRight: 'none',
          }}
          id="adminLoginPasswordInput"
        />
       
          <span
            className="input-group-text"
            onClick={togglePasswordVisibility}
            style={{
              cursor: 'pointer',
              backgroundColor: 'transparent', // Remove background color
              borderLeft: 'none',// Remove border
             
            }}
          >
            {showPassword ? (
               <VisibilityOff fontSize="small"  />
            ) : (
              <Visibility fontSize="small" />
            )}
          </span>
     
        </div>
      </div>
      <div className="form-group cont mb-3">
      <label className="control-label mb-2" htmlFor="key" style={{ fontSize: '14px' }} id="adminLoginDistrictLabel">DistrictId</label>
        <Form.Control 
          type="text" 
          className="form-control"
          name="districtId" 
          placeholder="Enter your District Id"
          onChange={onDistrictIdChanged}
          ref = {districtIdInputFieldRef}
          onKeyDown={event => focusRefOnEnter(event, loginButtonRef)}
          id="adminLoginDistrictInput"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end",}}>
        <button type="button" className="btn btn-outline text-primary " onClick={onClickShowForgotPassword} style={{
        fontSize: '12px', 
        padding: '5px 1px', 
        float: 'right'
  }} id="adminforgotPasswordButton">Forgot Password?</button>
        </div>
      <div className="row justify-content-center px-3" >
        <ProcessingButton 
          id="adminLoginButton"
          //@ts-ignore
          onBtnClick={onLoginFunc}
          btnText={loginStatus === 'loading' ? 'Loading...' : 'Login'}
          isLoading={loginStatus === 'loading'}
          styles="btn-block btn-color text-center mt-1 text-white custom-button-style"
          ref={loginButtonRef}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <button type="button" className="btn btn-outline text-primary p-2 " onClick={onClickSignup} style={{
        fontSize: '12px', 
        padding: '5px 1px', 
        float: 'right'
  }} id="signupButton">SignUp?</button>
      </div>
    </Form>

        
  )


  const renderSendEmailSection = () => (
    <Form onSubmit={onSubmitForm}>
      <div  style={{ color:"#101626",fontSize:"18px",marginTop:20}}>Enter Your Registered Email</div>
      <div  style={{ fontSize: 11,marginTop:3 }}>
            Enter your credentials to access your account.
          </div>

      <div className="form-group cont mt-4">
      <label className="control-label mb-2" htmlFor="endpoint" style={{ fontSize: '14px' }} id="adminForgotPasswordEmail">Email</label>
        <Form.Control
        id="adminForgotPasswordInput"
          type="text" 
          className="form-control"
          placeholder="Enter your email"
          onChange={onUserChanged}
          value={email}
          disabled={showSuccessMessage}
        />
      </div> 
      {showSuccessMessage ? <h5 className="mt-2 text-success">{changePasswordSendEmailDetails.message}</h5> :
        <ProcessingButton 
        id="sendLinkButton"
          //@ts-ignore
          onBtnClick={onClickSendEmail}
          btnText={changePasswordSendEmailDetails.status === 'loading' ? 'Sending...' : 'Send Link'}
          isLoading={changePasswordSendEmailDetails.status === 'loading'}
          styles="btn-block btn-color text-center mt-1 text-white custom-button-style mt-4"
        />
      }
      <p className="d-flex align-items-center mt-3 justify-content-center">Back To
        <button type="button" className="btn btn-outline text-primary p-1 ml-1" onClick={onClickLogin} id="backToAdminLoginFromForgotPassword">Login</button>
      </p>
    </Form>
  )

  return (
    <div className="d-flex flex-row ">
      <div style={{marginLeft:80,width:"400px",backgroundColor:"#fff",borderRadius:4}}>
        <div className="loginRightScreen col-8">
          <div className="login-container m-auto p-4">
            <div className="logo-main d-flex justify-content-center">
              <img src={Logo} alt="bloom-logo" style={{ width: '120px', height: '120px' }} />
            </div>
            {showForgotPassword ?renderSendEmailSection() :
        
            <>
            {showSignup ? renderSignSection():renderLoginSection() }
            </>
             }
             <h1 style={{fontSize:"10px",textAlign:"center",marginTop:10}}>© 2022 Aauti All Rights Reserved.</h1>
          </div>
        </div>
        </div>
    </div>
  )
}

export default Login;
