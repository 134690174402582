import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { StudentService } from "../../../services"

const initialState = {
  studentList: [],
  status: "idle",
  error: null,
  dataSyncedAt:"",
  updateStudentPasswordDetails: {
    message: "",
    status: "idle",
    error: null
  },
  studentDetails: {
    status: "idle",
    isStudentExists: false,
    error: null
  },
  addOrUpdateStudentDetails: {
    message: '',
    status: "idle",
    error: null
  }
}

export const addOrUpdateStudent: any = createAsyncThunk(
  "student/addOrUpdateStudentInfo",
  async (data) => {
    const studentService = new StudentService();
    const response = await studentService.postStudentData(data)
    console.log("Response is*****", response)
    return response
  }
)

export const checkForStudentInformation: any = createAsyncThunk(
  "student/checkForStudentInformation",
  async (data) => {
    const studentService = new StudentService();
    const response = await studentService.studentFetch(data)
    console.log(response)
    return response
  }
)

export const updateStudentPassword: any = createAsyncThunk(
  "student/updateStudentPassword",
  async (data) => {
    const studentService = new StudentService();
    const response = await studentService.postUpdatedStudentPassword(data)
    return response
  }
)

export const checkIfStudentExists: any = createAsyncThunk(
  "staff/checkIfStudentExists",
  async (data) => {
    const studentService = new StudentService();
    const response = await studentService.checkStudentExists(data)
    return response
  }
)

export const studentSlice = createSlice({
  name: "student",
  initialState,

  reducers: {
    resetUpdateStudentPasswordDetails: (state) => {
      state.updateStudentPasswordDetails = initialState.updateStudentPasswordDetails
    },
    resetStudentDetails: (state) => {
      state.studentDetails = initialState.studentDetails
    },
    resetAddOrUpdateStudentDetails: (state) => {
      state.addOrUpdateStudentDetails = initialState.addOrUpdateStudentDetails
    }
  },
  extraReducers: {
    [checkForStudentInformation.pending]: (state, action) => {
      console.log("action payload in extraReducers!!!", action.payload)
      state.status = "loading"
    },
    [checkForStudentInformation.fulfilled]: (state, action) => {
      state.status = "succeeded"
      // Add any fetched posts to the array
      console.log("action payload in extraReducers!!!", action.payload)
      state.studentList = action.payload
      state.dataSyncedAt = action.payload.result?.dataSyncedAt
    },
    [checkForStudentInformation.rejected]: (state, action) => {
      console.log("action payload in extraReducers!!!", action.payload)
      state.status = "failed"
      state.error = action.error.message
    },
    [addOrUpdateStudent.pending]: (state) => {
      state.addOrUpdateStudentDetails.status = "loading"
    },
    [addOrUpdateStudent.fulfilled]: (state, action) => {
      if(!action.payload?.error?.message) {
        state.addOrUpdateStudentDetails.status = 'succeeded'
        state.addOrUpdateStudentDetails.message = action.payload.result.message
      } else {
        state.addOrUpdateStudentDetails.status = 'failed'
        state.addOrUpdateStudentDetails.error = action.payload.error.message
      }
    },
    [addOrUpdateStudent.rejected]: (state, action) => {
      state.addOrUpdateStudentDetails.status = "failed"
      state.addOrUpdateStudentDetails.error = action.error.message
    },
    [updateStudentPassword.fulfilled]: (state, action) => {
      if(action?.payload?.statusCode === 200) {
        state.updateStudentPasswordDetails.status = "succeeded"
        state.updateStudentPasswordDetails.message = action.payload.result
      } else {
        state.updateStudentPasswordDetails.status = "failed"
        state.updateStudentPasswordDetails.error = action.payload.error.message
      }
    },
    [updateStudentPassword.rejected]: (state, action) => {
      state.updateStudentPasswordDetails.status = "failed"
      state.updateStudentPasswordDetails.error = action.error.message
    },

    [checkIfStudentExists.fulfilled]: (state, action) => {
      if(action.payload.result === null) {
        state.studentDetails.status = 'succeeded'
        state.studentDetails.isStudentExists =  false
      } else {
        state.studentDetails.status = 'failed'
        state.studentDetails.isStudentExists =  true
      }
    },
    [checkIfStudentExists.rejected]: (state, action) => {
      state.studentDetails.error = action.error.message
    },
  },
})

export const { resetUpdateStudentPasswordDetails, resetStudentDetails, resetAddOrUpdateStudentDetails } = studentSlice.actions

export default studentSlice.reducer
