import { environment } from "../config/config";
import { BaseService } from './base.service';

export class NotificationService extends BaseService {

    public async sendNotification(data: any) {
        const env = environment()
        const url = "/async-svc/parent-setup-email"
        return await this.postData(url , {data,env});
    }
}
