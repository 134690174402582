import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box,Button} from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";



const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'staff_email', headerName: 'staff_email', width: 160 },
    { field: 'staff_employee_id', headerName: 'staff_employee_id', width: 130 },
    { field: 'staff_firstName', headerName: 'staff_firstName', width: 130 },
    { field: 'staff_job_role', headerName: 'staff_job_role', width: 130 },
    { field: 'staff_lastName', headerName: 'staff_lastName', width: 130 },
    { field: 'staff_password', headerName: 'staff_password', width: 130 },
    { field: 'staff_school_id', headerName: 'staff_school_id', width: 130 },
    { field: 'staff_status', headerName: 'staff_status', width: 130 },
    {
      field: 'staff_user_id',
      headerName: 'staff_user_id',
      type: 'number',
      width: 130,
    },
    {
        field: 'district_id',
        headerName: 'district_id',
        width: 200,
      
      },
    
  ];



export const SelectdTeacher = (props:any) => {
    const {tableData} = props
    return(
        <>
        <Box sx={{borderRadius: '20px',width:'95%'}}>
         <Box  sx={{display:"flex",alignItems:"center",flexDirection:"row",marginBottom:3}}>
         <Box  sx={{marginLeft:5}} id="backToAautiCSVContainer"
         onClick={() => props.onCSVNavigate('aauticsv')}
          >
        <img src={backArrow} height="40px" />
        </Box>
        <h1 style={{color: '#1e1e1e', fontWeight: 500,  textAlign: 'center', fontSize:'22px',marginLeft:550}}>Teacher CSV</h1>

         </Box>
         <Box id="teacherCSVdataGrid">
            <DataGrid
                rows={tableData}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 10,
                    },
                },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
            />
            </Box>
            <div style={{textAlign: 'center', marginTop: '5vh',}}>
            <Button 
            onClick={() => props.onCSVNavigate('studentCSV')}
             style={{padding: '8px 80px'}} variant="contained" id="nextButtonTeacherCSV">Next</Button>
        </div>
        </Box>
        </>
    )
}