import { getEnvironmentUrl,addVersionToUrl, environment } from "../config/config";
import { getItem } from '../store/storage';

export class BaseService {
  
public async postData(url: string, data: any) {
    const env = environment()
    let versionUrl = url
    if(env != 'prod'){
        versionUrl = addVersionToUrl(url)
    }
    const userDetails = getItem('userDetails');
    const API_BASE = getEnvironmentUrl(url);
    
    const response = await fetch(`${API_BASE}${versionUrl}?districtId=${userDetails?.districtId}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    })
    return await response.json();
}

public async getData(url: string) {
    const env = environment()
    let versionUrl = url
    if(env != 'prod'){
        versionUrl = addVersionToUrl(url)
    }
    const userDetails = getItem('userDetails');
    const API_BASE = getEnvironmentUrl(url);

    const response = await fetch(`${API_BASE}${versionUrl}?districtId=${userDetails?.districtId}`, 
    {
        method: 'GET'
    })
    return await response.json();
}

public async updateInfo(url: string, data: any) {
    const env = environment()
    let versionUrl = url
    if(env != 'prod'){
        versionUrl = addVersionToUrl(url)
    }
    const userDetails = getItem('userDetails');
    const API_BASE = getEnvironmentUrl(url);

    const response = await fetch(`${API_BASE}${versionUrl}?districtId=${userDetails?.districtId}`, {
      method: 'PUT',
      headers: {
          'Content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': '581ce4a8a14e4f7dbbdc6c64afe5509b'
        },
      body: JSON.stringify(data)
    });
    console.log(response)
    return await response.json();
}

public async DeleteInfo(url: string, data: any) {
    const env = environment()
    let versionUrl = url
    if(env != 'prod'){
        versionUrl = addVersionToUrl(url)
    }
    const userDetails = getItem('userDetails');
    const API_BASE = getEnvironmentUrl();

    const response = await fetch(`${API_BASE}${versionUrl}?districtId=${userDetails?.districtId}`, {
      method: 'DELETE',
      headers: {'Content-type': 'application/json',
      'Ocp-Apim-Subscription-Key': '581ce4a8a14e4f7dbbdc6c64afe5509b'},
      body: JSON.stringify(data)
    });
    return await response.json();
}

public async getInfoMockAPI(url: string) {
    const env = environment()
    let versionUrl = url
    if(env != 'prod'){
        versionUrl = addVersionToUrl(url)
    }

    const userDetails = getItem('userDetails');
    const API_BASE = getEnvironmentUrl();

    console.log("*** executing parent getInfo ***")
    const response = await fetch(`${API_BASE}${versionUrl}?districtId=${userDetails?.districtId}`, 
    {
        method: 'GET',
        headers: {
            'Ocp-Apim-Subscription-Key': 'a07e3fa63cca49d1bb4a6a4eb29fc527'
        },
    })
    return await response.json();
}
}
