import React from 'react';
//import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

export const DistrictSettings = (props: any) => {
     
    const distList = props.districtList.districtList.result
    // console.log('react',distList)
    const handleUpdate = (e: any, districtParameter: any) => {
       // console.log("handleUpdate ", districtParameter);
       
        props.handleUpdate(e, districtParameter);
      
    }

    return(
        <div className="horizontalScrollBar">
            <table className="ng-table sort-enabled upload-data-table">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>CONFIGURATION</th>
                    <th>DISTRICT ID</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                    </tr>   
                </thead>
                <tbody>
                        
                    {
                        distList && distList.map((object: any, i: number)=>{
                            return(
                                
                        <tr>
                        <td>{i=i+1}</td>
                        <td>{object.configuration}</td>
                        <td>{object.districtId}</td>
                        <td>{object.status?"true":"false"}</td>
                        <td>
                            <div>
                        {(object && object.status)?
                        
                        <Button className="generic-btn" variant="success" onClick={e=> handleUpdate(e,object)}>ON</Button>
                        :
                        <Button className="generic-btn" variant="success" onClick={e=> handleUpdate(e,object)}>OFF</Button>
                        }
                        </div>
                        </td>
                        
                    </tr>
                    // <tr>
                    //      <td>2</td>
                    //     <td>Inactive all staff not in Bloom CSV file</td>
                    //     <td>true</td>
                    //     <td><Button className="generic-btn" variant="success">ON</Button></td>
                    // </tr>
                    // <tr>
                    //     <td>3</td>
                    //     <td>Allow automatic processing of SFTP</td>
                    //     <td>true</td>
                    //     <td><Button className="generic-btn" variant="success">ON</Button></td>
                    // </tr>
                    // <tr>
                    //     <td>4</td>
                    //     <td>Sync student enrollments</td>
                    //     <td>true</td>
                    //     <td>    
                    //         <Button className="generic-btn" variant="danger">OFF</Button>
                    //     </td>
                    // </tr> */}
                            ) 
                        })
                        }
                        
                </tbody>
            </table>
        </div>
    )
}