import React from 'react';
//import Table from 'react-bootstrap/Table';
// import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react'
import { Button, Typography, } from '@mui/material';
import { ReactComponent as Cross } from "../../assets/images/cross.svg"

export const EnrollStaffTable = (props) => {
    //console.log('props in enrollstaff table********',props)
    //console.log('react',props.enrollList.enrolledStaffList.teachers)
    const staffEnrollmentList = props.enrollList.enrolledStaffList
    //console.log('props in enrollstaff table *******',staffEnrollmentList)
    console.log(props.studentSisName,"1233333333333333333333",staffEnrollmentList)
    
    
    return(
        <div>
            <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 0,
                    backgroundColor: 'white', 
                    padding: 2, 
                    border: '1px solid #e6e8ee',
                    borderRadius: 1,

                    width: '100%',maxHeight: '80vh',overflow: 'auto',
                
                }}>
                <Typography variant="h6" style={{color:"#1e1e1e",fontSize:18,marginLeft:30}}>Staff Name - {props.staffSisName}</Typography>
                <Button onClick={props.handleClose}>
                <Cross fill={"gray"} style={{ height: '30px', width: '30px',opacity: 0.41 }} />
                </Button>
              </div>
            {
                staffEnrollmentList && staffEnrollmentList.length===0 ?
                <div>NO ENROLLED STUDENTS FOR THE STAFF - (STAFF_NAME)</div> : 


                // <div className="horizontalScrollBar" style={{ width: '100%' }}> 
                <div style={{ overflowX: 'auto' }}>
                    <table style={{ width: '100%',tableLayout:"fixed" }} className="ng-table sort-enabled child-table">
                        <thead >
                            <tr>
                                <th style={{ width: '5%', borderLeft: '1px solid #e6e8ee', borderTop: '1px solid #e6e8ee' }}>#</th>
                                <th style={{ width: '15%' }}>STUDENT DISTRICT ID</th>
                                <th style={{ width: '15%' }}>STUDENT FIRST NAME</th>
                                <th style={{ width: '15%' }}>STUDENT LAST NAME</th>
                                <th style={{ width: '15%' }}>STUDENT GRADE LEVEL</th>
                                <th style={{ width: '15%' }}>STUDENT ACTIVE</th>
                                <th style={{ width: '20%', borderRight: '1px solid #e6e8ee', borderTop: '1px solid #e6e8ee' }}>STUDENT SIS ID</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            staffEnrollmentList && staffEnrollmentList.map((studentItem,i)=>{
                                return(
                                    <tr key={i+1}>
                                        <td> {i=i+1} </td>
                                        <td>{studentItem.districtId}</td>
                                        <td>{studentItem.firstName}</td>
                                        <td>{studentItem.lastName}</td>
                                        <td>{studentItem.gradeLevel}</td>
                                        <td>{studentItem.active}</td>
                                        <td>{studentItem.sisId}</td>
                                        
                                    </tr>
                                    )
                                    })
                        }
                        </tbody>
                    </table>
                    </div>
                // </div>
            }
        </div>
    )
   

}

