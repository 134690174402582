import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { checkIfStudentExists, resetStudentDetails } from "../../redux/features/student/student.slice"
import { getTeacherIds } from "../../redux/features/staff/staff.slice"
import {RiArrowDropDownLine} from 'react-icons/ri'
import Select from 'react-select'
import "./selectSearch.css"

let filteredOptions = [];

const AddNewStudentModal = forwardRef(({ modalState, handleClose, handleUpdate, editStudent }: any, ref: any) => {

    const [allValues, setAllValues] = useState(editStudent);
    const [errors, setErrors] = useState(allValues)
    const [validateSuccess, setValidateSuccess] = useState(true);
    const [showDropDown, setShowDropDown] = useState(false)
    const [name,setName] = useState(editStudent.teacherId);

    let elementId = "";

    const studentDetails = useSelector((state: any) => state.student.studentDetails)
    const teacherIdDetails = useSelector((state: any) => state.staff.teacherIds.teacherDetails)
    const teacherIdStatus = useSelector((state: any) => state.staff.teacherIds.status)
    const options = teacherIdDetails.map((eachTeacher: any) => {
        return {label:eachTeacher.firstName + " "+ eachTeacher.lastName + " - " + eachTeacher.employeeId,value:eachTeacher.employeeId}
    })

    const teacherIdFromEdit = options.filter((eachOption: any) => {
        if(eachOption.value === editStudent.teacherId){
            return eachOption.name
        }
    })

    useEffect(() => {
        if(editStudent?.teacherId){
            setName(teacherIdFromEdit[0]?.name)
        }
    },[editStudent])

    const filterOptions = () => { 
        
        filteredOptions = options.filter((eachOption: any) => {
            return eachOption.name.toUpperCase().includes(name?.toUpperCase())
        })
    }

    if(name === ""){
        filteredOptions = options
    }
    const dispatch = useDispatch()

    useEffect(() => {
        setAllValues(editStudent)

        // This is for the first time adding the status to default to active in allValues 
        // only for newly creating student
        if (editStudent === "") {
            setAllValues({ ...allValues, "status": "active", "isManualMaintenance": "No" })
        }
        // console.log('&&&&&& ',editStudent)
    }, [editStudent])

    useEffect(() => {
        console.log("studentDetails",studentDetails)
        if(studentDetails.isStudentExists === false && studentDetails.status === 'succeeded') {
            handleUpdateFields()
        }
    },[studentDetails])

    useEffect(() => {
        if(modalState === true){
            dispatch(getTeacherIds())
        }
    },[modalState])

    const changeHandler = (e: any) => {
        let updated = true;
        if (editStudent !== "")
            updated = true;
        else
            updated = false;

        setAllValues({ ...allValues, [e.target.name]: e.target.value.trim(), "created": !updated, "updated": updated })
    }

    useEffect(() => {
        if(studentDetails.isStudentExists){
            elementId = "email"
        }
        focusOnError(elementId)
    },[studentDetails])

    const handleUpdateInChild = (e: any, allValues: any) => {
        e.preventDefault();
        let updated = true;

        const allErrors: any = {};
        console.log(allValues)
        if (!allValues.teacherId || allValues.teacherId === "" || name === ""){
            allErrors.teacherId = "Teacher Id should not be blank"
            elementId = "teacherId"
        }

        // if(filteredOptions.length === 0){
        //     allErrors.teacherId = "Please select valid teacher Id"
        //     elementId = "teacherId"
        // }

        // if(!teacherIdDetails.filter(eachId => eachId === allValues.teacherId)){
        //     allErrors.teacherId = "Please select vaaaaaaaaaaaaaalid"
        //     elementId = "teacherId"
        // }

        if(!allValues.termPayment || allValues.termPayment === ""){
            allErrors.termPayment = "Term Payment should not be blank"
            elementId = "termPayment"
        }

        if (!allValues.section || allValues.section === ""){
            allErrors.section = "Section should not be blank"
            elementId = "section"
        }

        if (!allValues.email || allValues.email === ""){
            allErrors.email = "Email should not be blank"
            elementId = "email"
        }

        if (!allValues.middleName || allValues.middleName === ""){
            allErrors.middleName = "Student Middle Name should not be blank"
            elementId = "middleName"
        }

        if (!allValues.lastName || allValues.lastName === ""){
            allErrors.lastName = "LastName should not be blank"
            elementId = "lastName"
        }

        if (!allValues.firstName || allValues.firstName === ""){
            allErrors.firstName = "Firstname should not be blank"
            elementId = "firstName"
        }

        if (!allValues.NewPassword && editStudent === ""){
            allErrors.NewPassword = "NewPassword should not be blank"
            elementId = "NewPassword"
        }

        if (!allValues.password && editStudent === ""){
            allErrors.password = "Password should not be blank"
            elementId = "password"
        }

        if (!allValues.stuStateId || allValues.stuStateId === ""){
            allErrors.stuStateId = "State Id should not be blank"
            elementId = "stuStateId"
        }

        if (!allValues.sisId || allValues.sisId === ""){
            allErrors.sisId = "Student Sis Id should not be blank"
            elementId = "sisId"
        }

        if (!allValues.userId || allValues.userId === ""){
            allErrors.userId = "UserId should not be blank"
            elementId = "userId"
        }

        if (!allValues.lmsUserId || allValues.lmsUserId === ""){
            allErrors.lmsUserId = "LMS User Id should not be blank"
            elementId = "lmsUserId"
        }

        if (!allValues.schoolId || allValues.schoolId === ""){
            allErrors.schoolId = "School should not be blank"
            elementId = "schoolId"
        }

        if (!allValues.status || allValues.status === "")
            allErrors.status = "Student Status should not be blank"
        
        focusOnError(elementId)

        console.log("errorssssss",allErrors,allValues)
        const editStudentDetails = {...editStudent}

        if(allValues.created !== undefined && allValues.updated !== undefined) {
            editStudentDetails.created = allValues.created
            editStudentDetails.updated = allValues.updated
        }
            
        if (Object.entries(allErrors).length !== 0) {
            setErrors(allErrors);
            setValidateSuccess(false);
        } else if (JSON.stringify(editStudentDetails) !== JSON.stringify(allValues)) {
            if (allValues.email !== editStudent.email) {
                dispatch(checkIfStudentExists({ email: allValues.email, role: "S" }))
                setErrors(allErrors)
            } else {
                handleUpdateFields()
                if (studentDetails.status === "failed") {
                    dispatch(resetStudentDetails())
                }
            } 
        } else {
            handleClose()
            if(studentDetails.status === "failed") {
                dispatch(resetStudentDetails())
            }
        }
    }

    const handleUpdateFields = () => {
        setValidateSuccess(true)
        handleUpdate(allValues)
    }

    const resetState = () => {setAllValues("");setName("");setErrors("")}

    useImperativeHandle(ref, () => {
        return {
            resetState: resetState
        };
    });

    const onBlurEvent = (e: any) => {
        e.preventDefault()
        const intervalId = setTimeout(() => {
            setShowDropDown(false)
            clearInterval(intervalId)
        })
    }

    const focusOnError = (id: any) => {
        console.log(id)
        if(id){
            //document.getElementById(id).focus()
        }
    }

   
    return (
        <>
            <Modal size="lg" show={modalState} onHide={() => { setValidateSuccess(true); handleClose() }} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{editStudent ? <p>Edit Student</p> : <p>Add Student</p>}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mrgnbtm container">
                        <form className={validateSuccess ? "form-horizontal needs-validation" : "form-horizontal was-validated"} noValidate>
                            <div className="form-group">
                                <label className="control-label" htmlFor="name">Student School ID</label>
                                <input autoComplete='off' type="text" className="form-control" onChange={changeHandler} defaultValue={editStudent.schoolId} id="schoolId" name="schoolId" required />
                                <div className="invalid-feedback">{errors.schoolId}</div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="service">LMS User ID</label>
                                <input autoComplete='off' type="text" className="form-control" id="lmsUserId" onChange={changeHandler} defaultValue={editStudent.lmsUserId} name="lmsUserId" required />
                                <div className="invalid-feedback">{errors.lmsUserId}</div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="service">User ID</label>
                                <input autoComplete='off' type="text" className="form-control" id="userId" onChange={changeHandler} defaultValue={editStudent.userId} name="userId" required />
                                <div className="invalid-feedback">{errors.userId}</div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="key">Student SIS ID</label>
                                <input autoComplete='off' type="text" className="form-control" id="sisId" onChange={changeHandler} value={editStudent.sisId} name="sisId" required />
                                <div className="invalid-feedback">{errors.sisId}</div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="secret">Student State ID</label>
                                <input autoComplete='off' type="text" className="form-control" id="stuStateId" onChange={changeHandler} defaultValue={editStudent.stuStateId} name="stuStateId" required />
                                <div className="invalid-feedback">{errors.stuStateId}</div>
                            </div>
                            {editStudent === "" ?
                                <div className="form-group">
                                    <label className="control-label" htmlFor="endpoint">Password</label>
                                    <input autoComplete='new-password' type="password" className="form-control" id="password" onChange={changeHandler} defaultValue={editStudent.password} name="password" required />
                                    <div className="invalid-feedback">{errors.password}</div>
                                </div> : ""
                            }
                            {editStudent === "" ?
                                <div className="form-group">
                                    <label className="control-label" htmlFor="endpoint">Confirm Password</label>
                                    <input autoComplete='new-password' type="password" className="form-control" id="NewPassword" onChange={changeHandler} defaultValue={editStudent.NewPassword} name="NewPassword" required />
                                    <div className="invalid-feedback">{errors.NewPassword}</div>
                                </div> : ""
                            }
                            <div className="form-group">
                                <label className="control-label" htmlFor="endpoint">Student First Name</label>
                                <input autoComplete='off' type="text" className="form-control" id="firstName" onChange={changeHandler} defaultValue={editStudent.firstName} name="firstName" required />
                                <div className="invalid-feedback">{errors.firstName}</div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="endpoint">Student Last Name</label>
                                <input autoComplete='off' type="text" className="form-control" id="lastName" onChange={changeHandler} defaultValue={editStudent.lastName} name="lastName" required />
                                <div className="invalid-feedback">{errors.lastName}</div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="endpoint">Student Middle Name</label>
                                <input autoComplete='off' type="text" className="form-control" id="middleName" onChange={changeHandler} defaultValue={editStudent.middleName} name="middleName" required />
                                <div className="invalid-feedback">{errors.middleName}</div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="endpoint">Student Email</label>
                                <input autoComplete='off' type="text" className="form-control" id="email" onChange={changeHandler} defaultValue={editStudent.email} name="email" required />
                                <div className="invalid-feedback">{errors.email}</div>
                                {(studentDetails.isStudentExists===true && errors.email!=='Email should not be blank') && <div className="text-danger">Email already exists</div>}
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="endpoint">Student Section</label>
                                <input autoComplete='off' type="text" className="form-control" id="section" onChange={changeHandler} defaultValue={editStudent.section} name="section" required />
                                <div className="invalid-feedback">{errors.section}</div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="endpoint">Student Term Payment</label>
                                <input autoComplete='off' type="number" className="form-control" id="termPayment" onChange={changeHandler} defaultValue={editStudent.termPayment} name="termPayment" required />
                                <div className="invalid-feedback">{errors.termPayment}</div>
                            </div>

                            {/* <div className="form-group">
                                <label className="control-label" htmlFor="endpoint">Teacher Id</label>
                                <input autoComplete='off' type="text" className="form-control" id="endpoint" onChange={changeHandler} defaultValue={editStudent.teacherId} name="teacherId" required />
                                <div className="invalid-feedback">{errors.teacherId}</div>
                            </div> */}

                            {/* <div className="form-group">
                                <label className="control-label" htmlFor="endpoint">Teacher Id</label>
                                <div className='form-control d-flex justify-content-space-between align-items-center m-0 pr-0'>
                                    <input
                                        id="teacherId"
                                        style={{border: 'none', outline: 'none'}}
                                        className="mr-auto w-100"
                                        autoComplete="off"
                                        placeholder='Choose the Teacher Id'
                                        value={name}
                                        name="teacherId"
                                        required
                                        onFocus={() => {setShowDropDown(true); filteredOptions = options}}
                                        onChange={(e) => {setName(e.target.value);filterOptions();console.log(e.target.value)}}
                                        onClick={() => {setShowDropDown(true)}}
                                        onBlur={(e) => {onBlurEvent(e)}}
                                    />
                                    <RiArrowDropDownLine onClick={() => {setShowDropDown(!showDropDown)}} size={30} className="p-0 m-0 "/>
                                </div>
                                {showDropDown && <div className='border pt-2' role='button'>
                                    {filteredOptions?.map((eachOption,i) => {
                                        return <p key={i} className='pl-3 p-1 m-0 hover' onClick={() => {setName(eachOption.name);console.log(eachOption.name);setAllValues({...allValues, "teacherId":eachOption.value});setShowDropDown(false)}}>{eachOption.name}</p>
                                       
                                    //    return <p key={i} className='pl-3 p-1 m-0' onMouseEnter={() => {setIndex(i)}} onMouseLeave={() => {setIndex(null)}} style={{backgroundColor: (i === index) && "#24a0ed", color: (i === index) && "#ffffff"}}  onClick={() => {setName(eachOption.name);setAllValues({...allValues, "teacherId":eachOption.value});setShowDropDown(false)}}>{eachOption.name}</p>
                                    })}
                                    {(filteredOptions.length === 0) && <p className='pl-3 p-1 m-0'>No Teacher Id Found</p>}
                                </div>}
                                <div className="invalid-feedback d-block">{errors.teacherId}</div>
                            </div> */}
                            <div className="form-group">
                                <label className="control-label">
                                    Select Teacher Id
                                </label>
                                <Select 
                                    options={options} 
                                    isLoading={teacherIdStatus === "loading"} 
                                    onChange={(value: any)=> {setName(value.value);setAllValues({...allValues, "teacherId":value.value})}}
                                    menuPosition="absolute"
                                    defaultValue={name}
                                    defaultInputValue={name}
                                    id="teacherId"
                                    />
                                    <div className="invalid-feedback d-block">{errors.teacherId}</div>
                            </div>


                            <div className="form-group">
                                <label className="control-label" htmlFor="endpoint">Is Manual Maintenance</label>
                                <div className="d-flex align-items-center">
                                    <input className='mt-1' type="radio" id="maintenanceYes" onChange={changeHandler} defaultChecked={editStudent.isManualMaintenance==="Yes"} name="isManualMaintenance" value="Yes" required />
                                    <label htmlFor="maintenanceYes" className='p-0 m-0 ml-1 mr-2'>Yes</label>
                                    <input className='mt-1' type="radio" id="maintenanceNo" onChange={changeHandler} defaultChecked={editStudent.isManualMaintenance!=="Yes"} name="isManualMaintenance" value="No" required />
                                    <label htmlFor="maintenanceNo" className='p-0 m-0 ml-1 mr-2'>No</label>
                                </div>
                                <div className="invalid-feedback">{errors.IsManual}</div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="endpoint">Student Status</label>
                                <select style={{backgroundImage: 'none'}} className="form-control" aria-label="Default select example" defaultValue={editStudent.status} onChange={changeHandler} name="status" id="status">
                                    <option value="active" >Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                                <div className="invalid-feedback">{errors.status}</div>
                            </div>


                            {/* <div className="form-group">
                    <label className="control-label" htmlFor="endpoint">Created</label>
                    <input type="text" className="form-control" id="endpoint" onChange={changeHandlerBoolean} defaultValue={editStudent !== ""? false: true}  name="created"/>
                </div>
                <div className="form-group">
                    <label className="control-label" htmlFor="endpoint">Updated</label>
                    <input type="text" className="form-control" id="endpoint" onChange={changeHandlerBoolean} defaultValue={editStudent !== ""? true: false} name="updated"/>
                </div> */}
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setValidateSuccess(true); handleClose(); dispatch(resetStudentDetails()) }}>
                        Cancel
                    </Button>
                    {
                        editStudent !== "" ?
                            <Button variant="info" onClick={(e) => { handleUpdateInChild(e, allValues) }}>
                                Update
                            </Button> :
                            <Button variant="info" onClick={(e) => { handleUpdateInChild(e, allValues) }}>
                                Create
                            </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}
)
export default AddNewStudentModal