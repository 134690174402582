import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchOneRosterCourses, integratedFlowChanges } from '../../redux/features/integration/integration.slice';
import { Loading } from '../../shared/Loading';
import { Button } from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";
import { toast} from 'react-toastify';

const columns: GridColDef[] = [
  { 
    field: 'title', 
    headerName: 'Course Title' 
  },
  {
    field: 'grades',
    headerName: 'Grade',
    flex: 1
  },
  {
    field: 'courseCode',
    headerName: 'Course code',
    flex: 1
  },
  {
    field: 'schoolName',
    headerName: 'School',
    flex: 1
  },
  {
    field: 'subjects',
    headerName: 'Subject',
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1
  },
  {
    field: 'dateLastModified',
    headerName: 'Date Last Modified',
    flex: 1
  },
];

export default function SelectSchools({onOneRosterNavigate}: any) {

    const dispatch = useDispatch()
    const CoursesData = useSelector((state: any) => state?.integration?.oneRosterCoursesDetails)
    const selectedSchools = useSelector((state: any) => state?.integration?.integratedFlowProps?.schoolId)
    const selectedSchoolNames = useSelector((state: any) => state?.integration?.integratedFlowProps?.selectedSchoolNames)
    const oneRosterBaseUrl=useSelector((state:any)=>state?.integration?.integratedFlowProps)


    const [rows, setRows] = useState(CoursesData);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    
    useEffect(() => {
        dispatch(fetchOneRosterCourses({
            "oneRosterBaseUrl":oneRosterBaseUrl?.apiEndpoint,
            "clientId": oneRosterBaseUrl?.oAuthkey,
            "clientSecret": oneRosterBaseUrl?.oAuthSecret,
            "schoolIds": selectedSchools
          }))
    },[])

    useEffect(() => {
        if(CoursesData?.status === 'succeeded') {
          let Courses:any =[]
          let couresName:any = CoursesData?.data.map((each:any)=>{
            each.data.map((each1:any)=>{
              let tableData :any = selectedSchoolNames?.filter((each2:any)=>each1.parentSchSourcedId===each2.ID)
              if(tableData[0]!==undefined){
              Courses.push({...each1, schoolName : tableData[0].name})
            }else{
              Courses.push({...each1, schoolName : ""})
            }
            })
          })
            setRows(Courses);
        }
    }, [CoursesData])

    const buttonContinue = () =>{
      const selectSchoolData = CoursesData?.data.map((each:any)=>({
        schoolId:each.schoolId,
        courseIds:each?.data.filter((each:any)=>rowSelectionModel.includes(each.sourcedId)).map((a:any)=>a.sourcedId)
      }))
      if(rowSelectionModel.length>0){
        dispatch(integratedFlowChanges({
          schools: selectSchoolData
          }))
        onOneRosterNavigate('login-options')
      }else{
        toast("Select atleast one course",{type: toast.TYPE.ERROR})
      }
    }

  return (
    <Box sx={{width:'99%', height:'90%', marginTop:'-7vh'}}>
        <img src={backArrow} height="40px" style={{marginLeft:'10px'}} onClick={() => onOneRosterNavigate('select-schools')} id='oneRoasterBackButtonToSelectSchools'/>
        <h1 style={{color: '#1e1e1e', fontWeight: 500, marginBottom: '3vh', textAlign: 'center', fontSize:'22px',marginTop:'-2vh'}}>Select Courses</h1>
        {(CoursesData?.status === 'loading') ? <Loading /> :
            <><Box sx={{ width: '100%',height:"68vh" }} id="oneRoasterSelectCoursesDataGrid">
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row: any) =>  row.sourcedId}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 8,
                    },
                },
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
              //   componentsProps={{
              //     pagination: {
              //       nextIconButtonProps: { id: `next-page-button from ${rows.length + 1}` },
              //       backIconButtonProps: { id: `previous-page-button untill ${rows.length}` },
              //     },
              // }}
            />
            </Box>
            <div style={{textAlign: 'center', marginTop: '2vh'}}>
              <Button onClick={buttonContinue} style={{padding: '8px 80px',textTransform: 'none'}} variant="contained" id="fetchRoasterDataButtonForCourses">Fetch Roaster Data</Button>
            </div></>
        }
    </Box>
  );
}