import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AppContext from '../../store/AppContext';
import { SortIcons } from '../../shared/components/SortIcons'
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../shared/Loading';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { approveAndSendEmailForEventSignup, clearDetailsForApproveAndReject, getEmailsList, rejectEmailForEventSignup } from '../../redux/features/staff/staff.slice';

export const Admin = () => {
    const [selectedStatus, setSelectedStatus] = useState('WAITING')

    const dispatch = useDispatch()
    const appContext = useContext(AppContext);
    const adminInfo = appContext?.userDetails;

    const emailsListDetails = useSelector((state: any) => state.staff.emailsListDetails)
    const emailsList = emailsListDetails?.data

    const approveAndSendEmailDetails = useSelector((state: any) => state.staff.approveAndSendEmailDetails)
    const rejectEmailForEventSignupDetails = useSelector((state: any) => state.staff.rejectEmailForEventSignupDetails)

    useEffect(() => {
        if(approveAndSendEmailDetails?.status === 'succeeded' || rejectEmailForEventSignupDetails?.status === 'succeeded'){
            dispatch(getEmailsList())
            dispatch(clearDetailsForApproveAndReject())
        }
    }, [approveAndSendEmailDetails, rejectEmailForEventSignupDetails])

    const onClickApprove = (email: any, source: any) => {
        dispatch(approveAndSendEmailForEventSignup({email, source}))
    }

    const onClickReject = (email: any) => {
        dispatch(rejectEmailForEventSignup({email}))
    }
    
    const filteredList = emailsList.filter((each: any) => each.status === selectedStatus)

    return (
        <Box p={3}>
            <Box sx={{display:'flex', justifyContent:'flex-end'}}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">Status</InputLabel>
                    <Select  label="Status" value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                        <MenuItem value='WAITING'>Waiting</MenuItem>
                        <MenuItem value='APPROVED'>Approved</MenuItem>
                        <MenuItem value='REJECTED'>Rejected</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {emailsListDetails?.status === 'loading' || approveAndSendEmailDetails?.status === 'loading' || rejectEmailForEventSignupDetails?.status === 'loading' ?<Loading /> :
                <>
                    {filteredList.length > 0 ? 
                        <TableContainer sx={{border:'1px solid #e6e6e6', marginTop:'10px'}}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >S.no</TableCell>
                                        <TableCell >Name</TableCell>
                                        <TableCell >School</TableCell>
                                        <TableCell >Email</TableCell>
                                        <TableCell >Reffered By</TableCell>
                                        <TableCell >Source</TableCell>
                                        <TableCell >Requested Date</TableCell>
                                        <TableCell >Status</TableCell>
                                        <TableCell >Action</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                {filteredList.map((each: any, index: number) => (
                                    <TableRow
                                    key={each._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {each?.firstName}
                                    </TableCell>
                                    <TableCell >{each?.schoolName}</TableCell>
                                    <TableCell >{each?.email}</TableCell>
                                    <TableCell >{each?.referenceEmail}</TableCell>
                                    <TableCell >{each?.source}</TableCell>
                                    <TableCell >{each?.createdAt ? new Date(each?.createdAt).toLocaleString('en-US', {day:'numeric', month: 'numeric', year:'numeric'}) : 'NA'}</TableCell>
                                    <TableCell >{each?.status}</TableCell>
                                    <TableCell >
                                        {each.status === "WAITING" ? 
                                        <Box>
                                            <Button variant='contained' color="success" sx={{mr:5}} onClick={() => onClickApprove(each.email, each.source)}>Approve</Button>
                                            <Button variant='contained'  color="error" onClick={() => onClickReject(each.email)}>Reject</Button>
                                        </Box> 
                                        : 
                                        <Typography sx={{color  : each.status === "APPROVED" ? "green" : "red", fontWeight:'bold'}}>{each.status}</Typography>
                                        }
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <h1 style={{color:'#000000', display:'flex', justifyContent:'center', alignItems:'center', height:'50vh'}}>No Records Found</h1>
                    }
                </>
            }
        </Box>
       
    )
}
