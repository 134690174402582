import { BaseService } from './base.service';

export class SecurityPolicyService extends BaseService {

    public async securityFetch(data: any) {
        const districtId = "abc123"
        const url = `/async-svc/securityPolicies-list-all/${districtId}`
        return await this.getData(url);
    }

    public async updateSecurityData(data: any) {
        const url = "/async-svc/securityPolicies-update"
        return await this.updateInfo(url, data);
    }

}
