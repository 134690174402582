import React from "react"
//import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useContext, useEffect, useState } from 'react';
import { EnrollStudentTable } from './EnrollStudentTable';
// import { ProcessingImage } from '../../shared/components/ProcessingImage';
import {Loading} from '../../shared/Loading';
import { useSelector, useDispatch } from 'react-redux'
import { saveImpersonateUserDetails } from '../../redux/features/user/user.slice'
import AppContext from '../../store/AppContext';
import { environment } from "../../config/config";
import {BsSearch} from 'react-icons/bs'
import "./studentRosterTable.css"
import {SortIcons} from '../../shared/components/SortIcons'
import { Avatar } from '@mui/material'
import {  LightTooltip } from '../../styledComponents/index'
import Modal from '@mui/material/Modal';
import { EditLogo, ChangePasswordLogo, ImpersonateLogo, UserLogo } from '../../assets/images/redesignIcons'

export const StudentTable = (props) => {
  console.log("props are .....", props)
  // const studList = props.studentList.studentList.result?.students
  const studList = props.studentList.studentList
  const enrollList = props.enrollList;
  const studentSisName = props.studentSisName;
  const handler = props.handler;
  const [studentSisId, setStudentSisId] = useState('');
  const [selectedId, selectStudentId] = useState('');
  // const [selectedWidth, selectWidth] = useState();
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [open, setOpen] = useState(false);
 
  const [filteredListState, setFilteredListState] = useState();

  useEffect(()=>{
    const filteredList = studList && studList.filter(({firstName,lastName, sisId}) => {
      const isIncludes = value => value?.toLowerCase().includes(searchInput.trim().toLowerCase())  
      return isIncludes(firstName)||isIncludes(lastName)||isIncludes(sisId)
    })
    setFilteredListState(filteredList)
  },[searchInput])
 

  const uploadedDataLength = filteredListState?.length;
  // const noOfPages = studList?.length > 0 ? (uploadedDataLength + (10-(uploadedDataLength % 10))) / 10 : 0;
  const noOfPages = filteredListState?.length && Math.ceil(uploadedDataLength / 10)

  const [impersonateButtonClick, setImpersonateButtonClick] = useState(false);
  const dispatch = useDispatch()

  const impersonateUserDetails = useSelector(state => state.user.impersonateUserDetails)
  const dataSyncedAt = useSelector(state => state.student.dataSyncedAt && new Date(state.student.dataSyncedAt).toLocaleString())


  const appContext = useContext(AppContext);
  const adminInfo = appContext.userDetails;

  const editStudent = (e, studentEdit) => {
    // console.log("Selected Student for edit ", studentEdit.stuSisId);
    // <AddNewStaffModal modelState={true} staffEmpId={staffEmpId} handleClose={handleClose} handleUpdate={handleUpdate} />
    props.setEditStudent(studentEdit);
    props.setShow(true);
  }

  const fetchEnrolledStudents = (email, name, userId) => {
    // console.log('react/******** fetchedEnrolledStudents');
    setStudentSisId(email)
    // console.log(email);
    selectStudentId(email);
    // selectWidth(document.getElementById('page').getBoundingClientRect().width);
    // console.log(document.getElementById('page').getBoundingClientRect());
    console.log("ghgghhggh",userId)
    props.enrolledStudent(userId);
    props.setStudentSisName(name);
    setOpen(true);
    // var x = document.getElementById("studTable");
    // if (x?.style?.display === "none") {
    //   x.style.display = "block";
    // }
  }
  function handleClose (){
    setOpen(false);
  }
  // console.log('react', studList)
  // console.log('react', studentSisId)

  const handleImpersonate = (studentItem) => {
    const env = environment()
    const user = studentItem.email
    const admin = adminInfo.email
    const role = "S"
    const userDetails = { admin, user, role, environment:env}

    setImpersonateButtonClick(true)
    dispatch(saveImpersonateUserDetails(userDetails))
  }

  if (impersonateUserDetails.status === 'succeeded' && impersonateButtonClick) {
    window.open(
      impersonateUserDetails.data.link,
      '_blank'
    );
    setImpersonateButtonClick(false)
  }

  const onPrevClick = () => {
    setStartIndex(startIndex - 10);
    setEndIndex(endIndex - 10);
    setCurrentPage((endIndex / 10) - 1);
  }

  const onNextClick = () => {
    setStartIndex(startIndex + 10)
    setEndIndex(endIndex + 10)
    setCurrentPage((endIndex / 10) + 1)
  }

  const hideShowSearchInput = (e) => {
    if(!showSearchInput){
      setShowSearchInput(true)
    } else {
      if(!e.target.value){
        setShowSearchInput(false)
      }
    }
  }

  const sortTable = (tableArr, objKey, isAsc) => {
    let sortArr = [].concat(tableArr);
    sortArr.sort((a, b) => {
      if(isAsc){ // ascending order
          return a[objKey] > b[objKey] ? 1 : -1
      } else { // descending order
          return a[objKey] > b[objKey] ? -1 : 1
      }
     })
     setFilteredListState([].concat(sortArr))
  }

  const tableSortClick = (e, tableArr, objKey) => {
    let isAsc = false;
    if(e?.target?.className === 'sortUpLogo'){
      isAsc = true;
      e.target.nextElementSibling.style.opacity = '60%'
    } else {
      isAsc = false;
      e.target.previousElementSibling.style.opacity = '60%'
    }
    e.target.style.opacity = "30%";
    sortTable(tableArr, objKey, isAsc)
  }

  return (
    <>
      {
        studList && studList.length === 0 ?
          <div className="text-center font-weight-bold">STUDENT RECORDS ARE EMPTY. PLEASE UPLOAD.</div> : (

            <>
              {/* {dataSyncedAt && <p className="text-center">Last successful upload {dataSyncedAt}</p>} */}
              <div>
              {/* {filteredList?.length === 0 ? 
              (
                <p className="mb-0">0 of 0 Pages 0 - 0 Records</p>
              ):
              (
                  <p className="mb-0">
                  {currentPage} of {noOfPages} Pages {startIndex + 1} -{" "}
                  {startIndex + 10 > uploadedDataLength ? uploadedDataLength : endIndex} Records
                  </p>
              )
            } */}
            <div className="d-flex align-items-end mb-2 studentRosterHeader">
              <span>Student Roster</span>
                {/* <button disabled={startIndex === 0} className="navigation-btns" onClick={() => onPrevClick()}>Previous</button>
                <button disabled={startIndex + 10 >= uploadedDataLength} className="navigation-btns" onClick={() => onNextClick()}>Next</button> */}
                  <div className="d-flex align-items-center">
                    { dataSyncedAt && <span className="lastUploadedMsg text-center">Last successful upload {dataSyncedAt}</span>}
                    {/* <button id="addNewStudentBtnId" className="addNewStudentBtn" 
                        onClick={(e) => { e.currentTarget.blur(); console.log("elcike"); return handler(true)}}>
                          Add New Student
                    </button> */}
                    {showSearchInput ? 
                        <div className="studentSearchDiv">
                            <input
                              value={searchInput}
                              type="search"
                              className="w-100"
                              style={{ outline:"none"}}
                              placeholder="Search"
                              onChange={(e) => {
                                setSearchInput(e.target.value);
                                // setStartIndex(0);setEndIndex(10);setCurrentPage(1)
                              }}
                              onBlur={(e) => {hideShowSearchInput(e)}}
                            />
                            {searchInput.length === 0 && <BsSearch id="studentSearchInputSvgIcon" />}
                        </div> 
                        :
                        <BsSearch id="studentSearchSvgIcon" onClick={(e) => {hideShowSearchInput(e)}}/>
                  }
                  </div>
            </div>   
          </div>
          {filteredListState?.length === 0 ?
           (<div className="text-center" style={{marginTop:'15%'}}>No Records Found</div>):
           (
            <div className="">
              <table className="ng-table upload-data-table">
                <thead>
                  <tr>
                    <th style={{ width : "3%", fontSize: "12px" }}>#</th>
                    <th style={{ width : "10%", fontSize: "12px" }}>
                      <div className="wrapperDiv">
                        <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "schoolId")}} />
                        <div>School Id</div>
                      </div>
                    </th>
                    <th style={{ width : "10%", fontSize: "12px" }}>
                      <div className="wrapperDiv">
                        <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "lastName")}} />
                        <div>Last Name</div>
                      </div>
                    </th>
                    <th style={{ width : "10%", fontSize: "12px" }}>
                      <div className="wrapperDiv">
                        <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "firstName")}} />
                        <div>First Name</div>
                      </div>
                    </th>
                    <th style={{ width : "8%", fontSize: "12px" }}>
                      <div className="wrapperDiv">
                        <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "gradeLevel")}} />
                        <div>Grade Level</div>
                      </div>
                    </th>
                    <th style={{ width : "10%", fontSize: "12px" }}>
                      <div className="wrapperDiv">
                        <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "sisId")}} />
                        <div>Student Sis Id</div>
                      </div>
                    </th>
                    <th style={{ width : "14%", fontSize: "12px" }}>
                      <div className="wrapperDiv">
                        <SortIcons upOpacity="60%" downOpacity="30%"clickEventHandler={e => {tableSortClick(e, filteredListState, "districtId")}}  />
                        <div>State Id</div>
                      </div>
                    </th>
                    <th style={{ width : "14%", fontSize: "12px" }}>
                      <div className="wrapperDiv">
                        <SortIcons upOpacity="60%" downOpacity="30%"clickEventHandler={e => {tableSortClick(e, filteredListState, "email")}}  />
                        <div>Email</div>
                      </div>
                    </th>
                    <th style={{ width : "8%", fontSize: "12px" }}>
                      <div className="wrapperDiv">
                        <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "status")}} />
                        <div>Status</div>
                      </div>
                    </th>
                    {/* <th style={{ width : "8%", fontSize: "12px" }}>
                      <div className="wrapperDiv">
                        <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "termPayment")}} />
                        <div>Term Payment</div>
                      </div>
                    </th> */}
                    <th style={{ width : "19%", fontSize: "12px" }}>
                      <div className="wrapperDiv justify-content-start">
                        {/* <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "")}} /> */}
                        <div>Actions</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                {
                   filteredListState?.map((studentItem, i) => {
                    console.log("ggefghegher",studentItem.userId)
                    // if (i < startIndex || i >= endIndex) {
                    //   return;
                    // }
                    return (
                      <tbody key={`studentTbody`+i}>
                        <tr key={studentItem._id} className="p-5">
                          <td style={{ width : "3%", fontSize: "12px" }}>{i=i+1}</td>
                          <td style={{ paddingLeft : "23px", width : "10%", fontSize: "12px" }}>{studentItem.schoolId}</td>
                          <td style={{ paddingLeft : "23px", width : "10%", fontSize: "12px" }}>{studentItem.lastName}</td>
                          <td style={{ paddingLeft : "23px", width : "10%", fontSize: "12px" }}>{studentItem.firstName}</td>
                          <td style={{ paddingLeft : "23px", width : "8%", fontSize: "12px" }}>{studentItem.gradeLevel}</td>
                          <td style={{ paddingLeft : "23px", width : "10%", fontSize: "12px" }}>{studentItem.sisId}</td>
                          <td style={{ paddingLeft : "23px", width : "14%", fontSize: "12px" }}>{studentItem.districtId}</td>
                          <td style={{ paddingLeft : "23px", width : "14%", fontSize: "12px" }}>{studentItem.email}</td>
                          <td style={{ paddingLeft : "23px", width : "8%", fontSize: "12px" }}>{studentItem.status}</td>
                          {/* <td style={{ paddingLeft : "23px", width : "8%", fontSize: "12px" }}>{studentItem.termPayment ?? "-"}</td> */}
                          <td style={{ width : "19%", fontSize: "12px" }} className='studentLastColumn'>
                            <div className="d-flex">
                            {/* <LightTooltip title="Edit" placement="bottom">
                              <Avatar
                                onClick={e => { e.currentTarget.blur(); editStudent(e, studentItem)}}
                                sx={{ backgroundColor: "#ffffff", border: "1px solid #e6e8ee", marginRight: "10px", cursor:'pointer' }}>
                                <img src={EditLogo} style={{ width: '13px' }} />
                              </Avatar>
                            </LightTooltip> */}

                            <LightTooltip title="Enroll" placement="bottom">
                              <Avatar
                                onClick={e => { e.currentTarget.blur(); fetchEnrolledStudents(studentItem.email, studentItem.firstName, studentItem.userId)}}
                                sx={{ backgroundColor: "#ffffff", border: "1px solid #e6e8ee", marginRight: "10px", cursor:'pointer' }}>
                                <img src={UserLogo} style={{ width: '13px' }} />
                              </Avatar>
                            </LightTooltip>

                            <LightTooltip title="Impersonate" placement="bottom">
                              <Avatar
                                onClick={e => { e.currentTarget.blur(); handleImpersonate(studentItem)}}
                                sx={{ backgroundColor: "#ffffff", border: "1px solid #e6e8ee", marginRight: "10px", cursor:'pointer' }}>
                                <img src={ImpersonateLogo} style={{ width: '13px' }} />
                              </Avatar>
                            </LightTooltip>

                            <LightTooltip title="Change Password" placement="bottom">
                              <Avatar
                                onClick={e => { e.currentTarget.blur(); props.setEditStudentPassword(studentItem)}}
                                sx={{ backgroundColor: "#ffffff", border: "1px solid #e6e8ee", marginRight: "10px", cursor:'pointer' }}>
                                <img src={ChangePasswordLogo} style={{ width: '13px' }} />
                              </Avatar>
                            </LightTooltip>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )
                  })}
              </table>
            </div>)}
            </>
          )
      }
      {/* {width: selectedWidth } */}
      {
        <Modal
        open={open}
        style={{width:"100vw",height:"100vh",padding:"2%"}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{width: '96vw',height: '90vh',backgroundColor:"white",outline:0,overflow:"auto",borderRadius:10}}>
        {enrollList.status === "loading" ? (
          <div style={{  height: "510px" }}>
          <Loading />
        </div>
        ) : (
          <EnrollStudentTable
            enrollList={enrollList}
            studentSisName={studentSisName}
            handleClose={handleClose}
          />
        )}
        </div>
      </Modal>
      }
     
    </>
  )
}



