import React, { useEffect, useState } from 'react'
import MonthAndYearPicker from '../date-range-picker/MonthAndYearPicker'
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getArticlesData, resetSaveArticlesData, saveArticlesData } from '../../redux/features/staff/staff.slice';
import { Loading } from '../../shared/Loading';
import ArticlesModal from '../modals/ArticlesModal';

const gradeLevels = [
    "Kindergarten - 3D",
    "First Grade - 3D",
    "Second Grade - 3D",
    "Third Grade - 3D",
    "Fourth Grade - 3D",
    "Fifth Grade - 3D",
    "Sixth Grade - 3D",
    "Seventh Grade - 3D",
    "Eighth Grade - 3D"
  ]

const Articles = () => {

    const [MonthAndYearValue, setMonthAndYearValue] = useState<any>(dayjs(new Date()));
    const [selectedGradeLevel, setSelectedGradeLevel] = useState(gradeLevels?.[3])
    const [showModal, setShowModal] = useState(false)
    
    const [selectedTopic, setSelectedTopic] = useState<any>('')
    const [selectedArticle, setSelectedArticle] = useState<any>('')

    const dispatch = useDispatch()
    const articlesDetails = useSelector((state: any) => state?.staff?.articlesData)
    const saveArticlesDetails = useSelector((state: any) => state?.staff?.saveArticlesData)
    
    useEffect(() => {
        const month = new Date(MonthAndYearValue)?.getMonth()
        const year = new Date(MonthAndYearValue)?.getFullYear()
        dispatch(getArticlesData({month, year}))
    }, [MonthAndYearValue])

    useEffect(() => {
        if(saveArticlesDetails.status === 'succeeded'){
            const month = new Date(MonthAndYearValue)?.getMonth()
            const year = new Date(MonthAndYearValue)?.getFullYear()
            dispatch(getArticlesData({month, year}))
            dispatch(resetSaveArticlesData())
        }
    }, [saveArticlesDetails.status])

    useEffect(() => {
        if(selectedArticle){
            const month = new Date(MonthAndYearValue)?.getMonth()?.toString()
            const year = new Date(MonthAndYearValue)?.getFullYear()?.toString()
            const {topic, edition, gradeLevel } = selectedTopic  
            const {title, urlToImage, url, source} = selectedArticle
            const data = {
                month,
                year,
                topic, 
                edition, 
                gradeLevel,
                title,
                imgUrl: urlToImage,
                articleUrl: url,
                domain: source?.name
            }
            dispatch(saveArticlesData(data))
        }
    }, [selectedArticle])

  return (
    <div style={{padding:'20px'}}>

        {showModal && <ArticlesModal selectedTopic={selectedTopic?.topic} setSelectedArticle={setSelectedArticle} showModal={showModal} setShowModal={setShowModal}/>}

        <Box sx={{display:'flex', alignItems:'center'}}>
            <MonthAndYearPicker MonthAndYearValue={MonthAndYearValue} setMonthAndYearValue={setMonthAndYearValue} />
            {(articlesDetails?.status === 'loading' || saveArticlesDetails?.status === 'loading') ? <Loading /> :
                <Box>

                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <FormControl sx={{ m: 1, minWidth: 200 }} size="medium">
                            <InputLabel id="demo-select-small-label">GradeLevel</InputLabel>
                            <Select fullWidth label="GradeLevel" value={selectedGradeLevel} onChange={(e) => setSelectedGradeLevel(e.target.value)}>
                                {gradeLevels?.map((each) => {
                                    return <MenuItem value={each}>{each}</MenuItem>
                                })} 
                            </Select>
                        </FormControl>
                        <Typography sx={{display:'flex', alignItems:'center'}} variant='h5' color={'#000000'}>No Of Articles Selected: &nbsp;<Typography  variant='h5' fontWeight={'bold'} color={'green'}>{articlesDetails?.data?.filter((each: any) => each?.articleUrl && each?.gradeLevel === selectedGradeLevel)?.length}</Typography></Typography>
                        <Typography sx={{display:'flex', alignItems:'center', ml:2}} variant='h5' color={'#000000'}>No Of Articles to be Selected: &nbsp;<Typography  variant='h5' fontWeight={'bold'} color={'red'}>{articlesDetails?.data?.filter((each: any) => !each?.articleUrl  && each?.gradeLevel === selectedGradeLevel)?.length}</Typography></Typography>
                    </Box>
                </Box>
            }
            
        </Box>

        {articlesDetails?.status !== 'loading' && saveArticlesDetails?.status !== 'loading' && <Box>
            <TableContainer sx={{border:'1px solid #e6e6e6', marginTop:'10px',  maxHeight:'60vh', overflowY:'scroll'}}>
            <Table stickyHeader sx={{ minWidth: 650 }} >
                <TableHead>
                    <TableRow>
                        <TableCell>S.no</TableCell>
                        <TableCell >Topic</TableCell>
                        <TableCell >Article Url</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                {articlesDetails?.data?.filter((each: any) => each?.gradeLevel === selectedGradeLevel)?.map((each: any, index: number) => (
                    <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >   
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{each?.topic }</TableCell>
                        <TableCell>
                            {each?.articleUrl ? 
                                each?.articleUrl : 
                                <Button onClick={() => {setShowModal(true); setSelectedTopic(each)}}>Select Article</Button>
                            }
                        </TableCell>
                        
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </Box>}

    </div>
  )
}

export default Articles