import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IntegrationService, NotificationService, UploadService } from '../../../services'

const initialState: any = {
  integrationList: [],
  status: 'idle',
  error: null,
  generateUsernamesDetails: {
    status: 'idle',
    data: [],
    error: null
  },
  uploadUsersDetails: {
    status: 'idle',
    data: [],
    error: null
  },
  sendEmailDetails: {
    status: 'idle',
    data: [],
    error: null
  },
  oneRosterOrganisationsDetails:{
    status: 'idle',
    data: [],
    error: null
  },
  oneRosterCoursesDetails:{
    status: 'idle',
    data: [],
    error: null
  },
  oneRosterDataDetails:{
    status: 'idle',
    data: [],
    error: null
  },
  integratedFlowProps:{
    status: 'idle',
    data: [],
    error: null,
    
  },
  syncOneRosterDetails:{
    status: 'idle',
    data: [],
    error: null
  }
}

export const addOrUpdateIntegration: any = createAsyncThunk('integration/addOrUpdateIntegrationInfo',async(data) => {
  const integrationService = new IntegrationService();
  const response = await integrationService.postIntegrationData(data);
  console.log("Response is*****",response);
  return response;
})

export const updateBrowseFile: any = createAsyncThunk('integration/updateBrowseFile',async(data) => {
  const integrationService = new IntegrationService();
  const response = await integrationService.updateIntegrationData(data);
  console.log("Response from updatebrowse file is*****",response);
  return response;
})

export const checkForIntegrationInformation: any = createAsyncThunk('integration/checkForIntegrationInformation', async (data: any) => {
  const integrationService = new IntegrationService();
  const response = await integrationService.integrationFetch(data);
  console.log(response)
  return response
})

export const getIntegrationTableInformation: any = createAsyncThunk('integration/getIntegrationTableInformation', async (data: any) => {
  const integrationService = new IntegrationService();
  const response = await integrationService.integrationTableFetch(data);
  console.log('getIntegrationTableInformation ',response)
  return response
})

export const deleteIntegrationTableInformation: any = createAsyncThunk('integration/deleteIntegrationTableInformation', async (data: any) => {
  const integrationService = new IntegrationService();
  const response = await integrationService.deleteIntegrationData(data);
  console.log('deleteIntegrationTableInformation ',response)
  return response
})

export const generateUsernames: any = createAsyncThunk('integration/generateUsernames', async(data) => {
  const integrationService = new IntegrationService();
  const response = await integrationService.generateSystemUsernames(data)
  return response
})

export const uploadUsers: any = createAsyncThunk('integration/uploadUsers', async (data) => {
  const uploadService = new UploadService();
  const response = await uploadService.upload(data)
  return response
})

export const sendEmail: any = createAsyncThunk('integration/sendEmail', async (data) => {
  const notificationService = new NotificationService();
  const response = await notificationService.sendNotification(data)
  return response
})

export const fetchOneRosterOrganisations: any = createAsyncThunk('integration/fetchOrgs', async (data) => {
  const integrationService = new IntegrationService();
  const response = await integrationService.getOneRosterOrganisations(data)
  return response
})

export const fetchOneRosterCourses: any = createAsyncThunk('integration/fetchCourses', async (data) => {
  const integrationService = new IntegrationService();
  const response = await integrationService.getOneRosterCourses(data)
  return response
})

export const fetchOneRosterData: any = createAsyncThunk('integration/fetchOneRosterData', async (data) => {
  const integrationService = new IntegrationService();
  const response = await integrationService.getOneRosterData(data)
  return response
})

export const syncRosterData:any = createAsyncThunk('integration/syncRosterDetails', async (data) => {
  const integrationService = new IntegrationService();
  const response = await integrationService.syncOneRosterData(data)
  return response
})


export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    integratedFlowChanges: (state,action) => {
      const key = Object.keys(action.payload)[0]
      state.integratedFlowProps = {...state.integratedFlowProps,[`${key}`]:action.payload[key]}
      console.log(state.integratedFlowProps,"789789")
    },
    changeStatus:(state)=>{
      state.oneRosterDataDetails.status="idle"
    },
    changeSyncStatus:(state)=>{
      state.syncOneRosterDetails.status="idle"
    }
  },
  extraReducers: {
    [checkForIntegrationInformation.pending]: (state, action) => {
      console.log('action payload in extraReducers!!!', action.payload)
      state.status = 'loading'
    },
    [checkForIntegrationInformation.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      console.log('action payload in extraReducers!!!', action.payload)
      state.integrationList = action.payload
    },
    [checkForIntegrationInformation.rejected]: (state, action) => {
      console.log('action payload in extraReducers!!!', action.payload)
      state.status = 'failed'
      state.error = action.error.message
    },
    [getIntegrationTableInformation.pending]: (state, action) => {
      console.log('action payload in getIntegrationTableInformation!!!', action.payload)
      state.status = 'loading'
    },
    [getIntegrationTableInformation.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      console.log('action payload in getIntegrationTableInformation!!!', action.payload)
      state.IntegrationList = action.payload
    },
    [getIntegrationTableInformation.rejected]: (state, action) => {
      console.log('action payload in getIntegrationTableInformation!!!', action.payload)
      state.status = 'failed'
      state.error = action.error.message
    },
    [addOrUpdateIntegration.fulfilled]: (state,action) => {
      state.status = 'Added successfully'
      // state.posts = 
    },
    [addOrUpdateIntegration.rejected]: (state,action) => {
      state.status = 'Failed to add'
      state.error = action.error.message 
    },
    [updateBrowseFile.fulfilled]: (state,action) => {
      state.status = 'Updated successfully'
      // state.posts = 
    },
    [updateBrowseFile.rejected]: (state,action) => {
      state.status = 'Failed to Update'
      state.error = action.error.message 
    },
    [deleteIntegrationTableInformation.fulfilled]: (state,action) => {
      state.status = 'deleted successfully'
      // state.posts = 
    },
    [deleteIntegrationTableInformation.rejected]: (state,action) => {
      state.status = 'Failed to delete'
      state.error = action.error.message 
    },

    [generateUsernames.pending]: (state) => {
      state.generateUsernamesDetails.status = 'loading'
    },
    [generateUsernames.fulfilled]: (state, action) => {
      if(action.payload?.error?.message) {
        state.generateUsernamesDetails.status = 'failed'
        state.generateUsernamesDetails.error = action.payload.error.message
      } else {
        state.generateUsernamesDetails.status = 'succeeded'
        state.generateUsernamesDetails.data = action.payload.result
      }
    },
    [generateUsernames.rejected]: (state, action) => {
      state.generateUsernamesDetails.status = 'failed'
      state.generateUsernamesDetails.error = action.error.message
    },
    
    [uploadUsers.pending]: (state) => {
      state.uploadUsersDetails.status = 'loading'
    },
    [uploadUsers.fulfilled]: (state, action) => {
      if(action.payload?.error?.message) {
        state.uploadUsersDetails.status = 'failed'
        state.uploadUsersDetails.error = action.payload.error.message
      } else {
        state.uploadUsersDetails.status = 'succeeded'
        state.uploadUsersDetails.data = action.payload.result
      }
    },
    [uploadUsers.rejected]: (state, action) => {
      state.uploadUsersDetails.status = 'failed'
      state.uploadUsersDetails.error = action.error.message
    },

    [sendEmail.pending]: (state) => {
      state.sendEmailDetails.status = 'loading'
    },
    [sendEmail.fulfilled]: (state, action) => {
      if(!action.payload?.result?.output) {
        state.sendEmailDetails.status = 'failed'
        state.sendEmailDetails.error = "Some Technical Issue"
      } else {
        state.sendEmailDetails.status = 'succeeded'
        state.sendEmailDetails.data = action.payload.result.output
      }
    },
    [sendEmail.rejected]: (state, action) => {
      state.sendEmailDetails.status = 'failed'
      state.sendEmailDetails.error = action.error.message
    },

    [fetchOneRosterOrganisations.pending]: (state) => {
      state.oneRosterOrganisationsDetails.status = 'loading'
    },
    [fetchOneRosterOrganisations.fulfilled]: (state, action) => {
      if(!action.payload) {
        state.oneRosterOrganisationsDetails.status = 'failed'
        state.oneRosterOrganisationsDetails.error = "Some Technical Issue"
      } else {
        state.oneRosterOrganisationsDetails.status = 'succeeded'
        state.oneRosterOrganisationsDetails.data = action.payload.data
      }
    },
    [fetchOneRosterOrganisations.rejected]: (state, action) => {
      state.oneRosterOrganisationsDetails.status = 'failed'
      state.oneRosterOrganisationsDetails.error = action.error.message
    },
    
    [fetchOneRosterCourses.pending]: (state) => {
      state.oneRosterCoursesDetails.status = 'loading'
    },
    [fetchOneRosterCourses.fulfilled]: (state, action) => {
      if(!action.payload?.data) {
        state.oneRosterCoursesDetails.status = 'failed'
        state.oneRosterCoursesDetails.error = "Some Technical Issue"
      } else {
        state.oneRosterCoursesDetails.status = 'succeeded'
        state.oneRosterCoursesDetails.data = action.payload.data
      }
    },
    [fetchOneRosterCourses.rejected]: (state, action) => {
      state.oneRosterCoursesDetails.status = 'failed'
      state.oneRosterCoursesDetails.error = action.error.message
    },

    [fetchOneRosterData.pending]: (state) => {
      state.oneRosterDataDetails.status = 'loading'
    },
    [fetchOneRosterData.fulfilled]: (state, action) => {
        state.oneRosterDataDetails.status = 'succeeded'
        state.oneRosterDataDetails.data = action.payload.result
      // }
    },
    [fetchOneRosterData.rejected]: (state, action) => {
      state.oneRosterDataDetails.status = 'failed'
      state.oneRosterDataDetails.error = action.error.message
    },

    [syncRosterData.pending]: (state) => {
      state.syncOneRosterDetails.status = 'loading'
    },
    [syncRosterData.fulfilled]: (state, action) => {
      if(!action.payload) {
        state.syncOneRosterDetails.status = 'failed'
        state.syncOneRosterDetails.error = "Some Technical Issue"
      } else {
        state.syncOneRosterDetails.status = 'succeeded'
        state.syncOneRosterDetails.data = action.payload.result
      }
    },
    [syncRosterData.rejected]: (state, action) => {
      state.syncOneRosterDetails.status = 'failed'
      state.syncOneRosterDetails.error = action.error.message
    },

    
  }
})
export const {integratedFlowChanges,changeStatus,changeSyncStatus } = integrationSlice.actions

export default integrationSlice.reducer