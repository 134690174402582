import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { color } from '@mui/system';
import { useState } from 'react';
import { Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler } from "react-hook-form";
import backArrow from "../../assets/images/backArrow.svg";
import { useDispatch,useSelector } from 'react-redux';
import { integratedFlowChanges } from '../../redux/features/integration/integration.slice';


export default function ChooseUserId({ onOneRosterNavigate }: any) {
  const dispatch = useDispatch()

  const [loginId, setLoginId] = useState("email")
  const [checkA, SetCheckA] = useState(false);
  const [checkB, SetCheckB] = useState(false);
  const [checkC, SetCheckC] = useState(true);

  const IDs = ['T888A', 'T999B']

  function handleClickA() {
    SetCheckA(true)
    SetCheckB(false)
    SetCheckC(false)
    setLoginId('username')
  }
  function handleClickB() {
    SetCheckA(false)
    SetCheckB(true)
    SetCheckC(false)
    setLoginId('userId')
  }
  function handleClickC() {
    SetCheckA(false)
    SetCheckB(false)
    SetCheckC(true)
    setLoginId('email')
  }

  function placeholderValue(){
    if(loginId==="email"){
      return('sample@gmail.com')
    }else if(loginId==="userId"){
      return('T888A')
    }else if(loginId==="username"){
      return('sampleName')
    }
  }
  
  const IdsList = () => IDs.map((each: any,index:any) => {
    return (
      <Box>
        <input
          type="checkbox"
          checked={checkB}
          onChange={handleClickB}
          style={{height:'10px'}}
        />
        <label style={{ paddingLeft: '5px', fontSize: '11px', fontWeight:500 }}>{each}</label>
      </Box>
    )
  })

  function buttonContinue(){
      dispatch(integratedFlowChanges({'userIdType':loginId}))
      onOneRosterNavigate('userpassword')
  }

  return (
    <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '540px', width: '1044px', borderRadius: '20px' }}>
      <Box style={{ margin: '30px', paddingBottom: '10px' }} onClick={() => onOneRosterNavigate('login-options')}>
        <img src={backArrow} height="40px" id="backButtonToSelectWayToLogin"/>
      </Box>
      <h1 style={{ color: '#1e1e1e', fontWeight: 600, marginBottom: '4vh', textAlign: 'center', fontSize: '20px' }}>Choose UserId for staff</h1>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '400px' }}>
          <label style={{ paddingBottom: '3px', color: '#333333',fontSize: '12px', fontWeight:500  }} id='selectIdToUserLoginLabel'>Select one Id for users to use for login</label>
          <Card variant="outlined" sx={{ backgroundColor: '#ffff', height: '142px', borderRadius: '5px', padding: '15px' }}>
            <Box>
              <input
              id="oneRoasterSelectUserNameInput"
                type="checkbox"
                checked={checkA}
                onChange={handleClickA}
                style={{height:'10px'}}
              />
              <label style={{ paddingLeft: '5px',fontSize: '11px', fontWeight:500  }} id="oneRoasterSelectUserNameLabel">Username</label>
            </Box>
            <Box>
              <Box>
                <input
                id="oneRoasterSelectUserIDsInput"
                  type="checkbox"
                  checked={checkB}
                  onChange={handleClickB}
                  style={{height:'10px'}}
                />
                <label style={{ paddingLeft: '5px',fontSize: '11px', fontWeight:500  }} id="oneRoasterSelectUserIDsLabel">UserID's</label>
              </Box>
              <Box sx={{ paddingLeft: '25px' }}>
                {IdsList()}
              </Box>
            </Box>
            <Box>
              <input
              id="oneRoasterSelectEmailInput"
                type="checkbox"
                checked={checkC}
                onChange={handleClickC}
                style={{height:'10px'}}
              />
              <label style={{ paddingLeft: '5px', fontSize: '11px', fontWeight:500  }} id='oneRoasterSelectEmailLabel'>Email:</label>
            </Box>
          </Card>
          <label style={{ paddingTop: '20px', paddingBottom: '3px', color: '#333333',fontSize: '12px', fontWeight:500 }} id="oneRoasterSampleLoginLabel">Sample login</label>
          <input className="MuiInput-input" readOnly value={placeholderValue()} id="oneRoasterSelectSampleLoginInput" placeholder='sample@gmail.com' style={{ backgroundColor: '#ffff', border: 'solid 1px #e9e9e9', width: '100%', height: '30px', padding: '5px', fontSize:'11px', fontWeight: 500 }}/>
          <label style={{ paddingTop: '20px', color: '#333333', fontSize: '11px' }}>This user id will applied to all the staff users in the system</label>
        </Box>
      </Box>
      <div style={{ textAlign: 'center', marginTop: '4vh' }}>
        <Button onClick={buttonContinue} style={{ padding: '8px 110px', textTransform: 'none' }} variant="contained" id="oneRoasterNextButtonFromChooseUserId">Next</Button>
      </div>

    </Box>
  );
}
