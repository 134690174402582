export const saveItem = (key: string, value: string) => localStorage.setItem(key, JSON.stringify(value));

export const getItem = (key: string): any => {

    const localStorageItem: string | null = localStorage.getItem(key);

    if (localStorageItem) {
        return JSON.parse(localStorageItem);
    }
    return {}
}

export const clearStorage = () => localStorage.clear();

export const getUserId = () => {
    const userDetails = getItem("userDetails");
    return userDetails ? userDetails.userId : "";
}

export const saveReduxState = (state: any) => {

    try{
        const serializedState = JSON.stringify(state);
        localStorage.setItem('reduxState', serializedState);
    } catch (err) {
        console.log(`Error Occured while loading redux state `, err);
    }
    
}

export const loadReduxState = (state: any) => {

    try{
        const serializedState = localStorage.getItem('reduxState');
        if (serializedState === 'undefined' || serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.log(`Error Occured while loading redux state `, err);
    }
    
}