import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect, useImperativeHandle, forwardRef, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkIfTeacherExists, resetTeacherDetails } from "../../redux/features/staff/staff.slice"
import AppContext from '../../store/AppContext';

// function AddNewStaffModal({modalState, handleClose, handleUpdate, editStaff} ){
    const AddNewStaffModal = forwardRef(({modalState, handleClose, handleUpdate, editStaff}: any, ref: any ) => {

    const [allValues, setAllValues] = useState(editStaff);
    const [errors,setErrors] = useState(allValues)
    const [validateSuccess, setValidateSuccess] = useState(true);

    const teacherDetails = useSelector((state: any) => state.staff.teacherDetails)
    const dispatch = useDispatch()
    const appContext = useContext(AppContext);
    const DistrictId = appContext?.userDetails.districtId

    let elementId = "";

    useEffect(() => {
        setAllValues(editStaff)
    // This is for the first time adding the status to default to active in allValues 
    // only for newly creating staff
    if(editStaff===''){
    setAllValues({...allValues, "status":"active", "isManualMaintenance":"No", "districtId": DistrictId})
    }
    },[editStaff])

    useEffect(() => {
        if(teacherDetails.isTeacherEmailExists === false && teacherDetails.isTeacherEmployeeIdExists === false && teacherDetails.status === 'succeeded') {
            handleUpdateFields()
        }
    },[teacherDetails])
    
    const changeHandler = (e: any) => {
        let updated = true;
        if(editStaff !== "")
            updated = true;
        else
            updated = false;
        setAllValues({...allValues, [e.target.name]: e.target.value.trim(), "created": !updated, "updated": updated})
    }

    useEffect(() => {
        if(teacherDetails.isTeacherEmailExists){
            elementId = "email"
        }
        if(teacherDetails.isTeacherEmployeeIdExists){
            elementId = "employeeId"
        }
        focusOnError(elementId)
    },[teacherDetails])


    const handleUpdateInChild = (e: any, allValues: any) => {
        e.preventDefault();
        let updated = true;
        
        const allErrors: any = {};

        if(!allValues.email || allValues.email === ""){
            allErrors.email = "Email should not be blank"
            elementId = "email"
        }

        if(!allValues.NewPassword && editStaff===""){
            allErrors.NewPassword = "New Password should not be blank"
            elementId = "NewPassword"
        }

        if(!allValues.password && editStaff===""){
            allErrors.password = "Password should not be blank"
            elementId = "password"
        }

        if(!allValues.userId || allValues.userId === ""){
            allErrors.userId = "User Id should not be blank"
            elementId = "userId"
        }

        if(!allValues.role || allValues.role === ""){
            allErrors.role = "Job Role should not be blank"
            elementId = "role"
        }

        if(!allValues.schoolId || allValues.schoolId === ""){
            allErrors.schoolId = "School Id should not be blank"
            elementId = "schoolId"
        }
        
        if(!allValues.districtId || allValues.districtId === ""){
            allErrors.districtId = "District Id should not be blank"
            elementId = "districtId"
        }

        if(!allValues.firstName || allValues.firstName === ""){
            allErrors.firstName = "First Name should not be blank"
            elementId = "firstName"
        }

        if(!allValues.lastName || allValues.lastName === ""){
            allErrors.lastName = "Last Name should not be blank"
            elementId = "lastName"
        }
            
        if(!allValues.employeeId || allValues.employeeId === ""){
            allErrors.employeeId = "Employee Id should not be blank"
            elementId = "employeeId"
        }
            
        if(!allValues.lmsUserId || allValues.lmsUserId === ""){
            allErrors.lmsUserId = "LMS User Id should not be blank"
            elementId = "lmsUserId"
        }
            
        if(!allValues.status || allValues.status === "")
            allErrors.status = "Status should not be blank"
        
        focusOnError(elementId)

        console.log("aaaaaaaaa",allValues)

        const editStaffDetails = {...editStaff}

        if(allValues.created !== undefined && allValues.updated !== undefined) {
            editStaffDetails.created = allValues.created
            editStaffDetails.updated = allValues.updated
        }
            
        if(Object.entries(allErrors).length !== 0) {
            setErrors(allErrors);
            setValidateSuccess(false);
        } else if (JSON.stringify(editStaffDetails) !== JSON.stringify(allValues)) {
            if(allValues.email !== editStaff.email) {
                dispatch(checkIfTeacherExists({email:allValues.email,employeeId:allValues.employeeId,role:"T"}))
                setErrors(allErrors)
            } else {
                handleUpdateFields()
                if(teacherDetails.status === "failed") {
                    dispatch(resetTeacherDetails())
                }
            }
        } else {
            handleClose()
            if(teacherDetails.status === "failed") {
                dispatch(resetTeacherDetails())
            }
        }
    }

    const handleUpdateFields = () => {
        setValidateSuccess(true)
        handleUpdate(allValues)
    }

    const resetState = () => setAllValues("")

    useImperativeHandle( ref, () => {
        return {
            resetState: resetState
        };
      });

    const focusOnError = (id: any) => {
        if(id){
            //document.getElementById(id).focus()
        }
    }

    return (       
      <>
      
        <Modal show={modalState} size="lg" onHide={() => {setValidateSuccess(true);handleClose()}} scrollable={true}>
          <Modal.Header closeButton>
            <Modal.Title>{editStaff ? <p>Edit Staff</p> : <p>Add Staff</p>}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mrgnbtm container"  >
                <form className= {validateSuccess? "form-horizontal needs-validation" : "form-horizontal was-validated"} noValidate>
                    <div className="form-group">
                        <label className="control-label" htmlFor="service">LMS User ID</label>
                        <input autoComplete='off' type="text" className="form-control" onChange={changeHandler} id="lmsUserId" defaultValue={editStaff.lmsUserId} name="lmsUserId" required/>
                        <div className="invalid-feedback">{errors.lmsUserId}</div>
                    </div>
                    <div className="form-group">
                        <label className="control-label" htmlFor="name">Employee ID</label>
                        <input autoComplete='off' type="text" className="form-control" onChange={changeHandler} id="employeeId" value={editStaff.employeeId} name="employeeId" required/>
                        <div className="invalid-feedback">{errors.employeeId}</div>
                        {teacherDetails.isTeacherEmployeeIdExists && <div className="text-danger">Employee Id already exists</div>}
                    </div>
                    <div className="form-group">
                        <label className="control-label" htmlFor="key">Staff Last Name</label>
                        <input autoComplete='off' type="text" className="form-control" onChange={changeHandler} id="lastName" defaultValue={editStaff.lastName} name="lastName" required/>
                        <div className="invalid-feedback">{errors.lastName}</div>
                    </div>
                    <div className="form-group">
                        <label className="control-label" htmlFor="secret">Staff First Name</label>
                        <input autoComplete='off' type="text" className="form-control" onChange={changeHandler} id="firstName" defaultValue={editStaff.firstName} name="firstName" required/>
                        <div className="invalid-feedback">{errors.firstName}</div>
                    </div>
                    <div className="form-group">
                        <label className="control-label" htmlFor="endpoint">Staff District ID</label>
                        <input autoComplete='off' type="text" className="form-control" onChange={changeHandler} id="districtId" defaultValue={editStaff ? editStaff.districtId : DistrictId} name="districtId" required/>
                        <div className="invalid-feedback">{errors.districtId}</div>
                    </div>
                    <div className="form-group">
                        <label className="control-label" htmlFor="endpoint">Staff School ID</label>
                        <input autoComplete='off' type="text" className="form-control" onChange={changeHandler} id="schoolId" defaultValue={editStaff.schoolId} name="schoolId" required/>
                        <div className="invalid-feedback">{errors.schoolId}</div>
                    </div>
                    <div className="form-group">
                        <label className="control-label" htmlFor="endpoint">Job Role</label>
                        <input autoComplete='off' type="text" className="form-control" onChange={changeHandler} id="role" defaultValue={editStaff.role} name="role" required/>
                        <div className="invalid-feedback">{errors.role}</div>
                    </div>
                    <div className="form-group">
                        <label className="control-label" htmlFor="endpoint">User ID</label>
                        <input autoComplete='off' type="text" className="form-control" onChange={changeHandler} id="userId" defaultValue={editStaff.userId} name="userId" required/>
                        <div className="invalid-feedback">{errors.userId}</div>
                    </div>
                    { editStaff===""?
                    <div className="form-group">
                        <label className="control-label" htmlFor="endpoint">Password</label>
                        <input autoComplete='new-password' type="password" className="form-control" onChange={changeHandler} id="password" defaultValue={editStaff.password} name="password" required/>
                        <div className="invalid-feedback">{errors.password}</div>
                    </div> : ""
                    }
                    { editStaff===""?
                    <div className="form-group">
                        <label className="control-label" htmlFor="endpoint">Confirm Password</label>
                        <input autoComplete='new-password' type="password" className="form-control" onChange={changeHandler} id="NewPassword" defaultValue={editStaff.NewPassword} name="NewPassword" required/>
                        <div className="invalid-feedback">{errors.NewPassword}</div>
                    </div> : ""
                    }
                    <div className="form-group">
                        <label className="control-label" htmlFor="endpoint">Staff Email</label>
                        <input autoComplete='off' type="text" className="form-control" onChange={changeHandler} id="email" defaultValue={editStaff.email} name="email" required/>
                        <div className="invalid-feedback">{errors.email}</div>
                        {teacherDetails.isTeacherEmailExists && <div className="text-danger">Email already exists</div>}
                    </div>

                    <div className="form-group">
                        <label className="control-label" htmlFor="endpoint">Is Manual Maintenance</label>
                        <div className="d-flex align-items-center">
                            <input className='mt-1' type="radio" id="maintenanceYes" onChange={changeHandler} defaultChecked={editStaff.isManualMaintenance === "Yes"} name="isManualMaintenance" value="Yes" required />
                            <label htmlFor="maintenanceYes" className='p-0 m-0 ml-1 mr-2'>Yes</label>
                            <input className='mt-1' type="radio" id="maintenanceNo" onChange={changeHandler} defaultChecked={editStaff.isManualMaintenance !== "Yes"} name="isManualMaintenance" value="No" required />
                            <label htmlFor="maintenanceNo" className='p-0 m-0 ml-1 mr-2'>No</label>
                        </div>
                        <div className="invalid-feedback">{errors.isManualMaintenance}</div>
                    </div>

                    <div className="form-group">
                        <label className="control-label" htmlFor="endpoint">Staff Status</label>
                        <select style={{backgroundImage: 'none'}} className="form-control" aria-label="Default select example" defaultValue={editStaff.status} onChange={changeHandler} name="status">
                            <option value="active" >Active</option>
                            <option value="inactive">InActive</option>
                        </select>
                        <div className="invalid-feedback">{errors.status}</div>
                    </div>
                    {/* <div className="form-group">
                        <label className="control-label" for="endpoint">Created</label>
                        <input type="text" className="form-control" onChange={changeHandlerBoolean} id="endpoint" defaultValue={editStaff !== ""? false: true} name="created" />
                    </div>
                    <div className="form-group">
                        <label className="control-label" for="endpoint">Updated</label>
                        <input type="text" className="form-control" onChange={changeHandlerBoolean} id="endpoint" defaultValue={editStaff !== ""? true: false} name="updated" />
                    </div> */}
                </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=> {setValidateSuccess(true);handleClose();dispatch(resetTeacherDetails())}}>
              Cancel
            </Button>
            {editStaff != ""?
            <Button variant="info" onClick={(e) => {handleUpdateInChild(e,allValues)}}>
              Update
            </Button>
            :
            <Button variant="info" onClick={(e) => {handleUpdateInChild(e,allValues)}}>
            Create
            </Button>
            }
          </Modal.Footer>
        </Modal>
      </>
    );
  }
    )
  export default AddNewStaffModal