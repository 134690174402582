import * as React from 'react';
import { Box, CardContent, Typography } from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";


export default function SSOText({onOneRosterNavigate}: any) {

  return (
      <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '700px', width: '1044px', borderRadius: '20px' }}>
          <Box style={{ margin: '30px', paddingBottom: '50px' }} onClick={() => onOneRosterNavigate('login-options')}>
              <img src={backArrow} height="40px" />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '9vh' }}>
              <CardContent sx={{ textAlign: 'center', backgroundColor: '#ffff', width: '800px', height: '260px', justifyContent: 'center', padding: '80px', borderRadius: '6px' }}>
                  <Typography variant="body2" sx={{ fontWeight: 500, fontSize: '25px', color: '#1e1e1e' }}>
                      Please contact Aauti customer support to activate Single sign on for your school district. All the enrollment roster data is processed
                  </Typography>
              </CardContent>
          </Box>
      </Box>
    )
}
