import '../assets/css/login.css';
import { useNavigate, Navigate } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react';
import LoginLayout from '../layouts/LoginLayout';
import Login from '../components/login/Login';
// redux
import { useSelector, useDispatch } from 'react-redux'
import { checkForUserProfile } from '../redux/features/user/user.slice'
import useToast from '../shared/components/Toast'
import AppContext from '../store/AppContext';


function LoginContainer() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const showToast = useToast();
  const [loginStatus,setLoginStatus] = useState(true)

  const appContext = useContext(AppContext);

  const userStatus = useSelector((state: any) => state.user.status)
  const userProfile = useSelector((state: any) => state.user.userProfile)

  const onLogin = (email: string, password: string, districtId: string) => {
      if(loginStatus) {
        setLoginStatus(false)
        dispatch(checkForUserProfile({email, password, districtId}))
      }
  }

  useEffect(() => {
    if (userProfile.status) {
        const { firstName, districtId } = userProfile.user;
        showToast({ message: `Welcome ${firstName}!` });
        appContext?.setUserDetails({...userProfile.user, status: userProfile.status});
        navigate(`${districtId}/dashboard`)
    } if (userProfile.status === false) {
      showToast({ message : "You have entered Invalid data" , type : "error" })
      const loginIntervalId = setInterval(() => {
        setLoginStatus(true)
        clearInterval(loginIntervalId)
      }, 3000)
    }
  }, [userProfile])

  const userDetails = appContext?.userDetails
  if(userDetails?.status === true){
    return <Navigate to={`${userDetails.districtId}/dashboard`}/>
  }

  return (
    <div className="loginScreen">
      <LoginLayout>
        <Login onLogin={onLogin} loginStatus={userStatus}/>
      </LoginLayout>
      </div>
    );
}

export default LoginContainer;