import auth from './AuthService';
import { BaseService } from './base.service';

export class DistrictsService extends BaseService {

    public async districtFetch(data: any) {
        const districtInfo = auth.getAdminInfo()
         const url = `/async-svc/districtParameterList/${districtInfo.users.districtId}`
         return await this.getData(url);
     }

     public async updateDistrictData(data: any) {
         const url = "/async-svc/districtParameter-update"
         return await this.updateInfo(url, data);
     }

}
