import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { color } from '@mui/system';
import { useState } from 'react';
import { Button } from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";

const Enrollment = styled(Paper)(({ theme }) => ({
  width: 200,
  height: 140,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
  marginRight: '3%',
  cursor: 'pointer'
}));

const selectedBox = {backgroundColor: 'rgba(49, 102, 186, 1)', color: 'white',fontSize:'16px'}

export default function IntegrationConsole({onOneRosterNavigate,onCSVNavigate}: any) {

  const [selection, setSelection] = useState<any>({rosterMethod: 'oneroster'})

  console.log(selection.rosterMethod)

  const abc=(selection:any)=>{
    if(selection ==="oneroster"){
      onOneRosterNavigate(selection)
    }else if (selection==="aauticsv"){
      onCSVNavigate(selection)
    }

  }

  return (
    
    <div style={{backgroundColor:'rgb(215,215,215,0.1)',height:'520px',width:'1044px',borderRadius: '20px',paddingTop:'50px'}}>
       <img src={backArrow} height="40px" style={{marginLeft:'10px'}} onClick={() => onOneRosterNavigate('completeFlow')}/>
        <h1 style={{color: '#1e1e1e', fontWeight: 500, marginBottom: '10vh', textAlign: 'center', fontSize:'25px'}}>Rostering & Enrollment</h1>
        <Stack style={{marginLeft: '20vh'}} direction="row" spacing={4}>
            <Enrollment onClick={(e) => setSelection({rosterMethod: 'oneroster'})} style={{display:'flex',flexDirection:'column',fontWeight:500,fontSize:'16px',justifyContent:'center',textAlign:'center',backgroundColor:selection.rosterMethod == 'oneroster' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: selection.rosterMethod == 'oneroster' ? '#ffffff':'#1e1e1e'}} square={false} id="oneRoasterApiButton">One Roster API</Enrollment>
            <Enrollment onClick={(e) => setSelection({rosterMethod: 'onersotercsv'})} style={{display:'flex',flexDirection:'column',fontWeight:500,fontSize:'16px',justifyContent:'center',textAlign:'center',backgroundColor:selection.rosterMethod == 'onersotercsv' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: selection.rosterMethod == 'onersotercsv' ? '#ffffff':'#1e1e1e'}} square={false} id="oneRoasterCSVButton">One Roster CSV</Enrollment>
            <Enrollment onClick={(e) => setSelection({rosterMethod: 'aauticsv'})} style={{display:'flex',flexDirection:'column',fontWeight:500,fontSize:'16px',justifyContent:'center',textAlign:'center',backgroundColor:selection.rosterMethod == 'aauticsv' ? 'rgba(49, 102, 186, 1)':'#ffffff' ,color: selection.rosterMethod == 'aauticsv' ? '#ffffff':'#1e1e1e'}} square={false} id='aautiStaffStudentCSVButton'>Aauti Staff & Student CSV</Enrollment>
        </Stack>
        <div style={{textAlign: 'center', marginTop: '13vh'}}>
            <Button onClick={() => abc(selection.rosterMethod)} style={{padding: '10px 70px'}}variant="contained" id={`continueButtonFor${selection.rosterMethod}`}>Continue</Button>
        </div>
    </div>

  );
}
