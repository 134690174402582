import { useState, useEffect } from 'react';
import AdminLayout from '../layouts/AdminLayout';
import { SchoolMapping } from '../components/district-settings/SchoolMapping';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { checkForSchoolInformation , addOrUpdateSchool} from '../redux/features/school/school.slice'
import {ProcessingImage} from '../shared/components/ProcessingImage'
import { changeActivePage } from '../redux/features/user/user.slice';
function SchoolMappingContainer() {
  const schoolList = useSelector((state: any) => state.school)
  console.log('school::', schoolList)
  const dispatch = useDispatch()
  //const history = useHistory()
  const [ show,setShow ] = useState(false);
  const [ editSchool,setEditSchool] = useState<any>('')
  const addJobRole = () => {
    console.log('in the container!!!');
    setShow(true);
  }
  const handleClose = () => {
      setShow(false);
  }
  const handleUpdate = (e: any, schoolMappingData: any) => {
    console.log("handleUpdate" , schoolMappingData)
    e.preventDefault();

    // inputData.school = {"school": staffData, "IsManual":schoolData.IsManual, "created": schoolData.created, "updated": schoolData.updated}
    // let inputData = {"school": {
    //   "school": schoolData, "IsManual":schoolData.IsManual, "created": schoolData.created, "updated": schoolData.updated
    // }}
    // console.log("TOtal Input data to be passed", inputData);
    let districtSchoolId=schoolMappingData.districtSchoolId;
    let schoolName= schoolMappingData.schoolName;
    let districtName =   schoolMappingData. districtName;
    let districtSchoolLMSId =  schoolMappingData. districtSchoolLMSID;
    let schoolType  =  schoolMappingData. schoolType;
    let status =  schoolMappingData. status;
    let city =  schoolMappingData. city;
    let state = schoolMappingData. state;
    let active = schoolMappingData. active;
    dispatch(addOrUpdateSchool({
      "schoolMappingData":
      {
              "districtSchoolId": districtSchoolId,
              "schoolName": schoolName,
              "districtName": districtName,
              "districtSchoolLMSId": districtSchoolLMSId,
              "schoolType":schoolType,
              "status": status,
              "city": city,
              "state":state,
              "active":active

          }
    }))
    if(schoolList.schoolList.statusCode === 200){
      setEditSchool(true)
    } 
  }

  useEffect(() => {
    dispatch(checkForSchoolInformation())
    if(editSchool)
    setEditSchool(false)
    }, [dispatch,editSchool])

  useEffect(() => {
    dispatch(changeActivePage("settings"))
  },[])

  return (
    <>
    <AdminLayout showButton="true" handleClose={handleClose} handler={addJobRole} name="Add New School">
        <div className="container mrgntop">
          {
            schoolList.status==="loading" ? 
            <ProcessingImage isLoading={true}/> :
            <SchoolMapping schoolList={schoolList} handleUpdate={handleUpdate} setEditSchool={setEditSchool}/>
          }
        </div>
      </AdminLayout>
      </>
    );
}
export default SchoolMappingContainer;