import auth from './AuthService'
import { BaseService } from './base.service';

export class JobRoleService extends BaseService {

    public async jobRoleFetch(data: any) {
        const jobRoleInfo = auth.getAdminInfo()
        const url = `/async-svc/job-role-mappings/${jobRoleInfo.users.districtId}`
        return await this.getData(url);
    }
    public async postJobRoleData(data: any) {
        const url = "/async-svc/jobmapping"
        return await this.postData(url, data);
    }
    public async deleteJobRoleData(data: any) {
        const url = "/async-svc/job-mappings-delete"
        return await this.updateInfo(url, data)
    }
}
