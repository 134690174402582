import { BaseService } from "./base.service"

export class StudentService extends BaseService {

  public async studentFetch(data: any) {
    const {districtId} = data
    const url = `/async-svc/students/${districtId}`
    return await this.getData(url)
  }

  public async enrolledStudentFetch(data: any) {
    const userId = data.userId
    const districtId = data.districtId
    const url = `/async-svc/teachers/enrolledTeachers/${districtId}/${userId}`
    return await this.getData(url)
  }

  public async postStudentData(data: any) {
    const url = "/api/student-update"
    return await this.postData(url, data)
  }

  public async postUpdatedStudentPassword(data: any) {
    const url = "/api/student/change-password"
    return await this.postData(url, data)
  }
  
  public async checkStudentExists(data: any) {
    const url = "/api/user/details"
    return await this.postData(url, data)
  }
}


