import { BaseService } from "./base.service";

export class UploadService extends BaseService {

    public async upload(data: any) {
        const type = data.type;
        let url = '';
        let uploadData = {}
        if(type === 'students') {
            url = "/api/students"
            uploadData = {students: data.tableData}
        } else {
            url = "/api/teachers"
            uploadData = {teachers: data.tableData}
        }
        return await this.postData(url,uploadData);
    }
}
