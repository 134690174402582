import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {forwardRef,useState, useImperativeHandle, useRef} from 'react';

const BloomModal = forwardRef(({modalState, handleClose, handleUpdate, editJobRole}: any, ref: any)=> {
  const empRef = useRef<any>(null);
  const [allValues, setAllValues] = useState<any>({ 
   firstName:editJobRole.firstName,
   lastName:editJobRole.lastName, 
   employeeId:editJobRole.employeeId,
   bloomJobRole:editJobRole.bloomJobRole,
   districtJobRole:editJobRole.districtJobRole,
   recordStatus:editJobRole.recordStatus
   })
   const handleClear = (e: any) => {
    // document.getElementById("one").value = "";
    // document.getElementById("two").value = "";
    // document.getElementById("three").value = "";
    // document.getElementById("four").value = "";
   }

  const changeHandler = (e: any) => {
    console.log("targe",[e.target.name])
    setAllValues({...allValues,"recordStatus":"Active", "bloomJobRole":"Teacher", [e.target.name]: e.target.value,"employeeId": empRef.current.value})
}
const handleUpdateInChild = (e: any, allValues: any) => {
  console.log("from handle update in child method", empRef.current.value)
  handleUpdate(e,allValues)
}

const resetState = () => setAllValues("")

useImperativeHandle( ref, () => {
    return {
        resetState: resetState
    };
  });
    return (
      <>
        <Modal show={modalState} onHide={() => handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Job Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mrgnbtm row">
                <div className="col-md-4">
                    Bloom Job Role:
                </div>
                <div className="col-md-8">
                   <select className="form-select" aria-label="Default select example" id="bloomJobRole" defaultValue={editJobRole.bloomJobRole} onChange={changeHandler} name="bloomJobRole">
                        <option value="Teacher">Teacher</option>
                        <option value="Parent">Parent</option>
                        <option value="Student">Student</option>
                    </select>
                </div>
            </div>
            <div className="mrgnbtm row">
                <div className="col-md-4">
                    Record Status:
                </div>
                <div className="col-md-8">
                    <select className="form-select" aria-label="Default select example" defaultValue={editJobRole.recordStatus} onChange={changeHandler} name="recordStatus">
                        <option value="Active" >Active</option>
                        <option value="InActive">InActive</option>
                    </select>
                </div>
            </div>
            <div className="mrgnbtm row">
                <div className="col-md-4">
                    Client Job Role Description:
                </div>
                <div className="col-md-8">
                    <input type="text" onChange={changeHandler} id="one" defaultValue={editJobRole.districtJobRole} name="districtJobRole"></input>
                </div>
            </div>
            <div className="mrgnbtm row">    
                <div className="col-md-4">
                   First Name:
                </div>
                <div className="col-md-8">
                    <input type="text" onChange={changeHandler} id="two" defaultValue={editJobRole.firstName} name="firstName"></input>
                </div>
             </div> 
             <div className="mrgnbtm row">  
                <div className="col-md-4">
                   Last Name:
                </div>
                <div className="col-md-8">
                    <input type="text" onChange={changeHandler} id="three" defaultValue={editJobRole.lastName} name="lastName"></input>
                </div>
              </div>
              <div className="mrgnbtm row">  
                <div className="col-md-4">
                   Employee Id:
                </div>
                <div className="col-md-8">
                    <input type="text" onChange={changeHandler} id="four" ref={empRef} defaultValue={editJobRole.employeeId} name="employeeId"></input>
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClear}>
              Reset
            </Button>
            {
        editJobRole !== ""?
            <Button variant="info" onClick={(e) => {handleUpdateInChild(e,allValues)}}>
              Update
            </Button> :
            <Button variant="info" onClick={(e) => {handleUpdate(e,allValues)}}>
              Create
            </Button>
            }
          </Modal.Footer>
        </Modal>
      </>
    );
  }
)
  export default BloomModal