import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { JobRoleService } from '../../../services'

const initialState = {
  JobRoleList: [],
  status: 'idle',
  error: null
}
export const addOrUpdateJobRole: any = createAsyncThunk('jobrole/addOrUpdateJobRole',async(data) => {
  const jobRoleService = new JobRoleService();
  const response = await jobRoleService.postJobRoleData(data);
  return response;
})

export const checkForJobRoleInformation: any = createAsyncThunk('jobRole/checkForJobRoleInformation', async (data) => {
  const jobRoleService = new JobRoleService();
  const response = await jobRoleService.jobRoleFetch(data);
  return response
})

export const deleteJobRoleInfo: any = createAsyncThunk('jobRole/deleteJobRoleInfo', async (data) => {
  const jobRoleService = new JobRoleService();
  const response = await jobRoleService.deleteJobRoleData(data);
  return response
})

export const jobRoleSlice = createSlice({
    name: 'JobRole',
    initialState,
    reducers: {},
    extraReducers: {
      [checkForJobRoleInformation.pending]: (state, action) => {
        console.log('action payload in extraReducers!!!', action.payload)
        state.status = 'loading'
      },
      [checkForJobRoleInformation.fulfilled]: (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        console.log('action payload in extraReducers!!!', action.payload)
        state.JobRoleList = action.payload
      },
      [checkForJobRoleInformation.rejected]: (state, action) => {
        console.log('action payload in extraReducers!!!', action.payload)
        state.status = 'failed'
        state.error = action.error.message
      },
      [addOrUpdateJobRole.fulfilled]: (state,action) => {
        state.status = 'Added successfully'
        // state.posts = 
      },
      [addOrUpdateJobRole.rejected]: (state,action) => {
        state.status = 'Failed to add'
        state.error = action.error.message 
      },
      [deleteJobRoleInfo.fulfilled]: (state,action) => {
        state.status = 'Updated successfully'
        // state.posts = 
      },
      [deleteJobRoleInfo.rejected]: (state,action) => {
        state.status = 'Failed to Update'
        state.error = action.error.message 
      },
    }
  })
  
  export default jobRoleSlice.reducer