// my classes icons
import SortDown from './MyClasses/SortDownLogo.svg';
import SortUp from './MyClasses/SortUpLogo.svg';
import Edit from './MyClasses/edit.svg';
import ChangePassword from './MyClasses/changePassword.svg';
import Impersonate from './MyClasses/impersonate.svg';
import User from './MyClasses/user.svg';

// my classes icons
export const SortDownLogo = SortDown
export const SortUpLogo = SortUp
export const EditLogo = Edit
export const ChangePasswordLogo = ChangePassword
export const ImpersonateLogo = Impersonate
export const UserLogo = User
