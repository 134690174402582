import AdminLayout from '../layouts/AdminLayout';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeActivePage } from '../redux/features/user/user.slice';
import AppContext from '../store/AppContext';
import { Reports } from '../components/reports/Reports';

function ReportsContainer() {
  const dispatch = useDispatch()
  const appContext = useContext(AppContext);
  const adminInfo= appContext?.userDetails;

  useEffect(() => {
    dispatch(changeActivePage("reports"))
  },[])


  return (
      <AdminLayout>
        <Reports />
      </AdminLayout>
    );
}

export default ReportsContainer;