import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { apiFetchArticles } from '../../redux/features/staff/staff.slice';
import { Loading } from '../../shared/Loading';
import { Close } from '@mui/icons-material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ArticlesModal(props:any) {
  const {selectedTopic, setSelectedArticle, showModal, setShowModal} = props;

  const [searchInput, setSearchInput] = useState<string>('')

  const dispatch = useDispatch()

  const articlesData = useSelector((state:any) => state?.staff?.fetchArticlesData)

  const handleClose = () => setShowModal(false);

  const fetchArtciles = () => {
    dispatch(apiFetchArticles(searchInput))
  }

useEffect(() => {
    if (selectedTopic !== "") {
        setSearchInput(selectedTopic)
        dispatch(apiFetchArticles(selectedTopic))
    }
}, [selectedTopic])

const renderArticleCard = (article: any) => {
    const { title, description, content, publishedAt, author, url, urlToImage } = article
    return (
        <Box p={2} mt={2} sx={{display:'flex', flexDirection:'column', boxShadow:'1px 1px 5px 1px #75757555', borderRadius:'8px'}}>
            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                <Typography variant='h4' color={"black"}>{title}</Typography>
                {author && <Typography variant='caption' alignSelf={'flex-end'} color={"black"}>- {author}</Typography>}
            </Box>
            <hr color={"black"}/>
            <Typography mt={1} mb={1} variant='body2' color={"black"}>{description}</Typography>
            <img style={{width:'60%', maxHeight:'400px',alignSelf:'center'}} src={urlToImage} />
            <Typography variant='subtitle1' alignSelf={'flex-end'} color={"black"} >{publishedAt?.slice(0, 10)}</Typography>
            <Typography variant='subtitle1' color={"black"} >{content}{" "}<a style={{color:'blue'}} href={url} target="_blank" >Know more</a></Typography>
            <Button variant='contained' sx={{alignSelf:'flex-end'}} color='success' onClick={() => {setSelectedArticle(article); handleClose()}}>Approve</Button>
        </Box>
    )
}

  return (
    <div>
      <Modal open={showModal} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{display:'flex'}}>
            <TextField value={searchInput} onChange={(event) => setSearchInput(event.target.value)} size="small" fullWidth label="Search Article" id="searchArticle" sx={{mr:2}}/>
            <Button onClick={fetchArtciles} size="small" variant='contained' >Search</Button>
            <IconButton style={{alignSelf:'flex-end'}} onClick={handleClose}><Close /></IconButton>
          </Box>
          <Box sx={{height:'500px', overflow:'scroll', px:2, mt:2}}>
            {
                articlesData?.status === 'loading' ? <Loading /> :
                articlesData?.data?.map((eachArticle: any) => {
                    return renderArticleCard(eachArticle)
                })
            }
          </Box>
        </Box>
      </Modal>
    </div>
  );
}