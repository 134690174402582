import React from "react"
//import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useEffect, useState, useContext } from 'react'
import { EnrollStaffTable } from './EnrollStaffTable';
// import { ProcessingImage } from '../../shared/components/ProcessingImage';
import {Loading} from '../../shared/Loading';
import AppContext from '../../store/AppContext';
import { useSelector, useDispatch } from 'react-redux'
import { saveImpersonateUserDetails } from '../../redux/features/user/user.slice'
import Modal from '@mui/material/Modal';
import { environment } from "../../config/config";
import {BsSearch} from 'react-icons/bs'
import "./staffRosterTable.css"
import {SortIcons} from '../../shared/components/SortIcons'
import { Avatar } from '@mui/material'
import {  LightTooltip } from '../../styledComponents/index'
import { EditLogo, ChangePasswordLogo, ImpersonateLogo, UserLogo } from '../../assets/images/redesignIcons'
import { useDeferredValue } from "react";

export const StaffTable = (props) => {
  // const staList = props.staffList.staffList.result?.teachers
  const staList = props.staffList.staffList
  const enrollList = props.enrollList;
  const [staffSisName, setStaffSisName] = useState('');

  console.log(staffSisName,props.staffSisName,"123")
  const [staffSisId, setStaffSisId] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [filteredListState, setFilteredListState] = useState();
   

  const [enrollModalShow, setEnrollModalShow] = useState(false);
  const handler = props.handler;
  const [open, setOpen] = useState(false);
  const handleClose = () => {setOpen(false)};

  useEffect(()=>{
    const filteredList = staList && staList.filter(({firstName,lastName,email,employeeId}) => {
      const isIncludes = value => value?.toLowerCase().includes(searchInput.trim().toLowerCase()) 
      return isIncludes(firstName)||isIncludes(lastName)||isIncludes(email)||isIncludes(employeeId)
    })
    setFilteredListState(filteredList)
  },[searchInput])

  const openEnrollModal = () => {
    setEnrollModalShow(true);
  };

  // Function to close the Enroll modal
  const closeEnrollModal = () => {
    setEnrollModalShow(false);
  };

  const uploadedDataLength = filteredListState?.length;
  // const noOfPages = staList?.length > 0 ? (uploadedDataLength + (10 - (uploadedDataLength % 10))) / 10 : 0;
  const noOfPages = filteredListState?.length && Math.ceil(uploadedDataLength/10)

  const [impersonateButtonClick, setImpersonateButtonClick] = useState(false);

  const dispatch = useDispatch()
  const impersonateUserDetails = useSelector(state => state.user.impersonateUserDetails)
  const dataSyncedAt = useSelector(state => state.staff.dataSyncedAt && new Date(state.staff.dataSyncedAt).toLocaleString())

  const appContext = useContext(AppContext);
  const adminInfo = appContext.userDetails;

  const editStaff = (e, staffEdit) => {
    // console.log("Selected Staff for edit ", staffEdit.staffEmployeeId);
    // <AddNewStaffModal modelState={true} staffEmpId={staffEmpId} handleClose={handleClose} handleUpdate={handleUpdate} />
    props.setEditStaff(staffEdit);
    props.setShow(true);
  }

  // console.log('react', staList)
  const fetchEnrolledStaff = (id, name) => {
    // console.log('react/******** fetchedEnrolledStudents');
    setStaffSisId(id)
    // console.log(id);
    props.enrolledStaff(id);
    props.staffSisName(name);
    setStaffSisName(name);
    console.log(id,name,"12312333",staffSisName)

    setOpen(true);
    // var x = document.getElementById("staffTable");
    // if (x.style.display === "none") {
    //   x.style.display = "block";
    // }
  }


  const handleImpersonate = (staffItem) => {
    const env = environment()
    const user = staffItem.email
    const admin = adminInfo.email
    const role = "T"
    const userDetails = { admin, user, role, environment:env }

    setImpersonateButtonClick(true)
    dispatch(saveImpersonateUserDetails(userDetails))
  }

  if (impersonateUserDetails.status === 'succeeded' && impersonateButtonClick) {
    window.open(
      impersonateUserDetails.data.link,
      '_blank'
    );
    setImpersonateButtonClick(false)
  }


  const onPrevClick = () => {
    setStartIndex(startIndex - 10)
    setEndIndex(endIndex - 10)
    setCurrentPage(endIndex / 10 - 1)
  }

  const onNextClick = () => {
    setStartIndex(startIndex + 10)
    setEndIndex(endIndex + 10)
    setCurrentPage(endIndex / 10 + 1)
  }

  const hideShowSearchInput = (e) => {
    if(!showSearchInput){
      setShowSearchInput(true)
    } else {
      if(!e.target.value){
        setShowSearchInput(false)
      }
    }
  }

  const sortTable = (tableArr, objKey, isAsc) => {
    let sortArr = [].concat(tableArr);
    sortArr.sort((a, b) => {
      if(isAsc){ // ascending order
          return a[objKey] > b[objKey] ? 1 : -1
      } else { // descending order
          return a[objKey] > b[objKey] ? -1 : 1
      }
     })
     setFilteredListState([].concat(sortArr))
  }

  const tableSortClick = (e, tableArr, objKey) => {
    let isAsc = false;
    if(e?.target?.className === 'sortUpLogo'){
      isAsc = true;
      e.target.nextElementSibling.style.opacity = '60%'
    } else {
      isAsc = false;
      e.target.previousElementSibling.style.opacity = '60%'
    }
    e.target.style.opacity = "30%";
    sortTable(tableArr, objKey, isAsc)
  }

  return (
    <>
      {staList && staList.length === 0 ? (
        <div className="text-center ">STAFF RECORDS ARE EMPTY. PLEASE UPLOAD.</div>
      ) : (
        <>
          {/* {dataSyncedAt && <p className="text-center">Last successful upload {dataSyncedAt}</p>} */}
          <div>
            {/* {filteredListState?.length === 0 ? 
              (
                <p className="mb-0">0 of 0 Pages 0 - 0 Records</p>
              ):
              (
                  <p className="mb-0">
                  {currentPage} of {noOfPages} Pages {startIndex + 1} -{" "}
                  {startIndex + 10 > uploadedDataLength ? uploadedDataLength : endIndex} Records
                  </p>
              )
            } */}
            <div className="d-flex align-items-end mb-2 staffRosterHeader">
              <span>Staff Roster</span>
            {/* <button
              disabled={startIndex === 0}
              className='navigation-btns'
              onClick={() => onPrevClick()}
            >
              Previous
            </button>
            <button
              disabled={startIndex + 10 >= uploadedDataLength}
              className='navigation-btns'
              onClick={() => onNextClick()}
            >
              Next
            </button> */}

                  <div className="d-flex align-items-center">
                    { dataSyncedAt && <span className="lastUploadedMsg text-center">Last successful upload {dataSyncedAt}</span>}
                    {/* <button id="addNewStudentBtnId" className="addNewStudentBtn" 
                        onClick={(e) => { e.currentTarget.blur(); console.log("elcike"); return handler(true)}}>
                          Add New Staff
                    </button> */}
                    {showSearchInput ? 
                        <div className="staffSearchDiv">
                            <input
                              value={searchInput}
                              type="search"
                              className="w-100"
                              style={{ outline:"none"}}
                              placeholder="Search"
                              onChange={(e) => {
                                setSearchInput(e.target.value);
                                setStartIndex(0);
                                setEndIndex(10);
                                setCurrentPage(1)
                              }}
                              onBlur={(e) => {hideShowSearchInput(e)}}
                            />
                            {searchInput.length === 0 && <BsSearch id="staffSearchInputSvgIcon" />}
                        </div> 
                        :
                        <BsSearch id="staffSearchSvgIcon" onClick={(e) => {hideShowSearchInput(e)}}/>
                     }
                  </div>                
          </div>  
          </div>
          {filteredListState?.length === 0 ?
           (<div className="text-center" style={{marginTop:"15%"}}>No Records Found</div>):
           (<div className="horizontalScrollBar">
           <table className='ng-table upload-data-table'>
             <thead>
               <tr>
                  <th style={{ width : "3%", fontSize: "12px" }}>#</th>
                  <th style={{ width : "13%", fontSize: "12px" }}>
                    <div className="wrapperDiv">
                      <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "email")}} />
                      <div>Staff Email Id</div>
                    </div>
                  </th>
                  <th style={{ width : "13%", fontSize: "12px" }}>
                    <div className="wrapperDiv">
                      <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "employeeId")}} />
                      <div>Staff Employee Id</div>
                    </div>
                  </th>
                  <th style={{ width : "13%", fontSize: "12px" }}>
                    <div className="wrapperDiv">
                      <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "firstName")}} />
                      <div>Staff First Name</div>
                    </div>
                  </th>
                  <th style={{ width : "13%", fontSize: "12px" }}>
                    <div className="wrapperDiv">
                      <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "lastName")}} />
                      <div>Staff Last Name</div>
                    </div>
                  </th>
                  <th style={{ width : "13%", fontSize: "12px" }}>
                    <div className="wrapperDiv">
                      <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "schoolId")}} />
                      <div>Staff School Id</div>
                    </div>
                  </th>
                  <th style={{ width : "13%", fontSize: "12px" }}>
                    <div className="wrapperDiv">
                      <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "status")}} />
                      <div>Staff Status</div>
                    </div>
                  </th>
                  <th style={{ width : "19%", fontSize: "12px" }}>
                    <div className="wrapperDiv justify-content-start">
                      {/* <SortIcons upOpacity="60%" downOpacity="30%" clickEventHandler={e => {tableSortClick(e, filteredListState, "")}} /> */}
                      <div>Actions</div>
                    </div>
                  </th>
               </tr>
             </thead>
             <tbody>
               {
                 filteredListState?.map((staffItem, i) => {
                  //  if (i < startIndex || i >= endIndex) {
                  //    return
                  //  }
                  //  if (i < startIndex || i >= endIndex) {
                  //    return
                  //  }
                   return (
                     <tr key={staffItem._id}>
                       <td style={{ width : "3%", fontSize: "12px" }}>{(i = i + 1)}</td>
                       <td style={{ paddingLeft : "23px", width : "13%", fontSize: "12px" }}>{staffItem?.email}</td>
                       <td style={{ paddingLeft : "23px", width : "13%", fontSize: "12px" }}>{staffItem?.employeeId}</td>
                       <td style={{ paddingLeft : "23px", width : "13%", fontSize: "12px" }}>{staffItem?.firstName}</td>
                       <td style={{ paddingLeft : "23px", width : "13%", fontSize: "12px" }}>{staffItem?.lastName}</td>
                       <td style={{ paddingLeft : "23px", width : "13%", fontSize: "12px" }}>{staffItem?.schoolId}</td>
                       <td style={{ paddingLeft : "23px", width : "13%", fontSize: "12px" }}>{staffItem?.status}</td>
                       <td style={{ width : "19%", fontSize: "12px" }} className='staffLastColumn'>
                         <div className="d-flex">
                         {/* <LightTooltip title="Edit" placement="bottom">
                              <Avatar
                                onClick={e => { e.currentTarget.blur(); editStaff(e, staffItem)}}
                                sx={{ backgroundColor: "#ffffff", border: "1px solid #e6e8ee", marginRight: "10px", cursor:'pointer' }}>
                                <img src={EditLogo} style={{ width: '13px' }} />
                              </Avatar>
                            </LightTooltip> */}

                            <LightTooltip title="Enroll" placement="bottom">
                              <Avatar
                                onClick={e => { e.currentTarget.blur(); fetchEnrolledStaff(staffItem.userId, staffItem.firstName)}}
                                sx={{ backgroundColor: "#ffffff", border: "1px solid #e6e8ee", marginRight: "10px", cursor:'pointer' }}>
                                <img src={UserLogo} style={{ width: '13px' }} />
                              </Avatar>
                            </LightTooltip>

                            <LightTooltip title="Impersonate" placement="bottom">
                              <Avatar
                                onClick={e => { e.currentTarget.blur(); handleImpersonate(staffItem)}}
                                sx={{ backgroundColor: "#ffffff", border: "1px solid #e6e8ee", marginRight: "10px", cursor:'pointer' }}>
                                <img src={ImpersonateLogo} style={{ width: '13px' }} />
                              </Avatar>
                            </LightTooltip>

                            <LightTooltip title="Change Password" placement="bottom">
                              <Avatar
                                onClick={e => { e.currentTarget.blur(); props.setEditStaffPassword(staffItem)}}
                                sx={{ backgroundColor: "#ffffff", border: "1px solid #e6e8ee", marginRight: "10px", cursor:'pointer' }}>
                                <img src={ChangePasswordLogo} style={{ width: '13px' }} />
                              </Avatar>
                            </LightTooltip>
                         </div>
                       </td>
                     </tr>
                   )
                 })}
             </tbody>
           </table>
         </div>)
          }
        </>   
      )}

     <Modal
        open={open}
        style={{width:"100vw",height:"100vh",padding:"2%",borderRadius:10}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       <div style={{width: '96vw',height: '90vh',backgroundColor:"white",outline:0,overflow:"auto",borderRadius:10, }}>
        {enrollList.status === "loading" ? (
          <div style={{ height: "510px" }}>
          <Loading />
        </div>
        ) : (
          <EnrollStaffTable
            enrollList={enrollList}
            staffSisName={staffSisName}
            handleClose={handleClose}
          />
        )}
        </div>
      </Modal>
    </>
  )
}
