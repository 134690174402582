import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import AppContext from '../../store/AppContext';
import "./Home.css"
import { SortIcons } from '../../shared/components/SortIcons'
import { useDispatch, useSelector } from 'react-redux';
import { changeStatus, changeSyncStatus, syncRosterData } from '../../redux/features/integration/integration.slice';




export const Home = (integrationList: any) => {

    const dispatch = useDispatch()
    const appContext = useContext(AppContext);
    const adminInfo = appContext?.userDetails;
    const oneRosterData = useSelector((state: any) => state?.integration?.oneRosterDataDetails?.status)
    const syncRoster=useSelector((state:any)=>state?.integration?.syncOneRosterDetails?.status)

    useEffect(() => {
        if (oneRosterData === "succeeded") {

            toast("OneRoster Data Created Successfully", { type: toast.TYPE.SUCCESS })
            dispatch(changeStatus())
        
        }

    }, [oneRosterData])

    const integrationname = integrationList.integrationList.integrationList
    const handleButtonClick=(each:any)=>{

            dispatch(syncRosterData(adminInfo._id))
        }

    useEffect(()=>{
            if(syncRoster==="succeeded"){
                toast("Sync successfully",{type: toast.TYPE.SUCCESS})
                dispatch(changeSyncStatus())
        }
    },[syncRoster])


    return (
        <div>
            <div className="d-flex align-items-end mb-2 homeHeader">
                <span>Home</span>
            </div>
            <table className="ng-table upload-data-table" style={{ tableLayout: 'fixed' }}>
                <thead>
                    <tr>
                        <th style={{ width: "20%", fontSize: "12px",paddingLeft:"2%"}}>
                            <div className="wrapperDiv">
                                <SortIcons upOpacity="60%" downOpacity="30%" />
                                <div >Name</div>
                            </div>
                        </th>
                        <th style={{ width: "35%", fontSize: "12px" }}>
                            <div className="wrapperDiv">
                                <SortIcons upOpacity="60%" downOpacity="30%" />
                                <div>oAuth Key</div>
                            </div>
                        </th>
                        <th style={{ width: "35%", fontSize: "12px" }}>
                            <div className="wrapperDiv">
                                <SortIcons upOpacity="60%" downOpacity="30%" />
                                <div>oAuth Key</div>
                            </div>
                        </th>
                        <th style={{ width: "10%", fontSize: "12px" }}>
                            <div className="wrapperDiv">
                                <SortIcons upOpacity="60%" downOpacity="30%" />
                                <div>Actions</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
               { integrationname.map((each:any) => {
                
                return(
                     <tr className="p-1">
                     <td style={{ fontSize: "12px",textAlign: "left",paddingLeft:40 }}>{each.name}</td>
                     <td style={{ paddingLeft: "23px", width: "50%", fontSize: "12px" }}>{each.oAuthKey}</td>
                     <td style={{ paddingLeft: "23px", width: "50%", fontSize: "12px" }}>{each.oAuthSecret}</td>
                     <td style={{ paddingLeft: "23px", width: "50%", fontSize: "12px" }}>
                        <button 
                        onClick={() => handleButtonClick(each)}
                        style={{
                                 backgroundColor: ' #f5f8fc',
                                 border: ' 1px solid rgba(167, 167, 167, 0.8)',
     
                                }}>sync</button></td>
                 </tr>)
                })}
                    
                </tbody>
            </table>
        </div>
       
    )
}