import auth from './AuthService'
import { BaseService } from './base.service';

export class SchoolService extends BaseService {

    public async schoolFetch(data: any) {
        const schoolInfo = auth.getAdminInfo()
        const url = `/async-svc/schoolMappingList/${schoolInfo.users.districtId}`
        return await this.getData(url);
    }
    
    public async updateSchoolData(data: any) {
        const url = "/async-svc/school-mapping-update"
        return await this.updateInfo(url, data);
    }
}
