import React from 'react';
//import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

export const SecurityPolicies = (props) => {
    
    const policyList = props.securityList.securityList.result
    console.log('react',policyList)
    const editSecurity = (e, securityPoliciesData) => {
        console.log("editSecurity ", securityPoliciesData);
       
        props.setEditSecurity(securityPoliciesData);
        props.setShow(true); 
    }
    // const test =() => {
    //     console.log("prem")
    // }
    return(
        <div className="horizontalScrollBar"> 
            <table className="ng-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>USER ID GENERATION OPTION</th>
                        <th>USER PASSWORD GENERATION OPTION</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        policyList && policyList.map((policyItem,i)=>{
                            return(
                    <tr>
                        <td>{i=i+1}</td>
                        <td>{policyItem.userIdGenerationOption.staffFirstInitialPlusLastName?"Staff First Initial + Last Name":""}</td>
                        <td>{policyItem.passwordGenerationOption.randomGenerationCharacterAllowedInPassword?"Random Generation Character Allowed":""}
                            {policyItem.passwordGenerationOption.randomGenerationNumbersAllowedInPassword?"Random Generation Number Allowed":""}
                            {policyItem.passwordGenerationOption.randomGenerationSymboldAllowedInPassword?"Random Generation Symbold Allowed":""}</td>
                        
                        <td><Button className="generic-btn"  variant="success" onClick={e=> editSecurity(e,policyItem)}>Edit</Button></td> 
                            {/* <td><Button className="generic-btn"  variant="success" onClick={test}>Edit</Button></td>  */}
                            
                    </tr>
                            )
                        })
    }
                </tbody>
            </table>
        </div>
    )
}