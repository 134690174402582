import AdminLayout from '../layouts/AdminLayout';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeActivePage } from '../redux/features/user/user.slice';
import AppContext from '../store/AppContext';
import { Admin } from '../components/admin/Admin';
import { getEmailsList } from '../redux/features/staff/staff.slice';

function AdminContainer() {
  const dispatch = useDispatch()
  const appContext = useContext(AppContext);
  const adminInfo= appContext?.userDetails;

  useEffect(() => {
    dispatch(changeActivePage("admin"))
    dispatch(getEmailsList())
  },[])


  return (
      <AdminLayout>
        <Admin />
      </AdminLayout>
    );
}

export default AdminContainer;