import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AppContext from "./store/AppContext";
import _ from 'underscore'
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/global.css";
import "./App.css";
import "./assets/css/login.css";
import "./assets/css/global.css";


import LoginContainer from "./containers/LoginContainer";
import HomeContainer from "./containers/HomeContainer";
import IntegrationContainer from "./containers/IntegrationContainer";
import DistrictParametersContainer from "./containers/DistrictParametersContainer";
import SchoolMappingContainer from "./containers/SchoolMappingContainer";
import JobRoleMappingContainer from "./containers/JobRoleMappingContainer";
import PolicyContainer from "./containers/PolicyContainer";
import StaffRoasterContainer from "./containers/StaffRosterContainer";
import StudentRosterContainer from "./containers/StudentRosterContainer";
import ResetPassword from "./components/login/ResetPassword";
import ChangePassword from './components/login/ChangePassword'
import IntegrationConsoleContainer from "./containers/IntegrationConsoleContainer";
import AdminContainer from "./containers/AdminContainer";
import ReportsContainer from "./containers/ReportsContainer";
import ArticlesContainer from "./containers/ArticlesContainer";

function App() {
  const appContext = useContext(AppContext);

  const userDetails = appContext?.userDetails
  if(userDetails?.status === undefined) {
    return (
      <Routes>
        <Route path="/" element={<LoginContainer />} />
        <Route path="/change-password" element={<ChangePassword />} />
      </Routes>
    )
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginContainer />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/:districtId/dashboard" element={<HomeContainer />} />
        <Route path="/:districtId/integration" element={<IntegrationConsoleContainer />} />
        <Route path="/:districtId/staff" element={<StaffRoasterContainer />} />
        <Route path="/:districtId/student" element={<StudentRosterContainer />} />
        <Route path="/:districtId/policies/security-policies" element={<PolicyContainer />} />
        <Route path="/:districtId/settings/district-parameters" element={<DistrictParametersContainer />} />
        <Route path="/:districtId/settings/school-mapping" element={<SchoolMappingContainer />} />
        <Route path="/:districtId/settings/jobrole-mapping" element={<JobRoleMappingContainer />} />
        <Route path="/:districtId/admin" element={<AdminContainer />} />
        <Route path="/:districtId/reports" element={<ReportsContainer />} />
        <Route path="/:districtId/articles" element={<ArticlesContainer />} />
      </Routes>
    </div>
  );
}

export default App;