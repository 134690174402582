import React from 'react';
import { SortUpLogo, SortDownLogo } from '../../assets/images/redesignIcons'

export const SortIcons = ({upOpacity, downOpacity, clickEventHandler}: any) => {
    const upOpacityVal = upOpacity ? upOpacity : '60%';
    const downOpacityVal = downOpacity ? downOpacity : '60%';
    
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "0px" }}>
            <img src={SortUpLogo} style={{ height: "10px", opacity: upOpacityVal }} className="sortUpLogo" alt="sortUpIcon" onClick={clickEventHandler} />
            <img src={SortDownLogo} style={{ height: "10px", opacity: downOpacityVal }} className="sortDownLogo" alt="sortDownIcon" onClick={clickEventHandler}/>
        </div>
    )
}