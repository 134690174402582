import { environment } from '../config/config';
import { BaseService } from './base.service';

export class LoginService extends BaseService {

    public async userLogin(data: any) {
        const env=environment()
        const url = `/async-svc/user/admin/login`
        return await this.postData(url ,{user: data,env});
    }

    public async userSignUp(data: any) {
        const url = `/async-svc/user/admin`
        return await this.postData(url ,data);
    }
    
    public async saveImpersonateDetails(data: any){
        const url = `/core-svc/auth/save/impersonate/user`
        return await this.postData(url, data);
    }
    
}
