import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SchoolService } from '../../../services'

const initialState = {
  schoolList: [],
  status: 'idle',
  error: null
}
export const addOrUpdateSchool: any = createAsyncThunk('school/addOrUpdateschoolInfo',async(data) => {
  const schoolService = new SchoolService();
  const response = await schoolService.updateSchoolData(data);
  return response;
})
export const checkForSchoolInformation: any = createAsyncThunk('school/checkForSchoolInformation', async (data) => {
  const schoolService = new SchoolService();
  const response = await schoolService.schoolFetch(data);
  return response
})


export const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {},
  extraReducers: {
    [checkForSchoolInformation.pending]: (state, action) => {
      console.log('action payload in extraReducers!!!', action.payload)
      state.status = 'loading'
    },
    [checkForSchoolInformation.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      console.log('schoolslice-action payload in extraReducers!!!', action.payload)
      state.schoolList = action.payload
    },
    [checkForSchoolInformation.rejected]: (state, action) => {
      console.log('action payload in extraReducers!!!', action.payload)
      state.status = 'failed'
      state.error = action.error.message
    },
    [addOrUpdateSchool.fulfilled]: (state,action) => {
      state.status = 'Added successfully'
    },
    [addOrUpdateSchool.rejected]: (state,action) => {
      state.status = 'Failed to add'
      state.error = action.error.message 
    }   
  }
})

export default schoolSlice.reducer
