import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box,Button} from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";



const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'student_lastName', headerName: 'student_lastName', width: 130 },
    { field: 'student_firstName', headerName: 'student_firstName', width: 130 },
    { field: 'student_status', headerName: 'student_status', width: 130 },
    { field: 'student_current_grd_level', headerName: 'student_current_grd_level', width: 130 },
    { field: 'student_school_id', headerName: 'student_school_id', width: 130 },
    { field: 'student_sis_id', headerName: 'student_sis_id', width: 130 },
    { field: 'student_user_id', headerName: 'student_user_id', width: 130 },
    { field: 'student_user_password', headerName: 'student_user_password', width: 130 },
    {
      field: 'student_actively_enrolled',
      headerName: 'student_actively_enrolled',
      type: 'number',
      width: 130,
    },
    {
        field: 'student_email',
        headerName: 'student_email',
        type: 'number',
        width: 130,
      },
      { field: 'course_id', headerName: 'course_id', width: 130 },
    { field: 'course_external_id', headerName: 'course_external_id', width: 130 },
    { field: 'teacher_employee_id', headerName: 'teacher_employee_id', width: 130 },
    { field: 'guardian1_lastName', headerName: 'guardian1_lastName', width: 130 },
    { field: 'guardian1_firstName', headerName: 'guardian1_firstName', width: 130 },
    { field: 'parent_email', headerName: 'parent_email', width: 130 },
    
  ];


export const SelectdStudent = (props:any) => {
    const {tableData} = props
    return(
        <>
           <Box sx={{borderRadius: '20px',width:'95%'}}>
         <Box  sx={{display:"flex",alignItems:"center",flexDirection:"row",marginBottom:3}}>
         <Box sx={{marginLeft:5}} id="backToStudentCSVContainer"
          onClick={() => props.onCSVNavigate('studentCSV')}
          >
        <img src={backArrow} height="40px" />
        </Box>
        <h1 style={{color: '#1e1e1e', fontWeight: 500,  textAlign: 'center', fontSize:'22px',marginLeft:600}}>Student CSV</h1>

         </Box>
     <Box>
            <DataGrid
                rows={tableData}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 10,
                    },
                },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
            />
            </Box>
            <div style={{textAlign: 'center', marginTop: '10vh'}}>
            <Button 
            onClick={() => props.onCSVNavigate('createIds')}
             style={{padding: '8px 80px'}}variant="contained" id="nextButtonStudentCSV">Next</Button>
        </div>
        </Box>
        </>
    )
}