import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneRosterData, integratedFlowChanges } from '../../redux/features/integration/integration.slice';
import { useContext } from 'react';
import AppContext from "../../store/AppContext";
import { toast} from 'react-toastify';

export default function SyncSchedule({ onOneRosterNavigate }: any) {
  const dispatch = useDispatch()

  const [date, SetDate] = useState('');
  const [time, SetTime] = useState('');
  const [selection, setSelection] = useState<any>({ duration: 'Monthly' })

  const Data = useSelector((state: any) => state?.integration?.integratedFlowProps)
  const appContext = useContext(AppContext);
  const adminInfo = appContext?.userDetails;

  const Enrollment = styled(Paper)(({ theme }) => ({
    width: '100px',
    height: '30px',
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
    marginRight: '3%',
    cursor: 'pointer'
  }));
  function onEditDate(value: any) {
    let date = new Date(value)
    let month = (date.getMonth() + 1).toString(); // Add 1 because months are zero-based
    let day = date.getDate().toString();
    let year = date.getFullYear().toString();
    month = month.length === 1 ? "0" + month : month;
    day = day.length === 1 ? "0" + day : day;
    let formattedDateStr = month + "/" + day + "/" + year;
    console.log(formattedDateStr, "121212121212", value)
    SetDate(formattedDateStr)

  }

  function onSaveDate(value: any) {
    const parts = value.split('/');
    if (parts.length === 3) {
      const month = parts[0];
      const day = parts[1];
      const year = parts[2];
      // Create the desired date string in "YYYY-MM-DD" format
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    }
  }

  function onEditTime(value: any) {
    const [hours, minutes] = value.split(':');
    const hoursNum = parseInt(hours);
    const period = hoursNum >= 12 ? 'PM' : 'AM';
    const hours12 = hoursNum % 12 || 12;
    const time12 = `${hours12}:${minutes} ${period}`;
    SetTime(time12)
  }


  function onsaveTime(value: any) {
    const [time, modifier] = value.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    return `${hours}:${minutes}`;
  }


  const onSavePassword = (): any => {
    
    let a: any = {}
    if ((Data.staffPasswordPolicy !== undefined&&(Data?.studentPassword !== undefined))) {
      a = {
        studentPasswordPolicy: Data?.studentPasswordPolicy,
        studentPassword: Data?.studentPassword,
        staffPasswordPolicy: Data.staffPasswordPolicy,
        staffPassword: Data?.staffPassword
      }
    } else if (Data?.studentPassword !== undefined) {
      a = {
        studentPassword: (Data?.studentPassword),
        studentPasswordPolicy: (Data?.studentPasswordPolicy),
      }
    } else {
      a = {
        staffPasswordPolicy: Data.staffPasswordPolicy,
        staffPassword: Data?.staffPassword,
      }
    }
    return a
  }

  function onBtnClick() {
    if (date !== "" && time !== "") {
      dispatch(integratedFlowChanges({
        'syncSchedule': {
          "startDate": date,
          "startTime": time,
          "occurence": selection
        }
      }))
      dispatch(fetchOneRosterData(
        {
          oneRosterBaseUrl: Data?.apiEndpoint,
          clientId: Data?.oAuthkey,
          clientSecret: Data?.oAuthSecret,
          createdBy: adminInfo._id,
          integrationName: Data?.integrationName,
          districtId: adminInfo?.districtId,
          schools: Data.schools,
          loginInfo: {
            type: Data.type,
            userIdType: Data.userIdType,
            ...onSavePassword(),
           
            forceStaffToChangePassword: Data.forceStaffToChangePassword,
            forceStudentToChangePassword: Data.forceStudentToChangePassword,
          },
          syncSchedule: {
            startDate: date,
            startTime: time,
            occurence: selection
          }
        }
      ))
      onOneRosterNavigate('completeFlow')
    }else{
      toast("Select date/time to sync data",{type: toast.TYPE.ERROR})
    }
  }
  return (
    <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '520px', width: '1044px', borderRadius: '20px' }}>
      <h1 style={{ color: '#1e1e1e', fontWeight: 500, marginTop: '50px', marginBottom: '4vh', textAlign: 'center', fontSize: '22px' }}>Schedule</h1>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '300px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ paddingRight: '5px' }}>
              <label style={{ paddingBottom: '3px', color: '#333333' }} id='schedule-date-label'>Start Date</label>
              <div>
                <input
                  id="schedule-date-input"
                  type="date"
                  placeholder="mm/dd/yyyy"
                  value={onSaveDate(date)}
                  style={{ width: "185px", height: '35px', border: '1px solid #f0f2f6', borderLeft: '0px #fff', padding: '10px', cursor: "pointer" }}
                  onChange={(e: any) => onEditDate(e.target.value)}
                />
              </div>
            </Box>
            <Box>
              <label style={{ paddingBottom: '3px', color: '#333333' }} id="schedule-time-label">Time</label>
              <div>
                <input
                  id="schedule-time-input"
                  type="time"
                  value={onsaveTime(time)}
                  style={{ width: "115px", height: '35px', border: '1px solid #f0f2f6', borderLeft: '0px #fff', padding: '10px', cursor: "pointer" }}
                  onChange={(e: any) => onEditTime(e.target.value)}
                />
              </div>
            </Box>

          </Box>
          <label style={{ paddingBottom: '10px', color: '#333333', marginTop: '20px' }} id="scheduleRepeatLabel">Repeat</label>
          <Stack direction="row" spacing={1}>
            <Enrollment onClick={(e) => setSelection({ duration: 'Daily' })} style={{ display: 'flex', flexDirection: 'column', fontSize: '10px', justifyContent: 'center', textAlign: 'center', backgroundColor: selection.duration == 'Daily' ? 'rgba(49, 102, 186, 1)' : '#ffffff', color: selection.duration == 'Daily' ? '#ffffff' : '#1e1e1e' }} square={false} id="dailyScheduleButton">Daily</Enrollment>
            <Enrollment onClick={(e) => setSelection({ duration: 'Weekly' })} style={{ display: 'flex', flexDirection: 'column', fontSize: '10px', justifyContent: 'center', textAlign: 'center', backgroundColor: selection.duration == 'Weekly' ? 'rgba(49, 102, 186, 1)' : '#ffffff', color: selection.duration == 'Weekly' ? '#ffffff' : '#1e1e1e' }} square={false} id='weeklyScheduleButton'>Weekly</Enrollment>
            <Enrollment onClick={(e) => setSelection({ duration: 'Monthly' })} style={{ display: 'flex', flexDirection: 'column', fontSize: '10px', justifyContent: 'center', textAlign: 'center', backgroundColor: selection.duration == 'Monthly' ? 'rgba(49, 102, 186, 1)' : '#ffffff', color: selection.duration == 'Monthly' ? '#ffffff' : '#1e1e1e' }} square={false} id="monthlyScheduleButton">Monthly</Enrollment>
          </Stack>

        </Box>
      </Box>
      <div style={{ textAlign: 'center', marginTop: '15vh' }}>
        <Button onClick={onBtnClick} style={{ padding: '8px 110px', textTransform: 'none' }} variant="contained" id='saveButtonForSchedule'>Save</Button>
      </div>

    </Box>
  );
}