import { useState, useEffect } from 'react';
import AdminLayout from '../layouts/AdminLayout';
import { DistrictSettings } from '../components/district-settings/DistrictSettings';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { checkForDistrictInformation, addOrUpdateDistrict } from '../redux/features/districtParameters/district.slice'
import {ProcessingImage} from '../shared/components/ProcessingImage'
import { changeActivePage } from '../redux/features/user/user.slice';
function DistrictParametersContainer() {
  
  const districtList = useSelector((state: any) => state.district)
  console.log('district::',districtList)
  const dispatch = useDispatch()

  const [editSecurity, setEditSecurity] = useState(false);
  

  const handleUpdate = (e: any, districtParameter: any) => {
    e.preventDefault();
    let districtId=districtParameter.districtId;
    let districtName=districtParameter.districtName;
    let configuration=districtParameter.configuration;
    let status=districtParameter.status;
    let action=districtParameter.action;
    dispatch(addOrUpdateDistrict({
      "districtParameter":
      {
        "districtId": districtId,
        "districtName": districtName,
        "configuration": configuration,
        "status": status,
        "action": action
      }
    }))
    if(districtList.districtList.statusCode === 200){
      setEditSecurity(true)
    } 
  }
 
  
  useEffect(() => {
    dispatch(checkForDistrictInformation())
    if(editSecurity)
    setEditSecurity(false)
    
  }, [dispatch,editSecurity])

  useEffect(() => {
    dispatch(changeActivePage("settings"))
  }, [])

  return (
      <AdminLayout>
        <div className="container mrgntop">
          {
            districtList.status==="loading"?
            <ProcessingImage isLoading={true}/> :
            <DistrictSettings districtList={districtList} handleUpdate={handleUpdate}  />
          }
        </div>
      </AdminLayout>
    );
}

export default DistrictParametersContainer;