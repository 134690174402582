import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../store/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../shared/Loading';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { createAccount, getReports, resetCreateAccountDetails } from '../../redux/features/staff/staff.slice';
import { DateRangePickerComponent } from '../date-range-picker/DateRangePicker';
import dayjs from 'dayjs';
import ReportedContent from './ReportedContent';
import { toast } from 'react-toastify';

export const Reports = () => {
    const [selectedType, setSelectedType] = useState('ACTIVE_ACCOUNT')

    const [startDateValue, setStartDateValue] = useState<any>(null);
    const [endDateValue, setEndDateValue] = useState<any>(null);
    
    const dispatch = useDispatch()
    const appContext = useContext(AppContext);
    const adminInfo = appContext?.userDetails;

     const getReportsDetails = useSelector((state: any) => state.staff.getReportsDetails)
     const createAccountDetails = useSelector((state: any) => state.staff.createAccountDetails)
    const usersList = getReportsDetails?.data || []

    const filteredUsersList = (startDateValue && endDateValue) ? usersList.filter((eachUser: any) => {
        const userDate = Date.parse(new Date(eachUser?.createdAt).toDateString())
        const startDate = Date.parse(new Date(startDateValue).toDateString())
        const endDate = Date.parse(new Date(endDateValue).toDateString())
        if((startDate <= userDate && userDate <= endDate)){
            return eachUser
        }
    }) : usersList
    
    const datesList = usersList.filter((eachUser:any) => eachUser.createdAt).map((each:any) => each?.createdAt ? new Date(each?.createdAt) : null )
    const minDate = new Date(Math.min.apply(null, datesList))
    const minDateValue = dayjs(minDate)
    const maxDateValue = dayjs(new Date()) 


    useEffect(() => {
        setStartDateValue(null)
        setEndDateValue(null)
        dispatch(getReports(selectedType))
    }, [selectedType])

    useEffect(() => {
        if(createAccountDetails.status === 'succeeded'){
            toast("Account Created Successfully", { type: toast.TYPE.SUCCESS })
            dispatch(getReports(selectedType))
            dispatch(resetCreateAccountDetails())
        }else if(createAccountDetails.status === 'failed'){
            toast("Something went wrong", {type: toast.TYPE.ERROR})
            dispatch(resetCreateAccountDetails())
        }
    }, [createAccountDetails.status])

    const clearDateFilter = () => {
        setStartDateValue(null)
        setEndDateValue(null)
    }

    return (
        <Box p={3}>


            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <DateRangePickerComponent startDateValue={startDateValue} setStartDateValue={setStartDateValue} endDateValue={endDateValue} setEndDateValue={setEndDateValue} maxDateValue={maxDateValue} minDateValue={minDateValue} clearDateFilter={clearDateFilter}/>    
                <Typography variant='h5' color={'#000000'}>Total Users : {filteredUsersList?.filter((each:any) => !each.blockUser)?.length}</Typography>
                <FormControl sx={{ m: 1, minWidth: 200 }} size="medium">
                    <InputLabel id="demo-select-small-label">Type</InputLabel>
                    <Select fullWidth label="Type" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                        <MenuItem value='ACTIVE_ACCOUNT'>Active Account</MenuItem>
                        <MenuItem value='EVENT_SIGNUP'>Event Signup Form</MenuItem>
                        <MenuItem value='TEACHER_SIGNUP'>Teacher Signup Form</MenuItem>
                        <MenuItem value='JOIN_BETA'>Join Beta Form</MenuItem>
                        <MenuItem value='REPORTED_CONTENT'>Reported Content</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {selectedType==="REPORTED_CONTENT" ? (<ReportedContent/>):
            (<>
            {getReportsDetails?.status === 'loading' || createAccountDetails.status === 'loading' ?<Loading /> :
                <>
                    {filteredUsersList.length > 0 ? 
                    <TableContainer sx={{border:'1px solid #e6e6e6', marginTop:'10px',  maxHeight:'60vh', overflowY:'scroll'}}>
                    <Table stickyHeader sx={{ minWidth: 650 }} >
                        <TableHead>
                            <TableRow>
                                <TableCell>S.no</TableCell>
                                <TableCell >Name</TableCell>
                                <TableCell >School Email</TableCell>
                                <TableCell>Reffered By</TableCell>
                                <TableCell >Phone Number</TableCell>
                                <TableCell >School Name</TableCell>
                                {selectedType === 'JOIN_BETA' && <TableCell >Job Title</TableCell>}
                                {selectedType === 'JOIN_BETA' && <TableCell >School District</TableCell>}
                                <TableCell >Grades Taught</TableCell>
                                <TableCell >Curriculums</TableCell>
                                <TableCell >Hear about us</TableCell>
                                <TableCell >Joined Date</TableCell>
                                {(selectedType === 'EVENT_SIGNUP' || selectedType === 'TEACHER_SIGNUP') && <TableCell>Actions</TableCell>}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                        {filteredUsersList?.filter((each:any) => !each.blockUser)?.map((each: any, index: number) => 
                                    (
                                        <TableRow
                                            key={each._id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >   
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell  component="th" scope="row">{each?.firstName + " " +each?.lastName }</TableCell>
                                            <TableCell >{each?.email}</TableCell>
                                            <TableCell >{each?.referenceEmail ? each?.referenceEmail : 'NA'}</TableCell>
                                            <TableCell  width={'150px'}>{selectedType === 'JOIN_BETA' ? each?.countryDialCode+' '+each?.phoneNumber : each?.phoneNumber ? each?.phoneNumber : "NA"}</TableCell>
                                            <TableCell >{each?.schoolName ? each?.schoolName : 'NA'}</TableCell>
                                            {selectedType === 'JOIN_BETA' && <TableCell>{each?.jobTitle}</TableCell>}
                                            {selectedType === 'JOIN_BETA' && <TableCell>{each?.schoolDistrictName}</TableCell>}
                                            <TableCell >{each?.grades.length > 0 ? each?.grades.join(', ') : 'NA'}</TableCell>
                                            <TableCell >{each?.currentCurriculums ? each?.currentCurriculums : 'NA'}</TableCell>
                                            <TableCell >{each?.hearAboutUs.length > 0 ? each?.hearAboutUs.join(', ') : 'NA'}</TableCell>
                                            <TableCell >{each?.createdAt ? new Date(each?.createdAt).toLocaleString('en-US', {day:'numeric', month: 'numeric', year:'numeric'}) : 'NA'}</TableCell>
                                            {(selectedType === 'EVENT_SIGNUP' || selectedType === 'TEACHER_SIGNUP') && 
                                                <TableCell >
                                                    <Button variant='contained' onClick={() => {dispatch(createAccount(each?.email))}}>Activate Account</Button>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    )
                        )}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    :
                    <h1 style={{color:'#000000', display:'flex', justifyContent:'center', alignItems:'center', height:'50vh'}}>No Records Found</h1>}
                </>
            }
            </>)}
        </Box>
       
    )
}
