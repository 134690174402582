import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Box, Button } from '@mui/material';

export const DateRangePickerComponent = (props:any) => {

  const {startDateValue, setStartDateValue, endDateValue, setEndDateValue, maxDateValue, minDateValue, clearDateFilter} = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'640px'}}>
        <DatePicker
            label="Start"
            value={startDateValue}
            minDate={minDateValue}
            maxDate={maxDateValue}
            onChange={(newValue) => setStartDateValue(newValue)}
          />
      
        <DatePicker
            label="End"
            disabled={!startDateValue}
            value={endDateValue}
            minDate={startDateValue}
            maxDate={maxDateValue}
            onChange={(newValue) => setEndDateValue(newValue)}
          />

        <Button sx={{height:'40px'}} disabled={!startDateValue && !endDateValue} onClick={clearDateFilter} variant='contained'>Clear</Button>
      </Box>

    </LocalizationProvider>
  );
}
