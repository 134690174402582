import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

type ProcessingButtonProps = {
    id: string;
    isLoading: boolean;
    styles: string;
    btnText: string;
    onBtnClick: () => void;
  }

  const ProcessingButton = React.forwardRef<HTMLButtonElement, ProcessingButtonProps>(({id,isLoading, styles, btnText, onBtnClick}:ProcessingButtonProps, ref) => {

    return (
        <Button id={id} onClick={() => onBtnClick()} className={styles} variant="primary" ref={ref}>
            {isLoading ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                /> : '' }
            {btnText}
        </Button>
    )
})

export default ProcessingButton;