import { Card, styled,Tooltip ,tooltipClasses ,TooltipProps } from "@mui/material";
import React from "react";

export const CustomContainerCard = styled('div')((props ) => ({
  alignSelf:'center', 
  width:'100%', 
  maxWidth:'1850px'
}))

export const CustomCardHeader = styled('div')((props ) => ({
  display:'flex',
  alignItems:'center',
  justifyContent:'space-between',
  height:'70px', 
  padding:'16px',
  paddingTop:'25px'
}))

export const CustomCardTitleContainer = styled('div')((props ) => ({
  display:'flex', 
  alignItems:'center', 
  flexGrow: 1,
  // justifyContent:"space-between",
  // backgroundColor:"red",
  
})) 
export const CustomCardTitleContainer1 = styled('div')((props ) => ({
  display:'flex', 
  alignItems:'center', 
  flexGrow: 1,
  justifyContent:"space-between",
  // backgroundColor:"red",
  
})) 

export const CustomCardTitle = styled('h6')((props ) => ({
  margin: 0, 
  marginLeft:'10px', 
  fontSize:'16px', 
  fontFamily:"'HelveticaNeue-Medium', 'Helvetica Neue Medium', 'Helvetica Neue', sans-serif", 
  fontWeight:"500"
})) 

export const CustomCardActionsContainer = styled('div')((props) => ({
  width:'50px',
  display:'flex',
  alignItems:'center',
  justifyContent:'end',
  marginRight:'7px'
}))

export const CustomCardItemContainer = styled('div')((props ) => ({
    height: "calc(100% - 70px)", 
    overflowY: 'auto',
    // '&::-webkit-scrollbar': {
    //   width: '0.4em'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#F4F7FA'
    // },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `#3166ba${props.scrollbarColor+''}`
    } 
}))

export const CustomCardItem = styled('div')((props ) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems:'center',
  padding: '16px',
  cursor: 'pointer',
  borderBottom: '1px solid #f0f2f6', 
  minHeight:'60px'
}))

export const CustomTextPara = styled('p')((props ) => ({
  color: '#101626',
  fontSize:"14px",
  margin:0,
  fontFamily: "'HelveticaNeue-Medium', 'Helvetica Neue Medium', 'Helvetica Neue', sans-serif"
}))

export const CustomButton = styled('button')((props) => ({
  border: '1px solid #e4e4e4',
  width: 'fit-content',
  padding: '6px 25px',
  alignItems: 'center',
  borderRadius: '4px',
  cursor: 'pointer',
  backgroundColor: '#fff',
  // backgroundColor: (props?.backgroundColor ? props.backgroundColor : '#fff'),
  // color: props?.color ? props.color : '',
  // marginRight: '15px',
  ...props
}))

export const CustomHoverCardContainer = styled(Card)(() => ({
  '.custom-hover-container' : {
    display: 'none'
  },
  '&:hover .custom-hover-container': {
      display: 'flex',
      justifyContent: 'flex-end'
  }
}))

// left pane styling

export const CustomSubHeading = styled('p')((props) => ({
  fontSize:'13px',marginBottom:'0px',marginTop:'4px',width:'220px',cursor:"pointer"
}))
export const CustomRole = styled('p')((props ) => ({
  fontSize:'13px',marginBottom:'0px',marginTop:'4px',minWidth:"20px"
}))
export const CustomSubHeadingRoleCardContainer = styled('div')((props ) => ({
  display:'flex',flexDirection:'row', justifyContent:"space-between",width:"100%"
}))

export const CustomSubHeadingRoleMainContainer = styled('div')((props ) => ({
width:'100%',marginTop:"10px",marginBottom:'0px',borderColor:'transparent'
}))
export const CustomLeftPaneCardContainer = styled('div')((props ) => ({
  minWidth:'100%',color: '#8687a7',display:'flex',flexDirection:"row",justifyContent:'center',alignItems:'center' ,marginRight:'4px'
}))

export const CustomMainHeading = styled('p')((props ) => ({
  margin:"0px",cursor:"pointer"
}))
export const CustomHeadingIconCard = styled('div')((props ) => ({
  width:'100%',marginLeft:'10px',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',cursor: 'pointer'
}))
export const CustomLeftPaneMainContainer = styled('div')((props ) => ({
 width:'100%',color: '#8687a7',display:'flex',flexDirection:"column",justifyContent:'center',alignItems:'center',padding:"10px 15px",borderWidth:'0.01px',borderColor:'#f0f2f6',borderStyle:'solid',borderLeftWidth:'0px',maxWidth:370
}))
export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
    boxShadow: theme.shadows[10],
  //   borderColor:theme.palette.common.black,
    borderWidth:8,
    fontSize: 11,
  },
}));
