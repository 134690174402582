/*  Created by premkumar
    @usage: modal for the password change
*/
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useState, useImperativeHandle, forwardRef, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import useToast from '../../shared/components/Toast'
import { resetUpdateStaffPasswordDetails } from "../../redux/features/staff/staff.slice"
import { resetUpdateStudentPasswordDetails } from "../../redux/features/student/student.slice"

// function AddNewStaffModal({modalState, handleClose, handleUpdate, userInfo} ){
const ChangePasswordModal = forwardRef(
  ({ modalState, handleClose, handleUpdate, userInfo, teacher }: any, ref: any) => {
    // close modal if userInfo is not-found/undefined
    if (!userInfo) {
      handleClose()
    }
    const [allValues, setAllValues] = useState(userInfo)
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState(allValues)
    const [validateSuccess, setValidateSuccess] = useState(true)
    const updateStaffPasswordDetails = useSelector((state: any) => state.staff.updateStaffPasswordDetails)
    const updateStudentPasswordDetails = useSelector((state: any) => state.student.updateStudentPasswordDetails)
    const showToast = useToast()
    const dispatch = useDispatch()

    useEffect(() => {
      if(updateStaffPasswordDetails.status === 'succeeded') {
        showToast({message: updateStaffPasswordDetails.message})
        closeModal()
        dispatch(resetUpdateStaffPasswordDetails())
      } else if(updateStaffPasswordDetails.status === 'failed') {
        showToast({message: updateStaffPasswordDetails.error, type: 'error'})
        const intervalId = setInterval(() => {
          dispatch(resetUpdateStaffPasswordDetails())
          clearInterval(intervalId)
        }, 3000)
      }
    }, [updateStaffPasswordDetails])


    useEffect(() => {
      if(updateStudentPasswordDetails.status === 'succeeded') {
        showToast({message: updateStudentPasswordDetails.message})
        closeModal()
        dispatch(resetUpdateStudentPasswordDetails())
      } else if(updateStudentPasswordDetails.status === 'failed') {
        showToast({message: updateStudentPasswordDetails.error, type: 'error'})
        const intervalId = setInterval(() => {
          dispatch(resetUpdateStudentPasswordDetails())
          clearInterval(intervalId)
        }, 3000)
      }
    }, [updateStudentPasswordDetails])

    const changePassword = (e: any) => {
      setPassword(e.target.value)
      setErrors({})
    }

    const validateNewPasswordFormat = () => {
      const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      return re.test(password)
    }

    const handleUpdateInChild = (e: any) => {
      if(updateStaffPasswordDetails.status !== 'failed') {
        e.preventDefault()
        const allErrors: any = {}
        const invalidPasswordFormat = 'Password must be at least 8 characters long with 1 uppercase 1 lowercase and 1 numeric character'
        const validPassword = validateNewPasswordFormat()

        if (validPassword === false){
          allErrors.password = invalidPasswordFormat
        }

        if (Object.entries(allErrors).length > 0) {
          setErrors(allErrors)
          setValidateSuccess(false)
        } else {
          console.log("In errors else loop0", allErrors)
          const updatedPassword = password
          handleUpdate(e, { ...userInfo, password: updatedPassword })
        }
      }
    }

    const resetState = () => setAllValues("")

    useImperativeHandle(ref, () => {
      return {
        resetState: resetState,
      }
    })

    const closeModal = () => {
      setValidateSuccess(true)
      setPassword("")
      setErrors({})            
      handleClose()
    }

    return (
      <>
        <Modal
          show={modalState}
          size='lg'
          onHide={closeModal}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mrgnbtm container'>
              <form
                //type='submit'
                onSubmit={handleUpdateInChild}
                className={
                  validateSuccess
                    ? "form-horizontal needs-validation"
                    : "form-horizontal was-validated"
                }
                noValidate
              >
                {teacher && (
                  <label className='control-label'>
                    Employee ID: <b>{userInfo.employeeId}</b>
                  </label>
                )}
                <br />
                <label className='control-label'>
                  Email: <b>{userInfo.email}</b>
                </label>
                <div className='form-group'>
                  <label className='control-label' htmlFor='password'>
                    New Password
                  </label>
                  <input
                    type='password'
                    className='form-control'
                    onChange={changePassword}
                    id='password'
                    value={password}
                    name='password'
                    required
                  />
                  <div style={{color:"#dc3545", fontSize:"80%"}}>{errors.password}</div>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant='info'
              onClick={(e) => {
                handleUpdateInChild(e)
              }}
            >
              Change Password
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
)

export default ChangePasswordModal