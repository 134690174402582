import React from 'react';
//import Table from 'react-bootstrap/Table';
// import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react'
import { Button, Typography, } from '@mui/material';

export const EnrollStudentTable = (props) => {
    console.log('react',props.showEnrollList)
    console.log('react enrollllllllll list',props.enrollList)
    const studEnrollmentList = props.enrollList.enrolledStudentList
    
    
    return(
        <div >
           
          
            
                 <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 0,
                    backgroundColor: 'white', 
                    padding: 2, 
                    border: '1px solid #e6e8ee',
                    borderRadius: 1,
                    width: '100%',maxHeight: '80vh',overflow: 'auto',
                
                }}>
                <Typography variant="h6" style={{color:"#1e1e1e",fontSize:15,marginLeft:35}}>Student Name - {props.studentSisName}</Typography>
                <Button onClick={props.handleClose}>X</Button>
              </div>
                {studEnrollmentList && studEnrollmentList.length===0 ?
                <div>NO ENROLLED TEACHER FOR THE STUDENT - {props.studentSisName}</div> :

                <div className="horizontalScrollBar" style={{ width: '100%' }}>
                   <div style={{ overflow: 'auto' }}>
                    <table style={{ width: '100%',tableLayout:"fixed" }} className="ng-table sort-enabled child-table">
                        <thead style={{ position: 'sticky', top: 0 }}>
                        <tr>
                            <th style={{ width: '5%', borderLeft: '1px solid #e6e8ee', borderTop: '1px solid #e6e8ee' }}>#</th>
                            <th style={{ width: '15%' }}>STAFF DISTRICT ID</th>
                            <th style={{ width: '15%' }}>STAFF FIRST NAME</th>
                            <th style={{ width: '15%' }}>STAFF LAST NAME</th>
                            <th style={{ width: '15%' }}>STAFF SCHOOL ID</th>
                            <th style={{ width: '15%' }}>STAFF STATUS</th>
                            <th style={{ width: '20%', borderRight: '1px solid #e6e8ee', borderTop: '1px solid #e6e8ee' }}>STAFF USER ID</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                        
                            studEnrollmentList && studEnrollmentList.map((enrollItem,i)=>{
                                
                                return(
                                    <tr key={enrollItem.userId}>
                                        <td>{i=i+1}</td>
                                        <td>{enrollItem.districtId}</td>
                                        <td>{enrollItem.firstName}</td>
                                        <td>{enrollItem.lastName}</td>
                                        <td>{enrollItem.schoolId}</td>
                                        <td>{enrollItem.status}</td>
                                        <td>{enrollItem.userId}</td>
                                        
                                    </tr>
                                )
                                
                                })
                                
                                
                                
                        }
                            
                        </tbody>
                    </table>
                    </div>
                </div>
            }
        </div>
     
    )
   

}

