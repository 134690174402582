import React from 'react'
import { Dropdown } from 'react-bootstrap'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div className="flx pointer"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      {children}
    </div>
  ));

export const BsDropDown = ({ children, header, direction = "right" }) => <Dropdown className="dropdown-menus">
    <Dropdown.Toggle  as={CustomToggle} className="aic">
        {header}
    </Dropdown.Toggle>
    <Dropdown.Menu as="div" align={direction}>
        {children}
    </Dropdown.Menu>
</Dropdown>

export const ProfileImg = ({ url = null, name = "", size = "sm" }) => {
    return (<>
        <div className={`user-profile profile-${size}`}>
            {url === null ? <div className="">{name}</div> : <img src={url} alt="profile"/>}
        </div>
    </>)
}