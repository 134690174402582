import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useImperativeHandle, forwardRef, useRef } from 'react';

// function SecurityPolicyModal({modalState, handleClose}) {
    const SecurityPolicyModal = forwardRef(({modalState, handleClose, handleUpdate, editSecurity}: any, ref: any) => {
        console.log("editsecurity ", editSecurity)
        const userRef = useRef<any>(null);
        const [allValues, setAllValues] = useState<any>({
            userRole : editSecurity.userRole,

            userPasswordGenerationOptionStatus:editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.userPasswordGenerationOptionStatus,
            randomGenerationCharacterAllowedInPassword :editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.randomGenerationCharacterAllowedInPassword,
            randomGenerationNumbersAllowedInPassword :editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.randomGenerationNumbersAllowedInPassword,
            randomGenerationSymboldAllowedInPassword  :editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.randomGenerationSymboldAllowedInPassword,
            passwordLength : editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.passwordLength,
            countOfNumbersAllowedInPassword :editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.countOfNumbersAllowedInPassword,
            countOfSymboledAllowedInPassword : editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.countOfSymboledAllowedInPassword
        })  
        const changeHandler = (e: any) => {
            console.log("targe",[e.target.name])
            setAllValues({...allValues, [e.target.name]: e.target.value,"userRole": userRef.current.value})
        }
        const handleUpdateInChild = (e: any, allValues: any) => {
            console.log("from handle update in child method",userRef.current.value)
            console.log("useRef ",allValues)
            // setAllValues({...allValues,"staffEmployeeId": empRef.current.value})
           // console.log("EMNP ID is",allValues.studentSisId)
            handleUpdate(e,allValues)
        }
    
        // const changeHandlerBoolean = (e) => {
        //     console.log("e.targalut",e.target.value)
        //     setAllValues({...allValues, [e.target.name] : (e.target.value === "true")})
        // }
        const resetState = () => setAllValues("")
    
        useImperativeHandle( ref, () => {
            return {
                resetState: resetState
            };
          });

    return (
      <>
        <Modal show={modalState} onHide={() => handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Job Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mrgnbtm row">
                <div className="col-md-4">
                    User ID Generation Option:
                </div>
                <div className="col-md-8">
                    <select className="form-select" aria-label="Default select example" ref={userRef} id="userRole" defaultValue={editSecurity.userRole} onChange={changeHandler} name="userRole">
                        <option value="Teacher">Teacher</option>
                        <option value="Student">Student</option>
                        <option value="Parent">Parent</option>
                    </select>
                </div>
            </div>
            <div className="mrgnbtm row">
                <div className="col-md-4">
                    User Password Generation Option::
                </div>
                <div className="col-md-8">
                    <select className="form-select" aria-label="Default select example" onChange={changeHandler}  defaultValue={editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.userPasswordGenerationOptionStatus} name="userPasswordGenerationOptionStatus">
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                    </select>
                </div>
            </div>
            <div className="mrgnbtm row">
                <div className="col-md-4">
                    Random Generation Characters Allowed in Password:
                </div>
                <div className="col-md-8">
                    <input type="text" className="form-control" onChange={changeHandler} id="endpoint" defaultValue={editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.randomGenerationCharacterAllowedInPassword} name="randomGenerationCharacterAllowedInPassword" />
                </div>
            </div>
            <div className="mrgnbtm row">
                <div className="col-md-4">
                    Random Generation Numbers Allowed in Password:
                </div>
                <div className="col-md-8">
                    <input type="text" className="form-control" onChange={changeHandler} id="endpoint" defaultValue={editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.randomGenerationNumbersAllowedInPassword} name="randomGenerationNumbersAllowedInPassword"/>
                </div>
            </div>
            <div className="mrgnbtm row">
                <div className="col-md-4">
                    Random Generation Symbols Allowed in Password:
                </div>
                <div className="col-md-8">
                    <input type="text" className="form-control" onChange={changeHandler} id="endpoint" defaultValue={editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.randomGenerationSymboldAllowedInPassword} name="randomGenerationSymboldAllowedInPassword" />
                </div>
            </div>
            <div className="mrgnbtm row">
                <div className="col-md-4">
                    Password Length:
                </div>
                <div className="col-md-8">
                    <input type="text" className="form-control" onChange={changeHandler} id="endpoint" defaultValue={editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.passwordLength} name="passwordLength" />
                </div>
            </div>
            <div className="mrgnbtm row">
                <div className="col-md-4">
                    How many Symbols/Numbers Allowed in Password:
                </div>
                <div className="col-md-8">
                    <input type="text" className="form-control" onChange={changeHandler} id="endpoint" defaultValue={editSecurity.passwordGenerationOption && editSecurity.passwordGenerationOption.countOfNumbersAllowedInPassword} name="countOfNumbersAllowedInPassword"/>
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Reset
            </Button>
            {
        editSecurity !== ""?
            <Button variant="info" onClick={(e) => {handleUpdateInChild(e,allValues)}}>
              Update1
            </Button>:
        <Button variant="info" onClick={(e) => {handleUpdate(e,allValues)}}>
          Update
        </Button>
        }
          </Modal.Footer>
        </Modal>
      </>
    );
  }
    )

  export default SecurityPolicyModal