import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function MonthAndYearPicker(props: any) {
    const {MonthAndYearValue, setMonthAndYearValue} = props
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker value={MonthAndYearValue} onChange={(newValue) => setMonthAndYearValue(newValue)} label={'"month" and "year"'} views={['month', 'year']} />
    </LocalizationProvider>
  );
}
