import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { fetchOneRosterData } from '../../redux/features/integration/integration.slice';
import AppContext from '../../store/AppContext';

export default function IsSynchronozation({ onOneRosterNavigate,a,onCSVNavigate }: any) {

  const appContext = useContext(AppContext);
  const dataContainer = useSelector((state: any) => state?.integration?.integratedFlowProps)
  const adminInfo = appContext?.userDetails;
  const dispatch = useDispatch()
  const onSavePassword = (): any => {
    let a: any = {}
    if ((dataContainer.staffPasswordPolicy !== undefined )&& (dataContainer?.studentPassword !== undefined)) {
      a = {
        staffPasswordPolicy: dataContainer.staffPasswordPolicy,
        studentPasswordPolicy: dataContainer?.studentPasswordPolicy,
        studentPassword: dataContainer?.studentPassword,
        staffPassword: dataContainer?.staffPassword,
       
      }
    } else if (dataContainer?.studentPassword !== undefined) {
      a = {
        studentPassword: (dataContainer?.studentPassword),
        studentPasswordPolicy: (dataContainer?.studentPasswordPolicy),
      }
    } else {
      a = {
        staffPasswordPolicy: dataContainer.staffPasswordPolicy,
        staffPassword: dataContainer?.staffPassword,
      }
    }
    return a
  }

  function saveData(){
    if(a==="CSV"){
      onCSVNavigate('passwordpolicy')
    }else {
      dispatch(fetchOneRosterData(
        {oneRosterBaseUrl: dataContainer?.apiEndpoint,
        clientId: dataContainer?.oAuthkey,
        clientSecret: dataContainer?.oAuthSecret,
        createdBy:adminInfo._id,
        integrationName:dataContainer?.integrationName,
        districtId:adminInfo?.districtId,
        schools:dataContainer.schools,
        loginInfo:{
        type:dataContainer.type,
        userIdType:dataContainer.userIdType,
        ...onSavePassword(),
        forceStaffToChangePassword:dataContainer.forceStaffToChangePassword,
        forceStudentToChangePassword:dataContainer.forceStudentToChangePassword,
       
      },
     
     
    }
      ))
      onOneRosterNavigate('completeFlow')
    }
    
  }

  return (
    <Box sx={{ backgroundColor: 'rgb(215,215,215,0.1)', height: '70vh', width: '1044px', borderRadius: '20px',display:'flex',flexDirection:'column',justifyContent:'center', textAlign:'center' }}>
      <Typography variant="body2" sx={{ fontWeight: 500, fontSize: '20px', color: '#1e1e1e' }}>
        Do you want to schedule a synchronization of your enrollment data with Aauti School?
      </Typography>
      <div style={{ textAlign: 'center', marginTop: '8vh' }}>
       
      <Button onClick={saveData} style={{ padding: '6px 50px', textTransform: 'none', marginRight:'50px', borderColor:'black', color:'black' }} variant="outlined" id="noButtonForsync">No</Button>
        <Button onClick={a==="CSV"?() => onCSVNavigate('support'):() => onOneRosterNavigate('syncschedule')} style={{ padding: '6px 50px', textTransform: 'none' }} variant="contained" id='yesButtonForsync'>Yes</Button>
      </div>
    </Box>
  );
}
