import { getItem, saveItem } from "../store/storage";

class Auth {
    setAdminInfo = (userInfo: any)=>{
        saveItem('userInfo', userInfo)
    }

    getAdminInfo = ()=>{
        return getItem('userInfo')
    }
}
export default new Auth();