import * as React from 'react';
import { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useForm, SubmitHandler } from "react-hook-form";
import backArrow from "../../assets/images/backArrow.svg";
import { useDispatch } from 'react-redux';
import { integratedFlowChanges } from '../../redux/features/integration/integration.slice';
import { toast} from 'react-toastify';

const selectedBox = {backgroundColor: 'rgba(49, 102, 186, 1)', color: 'white'}

type Inputs = {
    apiEndpoint: string,
    oAuthkey: string,
    oAuthSecret: string
};

export default function OneRosterAPI({onOneRosterNavigate,val}: any) {

  const [selection, setSelection] = useState<any>({rosterMethod: 'onersoter'})
  const [url,setUrl] =useState<any>("https://classlinkcertification3-vn-v2.rosterserver.com")
  const [authKey,setAuthKey] =useState<any>("ff8c01de1a7903aa7c413f1c")
  const [authSecret,setAuthSecret]=useState<any>("a982c7460e51c061b37bc5a4")
  const [integrationName, setIntegrationName] = useState<any>("")

  const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>({});
  const onSubmit: SubmitHandler<Inputs> = data => console.log(data);
  const dispatch = useDispatch()

  const handleInputChange = (event:any) => {
    const value=event.target.value;
    setUrl(value)
  };
  const handleInputChangeAuthKey = (event:any) => {
    const value=event.target.value;
    setAuthKey(value)
  };
  const handleInputChangeAuthSecret = (event:any) => {
    const value=event.target.value;
    setAuthSecret(value)
  };
  const handleInputChangeIntegrationName = (event:any) => {
    const value=event.target.value;
    setIntegrationName(value)
  };


const buttonContinue = () =>{
  if(url!==""&&authKey!==""&& authSecret!=="" && integrationName!==""){
    dispatch(integratedFlowChanges({ apiEndpoint:url}))
    dispatch(integratedFlowChanges({ oAuthkey:authKey}))
    dispatch(integratedFlowChanges({ oAuthSecret:authSecret}))
    dispatch(integratedFlowChanges({ integrationName:integrationName}))
    onOneRosterNavigate('select-schools')
  }else{
    toast("Fill all fields",{type: toast.TYPE.ERROR})
  }
    }

  return (
    
    <Box sx={{backgroundColor:'rgb(215,215,215,0.1)',height:'530px',width:'1044px',borderRadius: '20px'}}>
    <Box style={{margin:'30px',paddingBottom:'10px'}} onClick={() => onOneRosterNavigate('home')} id="backButtonToRosteringAndEnrollment">
        <img src={backArrow} height="40px" />
        </Box>
        <h1 style={{color: '#1e1e1e', fontWeight: 500, marginBottom: '10vh', textAlign: 'center', fontSize:'22px'}}>One Roster API Integration</h1>
        
        <Box sx={{display:'flex',flexDirection:'row', justifyContent:'center', marginLeft:'5vw'}}>
            <form onSubmit={handleSubmit(onOneRosterNavigate)}>
                <Grid container spacing={4} sx={{display:'flex',flexDirection:'row', justifyContent:'center'}}>
                    <Grid item xs={4}>
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <label style={{color:'#333333',fontSize:'12px',fontWeight:500}} id="oneRoasterAPIEndPointLabel">API Endpoint</label>
                        <input placeholder='apiEndpoint' value={url} onChange={handleInputChange}
                        className="MuiInput-input"  id="oneRoasterAPIEndPointInput" style={{backgroundColor:'white', border:'solid 1px #e9e9e9', width:'210px', height:'35px'}} />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <label style={{color:'#333333',fontSize:'12px',fontWeight:500}} id='oneRoasteroAuthKeyLabel'>oAuth Key</label>
                        <input value={authKey} onChange={handleInputChangeAuthKey}
                        placeholder='oAuthkey'  className="MuiInput-input"   id="oneRoasteroAuthKeyInput" style={{backgroundColor:'white', border:'solid 1px #e9e9e9', width:'210px', height:'35px'}}  />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <label style={{color:'#333333',fontSize:'12px',fontWeight:500}} id="oneRoasteroAuthSecretLabel">oAuth Secret</label>
                        <input value={authSecret} onChange={handleInputChangeAuthSecret}
                         placeholder='oAuthSecret'  className="MuiInput-input"  id="oneRoasteroAuthSecretInput"  style={{backgroundColor:'white', border:'solid 1px #e9e9e9', width:'210px', height:'35px'}}  />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <label style={{color:'#333333',fontSize:'12px',fontWeight:500}} id="oneRoasterIntegrationNameLabel">Integration Name</label>
                        <input value={integrationName} onChange={handleInputChangeIntegrationName}
                         placeholder='Integration Name'  className="MuiInput-input"  id="oneRoasterIntegrationNameInput"  style={{backgroundColor:'white', border:'solid 1px #e9e9e9', width:'210px', height:'35px'}}  />
                        </div>
                    </Grid>
                </Grid>
            </form>
        </Box>
        <div style={{textAlign: 'center', marginTop: '13vh'}}>
            <Button 
            id="oneRoasterAPIIntegrationContinueButton"
            onClick={buttonContinue}
            style={{padding: '8px 80px'}}variant="contained">Continue</Button>
        </div>
  
    </Box>
    
  );
}
