import { useEffect, useState } from 'react';
import  ProcessingButton  from '../../shared/components/ProcessingButton'
import { Form } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import useToast from '../../shared/components/Toast'
import { changeUserPassword, checkForLinkValidation } from '../../redux/features/user/user.slice'
import { useSelector, useDispatch } from 'react-redux'
import Logo from '../../assets/images/logo-main.png'


const empytFields = "Please enter all fields"
const invalidPasswordFormat = 'Password must be at least 8 characters long with 1 uppercase 1 lowercase and 1 numeric character'
const passwordsMismatch = 'Passwords are not matched'


function ChangePassword() {

  const navigate = useNavigate()
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [changePasswordStatus, setChangePasswordStatus] = useState(true)
  const showToast = useToast()
  const dispatch = useDispatch()
  const search = useLocation().search;
  
  
  const queryParams = search && JSON.parse('{"' + decodeURI(decodeURIComponent(search.slice(1)).replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}')

  const changePasswordDetails = useSelector((state: any) => state.user.changePasswordDetails)
  const linkValidationDetails = useSelector((state: any) => state.user.linkValidationDetails)

  const onNewPasswordChanged = (e: any) => setNewPassword(e.target.value)
  const onNewConfirmPasswordChanged = (e: any) => setConfirmNewPassword(e.target.value)
  const newPasswordType = showPassword ? 'text' : 'password'

  const toggleShowPassword = () => setShowPassword(!showPassword)

  useEffect(() => {
    if(!search){
      navigate("/")
    }else{
      dispatch(checkForLinkValidation(queryParams))
    }
  }, [])

  useEffect(() => {
    if(changePasswordDetails.status === "succeeded") {
      showToast({message: changePasswordDetails.message})
      navigate('/')
    } else if (changePasswordDetails.status === "failed") {
      showToast({message: changePasswordDetails.error})
    }
  }, [changePasswordDetails])

  useEffect(() => {
    if(!changePasswordStatus){
      const intervalId = setInterval(() => {
        clearInterval(intervalId)
        setChangePasswordStatus(true)
      }, 3300)}
  },[changePasswordStatus])

  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  const onClickChange = () => {
    if(changePasswordStatus){
        if(!newPassword || !confirmNewPassword){
          showToast({message : empytFields, type : "error"})
        } else if(!re.test(newPassword)) {
          showToast({message : invalidPasswordFormat, type : "error"})
        } else if(newPassword !== confirmNewPassword) {
          showToast({message: passwordsMismatch, type : "error"})
        } else {
          const data = {id: queryParams.id, email: queryParams.email, userId: queryParams.userId, newPassword, confirmNewPassword}
          dispatch(changeUserPassword(data))
        }
        setChangePasswordStatus(false)
      }
  }


  const renderChangePasswordSection = () => (
    <>
      <div className="mb-4 text-center title-md">Change Password</div>
      <div className="form-group cont mt-4">
        <Form.Control
        id="adminNewPassword"
          type={newPasswordType}
          className="form-control"
          name="new password" 
          placeholder="New Password"
          onChange={onNewPasswordChanged}
        />
      </div> 
      <div className="form-group cont">
        <Form.Control 
        id="adminConfirmNewPassword"
          type="password" 
          className="form-control"
          name="confirm new password" 
          placeholder="Confirm New Password"
          onChange={onNewConfirmPasswordChanged} 
        />
      </div>
      <div className="d-flex align-items-center mb-1" >
        <input id="adminshowNewPassword" type="checkbox" className="mr-1" onClick={toggleShowPassword}/>
        <label className="mb-1" htmlFor="showPassword">Show Password</label>
      </div>
      <div className="row justify-content-center px-3 mb-3" >
        <ProcessingButton 
        id="adminChangePasswordButton"
          // @ts-ignore
          onBtnClick={onClickChange}
          btnText={changePasswordDetails.status === 'loading' ? 'Changing...' : 'Change'}
          isLoading={changePasswordDetails.status === 'loading'}
          styles="btn-block btn-color text-center mt-1 text-white"
        />
      </div>
    </>
  )

  const renderLoadingSection = () => (
    <div className="mb-4 text-center title-md mt-2">Loading...</div>
  )
  
  const renderLinkExpiredSection = () => (
    <div className="mb-4 text-center title-md mt-2">{linkValidationDetails.error}</div>
  )

  const renderApiStatus = () => {
      switch(linkValidationDetails.status) {
        case "loading":
          return renderLoadingSection()
        case "succeeded":
          return renderChangePasswordSection()
        case "failed":
          return renderLinkExpiredSection()
        default:
          return null
      }
  }

  return (
    <div className="loginScreen">
      <div className="container mx-auto login-body">
        <Form>
          <div>
            <div className="d-flex flex-lg-row flex-column-reverse">
              <div className="card card1 w-100 py-2">
                <div className="loginRightScreen col-12">
                  <div className="login-container m-auto p-2">
                    <div className="logo-main d-flex justify-content-center">
                      <img src={Logo} className="m-0" alt="bloom-logo"/>
                      <h1 className='school-text mb-1 align-self-end login-school-text'>School</h1>
                    </div>
                    {renderApiStatus()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default ChangePassword