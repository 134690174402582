import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SecurityPolicyService } from '../../../services'

const initialState = {
  securityList: [],
  status: 'idle',
  error: null
}

export const addOrUpdateSecurity: any = createAsyncThunk('security/addOrUpdateSecurityInfo',async(data) => {
  const securityPolicyService = new SecurityPolicyService();
  const response = await securityPolicyService.updateSecurityData(data);
  return response;
})

export const checkForSecurityInformation: any = createAsyncThunk('security/checkForSecurityInformation', async (data) => {
  const securityPolicyService = new SecurityPolicyService();
  const response = await securityPolicyService.securityFetch(data);
  return response
})

export const securitySlice = createSlice({
    name: 'security',
    initialState,
    reducers: {},
    extraReducers: {
      [checkForSecurityInformation.pending]: (state, action) => {
        console.log('action payload in extraReducers!!!', action.payload)
        state.status = 'loading'
      },
      [checkForSecurityInformation.fulfilled]: (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        console.log('action payload in extraReducers!!!', action.payload)
        state.securityList = action.payload
      },
      [checkForSecurityInformation.rejected]: (state, action) => {
        console.log('action payload in extraReducers!!!', action.payload)
        state.status = 'failed'
        state.error = action.error.message
      },
      [addOrUpdateSecurity.fulfilled]: (state,action) => {
        state.status = 'Added successfully'
        // state.posts = 
      },
      [addOrUpdateSecurity.rejected]: (state,action) => {
        state.status = 'Failed to add'
        state.error = action.error.message 
      }   
    }
  })
  
  export default securitySlice.reducer