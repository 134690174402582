import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DistrictsService } from '../../../services'

const initialState = {
  districtList: [],
  status: 'idle',
  error: null
}

export const addOrUpdateDistrict: any = createAsyncThunk('district/addOrUpdateDistrictInfo',async(data) => {
  const districtService = new DistrictsService();
  const response = await districtService.updateDistrictData(data);
  return response;
})

export const checkForDistrictInformation: any = createAsyncThunk('district/checkForDistrictInformation', async (data) => {
  const districtService = new DistrictsService();
  const response = await districtService.districtFetch(data);
  return response
})

export const districtSlice = createSlice({
    name: 'district',
    initialState,
    reducers: {},
    extraReducers: {
      [checkForDistrictInformation.pending]: (state, action) => {
        console.log('action payload in extraReducers!!!', action.payload)
        state.status = 'loading'
      },
      [checkForDistrictInformation.fulfilled]: (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        console.log('action payload in extraReducers!!!', action.payload)
        state.districtList = action.payload
      },
      [checkForDistrictInformation.rejected]: (state, action) => {
        console.log('action payload in extraReducers!!!', action.payload)
        state.status = 'failed'
        state.error = action.error.message
      },
      [addOrUpdateDistrict.fulfilled]: (state,action) => {
        state.status = 'Added successfully'
        // state.posts = 
      },
      [addOrUpdateDistrict.rejected]: (state,action) => {
        state.status = 'Failed to add'
        state.error = action.error.message 
      }   
    }
  })
  
  export default districtSlice.reducer