import { useState, useEffect, useRef } from 'react';
import AdminLayout from '../layouts/AdminLayout';
import { SecurityPolicies } from '../components/policies/SecurityPolicies';
import SecurityPolicyModal from '../components/modals/SecurityPolicyModal'
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { addOrUpdateSecurity, checkForSecurityInformation } from '../redux/features/securityPolicies/security.slice'
import { ProcessingImage } from '../shared/components/ProcessingImage';
import { changeActivePage } from '../redux/features/user/user.slice';

function PolicyContainer() {
  
  const securityList = useSelector((state: any) => state.security)
  console.log('security::',securityList)
  const dispatch = useDispatch()

  const [show, setShow] = useState(false);
  const [editSecurity, setEditSecurity] = useState<any>('')
  const staffModal = useRef<any>(null);
  // const addJobRole = () => {
  //     console.log('in the container!!!');
  //     setShow(true);
  // }

  const handleClose = () => {
      setShow(false);
  }
  const handleUpdate = (e: any, securityPoliciesData: any) => {
    console.log("handleUpdate ", securityPoliciesData)
    e.preventDefault();
    let userRole=securityPoliciesData.userRole;
    let userPasswordGenerationOptionStatus=securityPoliciesData.userPasswordGenerationOptionStatus;
    let randomGenerationCharacterAllowedInPassword=securityPoliciesData.randomGenerationCharacterAllowedInPassword;
    let randomGenerationNumbersAllowedInPassword=securityPoliciesData.randomGenerationNumbersAllowedInPassword;
    let randomGenerationSymboldAllowedInPassword=securityPoliciesData.randomGenerationSymboldAllowedInPassword;
    let passwordLength=securityPoliciesData.passwordLength;
    let countOfNumbersAllowedInPassword=securityPoliciesData.countOfNumbersAllowedInPassword;

  
     dispatch(addOrUpdateSecurity({"securityPoliciesData": { 
        "passwordGenerationOption":{
        "userPasswordGenerationOptionStatus":userPasswordGenerationOptionStatus,
        "randomGenerationCharacterAllowedInPassword" : randomGenerationCharacterAllowedInPassword,
        "randomGenerationNumbersAllowedInPassword" :randomGenerationNumbersAllowedInPassword,
        "randomGenerationSymboldAllowedInPassword"  :randomGenerationSymboldAllowedInPassword,
        "passwordLength" : passwordLength,
        "countOfNumbersAllowedInPassword" :countOfNumbersAllowedInPassword,
       //  "countOfSymbolsAllowedInPassword" :countOfSymbolsAllowedInPassword,
        "userRole":userRole
  
    }}}))
    setShow(false);
    setEditSecurity('');
    staffModal.current.resetState();
    if(securityList.securityList.statusCode === 200){
      setEditSecurity(true)
    } 
}

  const openModal = (modalState: any) => {
    console.log('modalState::::', modalState)
    setShow(modalState);
  }

  useEffect(() => {
    dispatch(checkForSecurityInformation())
    //console.log('student  status in login container  ', studentStatus)
    console.log('student  status in studentRoasterContainer container  ')
    if(editSecurity)
    setEditSecurity(false)
  }, [dispatch,editSecurity])

  useEffect(() => {
    dispatch(changeActivePage("policies"))
  },[])

  return (
      <AdminLayout>
        <div className="container mrgntop">
          {
            securityList.status==="loading"?
            <ProcessingImage isLoading={true}/> :
          <SecurityPolicies openModal={openModal} securityList={securityList} setShow={setShow} setEditSecurity={setEditSecurity} />
          }
        </div>
        <SecurityPolicyModal modalState={show} handleClose={handleClose} handleUpdate={handleUpdate} editSecurity={editSecurity} ref={staffModal}/>
      </AdminLayout>
    );
}

export default PolicyContainer;