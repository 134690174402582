import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import AppContext from '../../store/AppContext';
import { clearStorage } from '../../store/storage';
import { useDispatch, useSelector } from 'react-redux'
import { changeShowMenuStatus, changeStatus } from '../../redux/features/user/user.slice'
import {BsDropDown} from '../../shared/components/BsUtil'
import {HiMenu} from 'react-icons/hi'
import './header.css'
import Logo from "../../assets/images/logo-header.png"
import { styled } from '@mui/material/styles'
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Badge,
    MenuItem,
    Menu,
    Drawer,
    Avatar,
} from '@mui/material'
import {
    AccountCircle,
    Mail as MailIcon,
    Notifications as NotificationsIcon,
    MoreVert as MoreIcon,
    Settings as SettingsIcon,
    Person,
    Lock,
    Mail,
    Logout,
} from '@mui/icons-material'





export  const Header = () => {
  
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { districtId }: any = appContext?.userDetails && appContext.userDetails.districtId ? appContext.userDetails : {};
  const showMenu = useSelector((state: any) => state.user.showMenu);

  const adminInfo= appContext?.userDetails;
  const capitalize = (s: any) => s && s[0].toUpperCase(0,2) + (s.slice(1).toLowerCase());

  const onLogout = () => {
    // Clearing Local Storage
    clearStorage();
    dispatch(changeStatus())
    // Clearing the user details
    appContext?.setUserDetails(null);
    console.log("appContext ", appContext?.userDetails)
    navigate(`/`)
  }
  const ProfilContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
  }))
  return(
      <div className="header">
          <div className="title-lg text-white mb-0 aic">
              <HiMenu color="#000"  className='header-menu-icon' onClick={() => dispatch(changeShowMenuStatus(!showMenu)) }/>
              <div className="logo-header">
                <div className='icon-container mt-2'>
                  <img onClick={() => {navigate(`/${districtId}/dashboard`)}} src={Logo} alt="bloom-logo"/>
                  <h1 className="school-text mb-1">School</h1>
                </div>
              </div> 
          </div>
          <div className="aic">
            <Box sx={{ flexGrow: 1,}} />
            <Box sx={{ display: { xs: 'none', md: 'flex' },justifyContent:'center'}}>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    id="toggleDisplay"
                    data-target="#notiDrop-2"
                    data-toggle="collapse"
                    aria-expanded="false"
                    
                    onClick={() => {}}
                >
                    <NotificationsIcon sx={{ color: '#8687a7' }} />
                </IconButton>
                <ProfilContainer sx={{ paddingRight: '10px', paddingLeft: '10px' }}>
                    <AccountCircle sx={{ color: '#8687a7' }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="span"
                        sx={{
                            fontWeight: 600,
                            fontSize: '18px',
                            backgroundClip: 'text',
                            color: '#8687a7!important',
                            marginLeft: '5px',
                            border: 'none',
                            padding: '0',
                        }}
                    >
                        {adminInfo.firstName}
                    </Typography>
                </ProfilContainer>
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls="settingsMenuOpen"
                    aria-haspopup="true"
                    onClick={() => {}}
                    id="settingsMenuOpen"
                    color="inherit"
                >
                   
                  <BsDropDown header={ <SettingsIcon sx={{ color: '#8687a7' }} />}>
                    <Dropdown.Item onClick={() => onLogout()}><div>Logout</div></Dropdown.Item>  
                  </BsDropDown>
                </IconButton>
            </Box>
          </div>
      </div> 
    )
}