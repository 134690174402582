import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchOneRosterOrganisations } from '../../redux/features/integration/integration.slice';
import { Loading } from '../../shared/Loading';
import { Button } from '@mui/material';
import backArrow from "../../assets/images/backArrow.svg";
import {integratedFlowChanges} from "../../redux/features/integration/integration.slice"
import { toast} from 'react-toastify';

const columns: GridColDef[] = [
  { field: 'sourcedId', headerName: 'ID' },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1
  },
  {
    field: 'dateLastModified',
    headerName: 'Last Modified Date',
    flex: 1
  },
];

export default function SelectSchools({onOneRosterNavigate}: any) {

    const dispatch = useDispatch()
    const organisationsData = useSelector((state: any) => state?.integration?.oneRosterOrganisationsDetails)

    const [rows, setRows] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const oneRosterBaseUrl=useSelector((state:any)=>state?.integration?.integratedFlowProps)
    useEffect(() => {
        dispatch(fetchOneRosterOrganisations({
            "oneRosterBaseUrl": oneRosterBaseUrl?.apiEndpoint,
            "clientId": oneRosterBaseUrl?.oAuthkey,
            "clientSecret": oneRosterBaseUrl?.oAuthSecret
          }))
    },[])

    useEffect(() => {
        if(organisationsData?.status === 'succeeded') {
            setRows(organisationsData?.data);
        }
    }, [organisationsData])

    const buttonContinue = () =>{
      if(rowSelectionModel.length>0){
        dispatch(integratedFlowChanges({'schoolId':rowSelectionModel}))
        let schoolNames: { ID: any; name: any; }[] = []
        const selectdSchool = rowSelectionModel.map((each:any)=>{
          const tableDataSchools:any = rows.filter((each1:any)=>each1?.sourcedId === each)
          schoolNames.push({'ID':tableDataSchools[0]?.sourcedId,'name':tableDataSchools[0]?.name})
        })
        dispatch(integratedFlowChanges({'selectedSchoolNames':schoolNames}))
        onOneRosterNavigate('select-courses')
      }else{
        toast("Select atleast one school",{type: toast.TYPE.ERROR})
      }
    }

  return (
    <Box sx={{width:'99%'}}>
        <img src={backArrow} height="40px" style={{marginLeft:'10px'}} onClick={() => onOneRosterNavigate('oneroster')} id="backButtonToOneRoasterAPIIntegration"/>
        <h1 style={{color: '#1e1e1e', fontWeight: 500, marginBottom: '3vh', textAlign: 'center', fontSize:'22px',marginTop:'-2vh'}}>Select Schools</h1>
        
        {(organisationsData?.status === 'loading') ? <Loading /> :
            <>
            <Box sx={{ width: '100%',height:"61vh"}} id="oneRoasterSelectSchoolsDataGrid">
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row: any) =>  row.sourcedId}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 10,
                    },
                },
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
              //   componentsProps={{
              //     pagination: {
              //       nextIconButtonProps: { id: `next-page-button from ${rows.length + 1}` },
              //       backIconButtonProps: { id: `previous-page-button untill ${rows.length}` },
              //     },
              // }}
  
            />
            </Box>
            <div style={{textAlign: 'center', marginTop: '2vh'}}>
              <Button onClick={buttonContinue} style={{padding: '8px 80px',textTransform: 'none'}} variant="contained" id="fetchCoursesBtnForSchools">Fetch Courses</Button>
            </div></>
        }
    </Box>
  );
}